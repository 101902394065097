import {PUSH_NOTIFICATION_DATA, PUSH_NOTIFICATION_IMAGE,FETCH_PUSH_NOTIFICATION_BY_ID_SUCCESS,
FETCH_PUSH_NOTIFICATION_FALIOUR,FETCH_PUSH_NOTIFICATION_REQUEST,FETCH_PUSH_NOTIFICATION_SUCCESS } from "./pushNotificationTypes";
import { fetchData,postData,updateData,deleteData } from "../../Api/api";

export const fetchPushNotificationRequest = () => {
    return {
        type: FETCH_PUSH_NOTIFICATION_REQUEST
    }
}

export const fetchPushNotificationSuccess = (data) => {
    return {
        type : FETCH_PUSH_NOTIFICATION_SUCCESS,
        payload : data
    }
}

export const fetchPushNotificationFaliour = (data) => {
    return {
        type : FETCH_PUSH_NOTIFICATION_FALIOUR, 
        payload : data
    }
}

export const PushNotificationData = (data) => {
    return {
        type : PUSH_NOTIFICATION_DATA,
        payload : data
    }
}

export const PushNotificationImage = (data) => {
    return {
        type : PUSH_NOTIFICATION_IMAGE,
        payload: data
    }
}

export const fetchPushNotificationByIdSuccess = (data) => {
    return {
        type: FETCH_PUSH_NOTIFICATION_BY_ID_SUCCESS,
        payload: data
    }
}

export const getPushNotificationDataAll = (user_id, endpoint = `get_all_push_notification?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchPushNotificationSuccess(response.data))
        ).catch(
            error => dispatch(fetchPushNotificationFaliour(error.message))
        )
    }
}

export const getPushNotificationById = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        fetchData(`/get_ingredient_by_id/${endpoint}`, user_id).then(
            response => dispatch(fetchPushNotificationByIdSuccess([response.data.response]))
        ).catch(
            error => dispatch(fetchPushNotificationFaliour(error.message))
        )
    }
}

export const postPushNotificationData = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        postData(`add_push_notification`,body, user_id).then(
            response => {
                dispatch(PushNotificationData(response.data))
                response.data.status === 'success' && dispatch(getPushNotificationDataAll(user_id, `get_all_push_notification?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchPushNotificationFaliour(error.message))
        )
    }
}

export const updatePushNotification = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        updateData(`update_push_notification`,body, user_id).then(
            response => {
                dispatch(PushNotificationData(response.data))
                response.data.status === 'success' && dispatch(getPushNotificationDataAll(user_id, `get_all_push_notification?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchPushNotificationRequest(error.message))
        )
    }
}
export const deletePushNotification = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        deleteData(`delete_push_notifiation`,body, user_id).then(
            response => {
                dispatch(PushNotificationData(response.data))
                response.data.status === 'success' && dispatch(getPushNotificationDataAll(user_id, `get_all_push_notification?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchPushNotificationRequest(error.message))
        )
    }
}

export const addPushNotificationImage = (body) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        postData(`add_push_notification_image`,body).then(
            response => dispatch(PushNotificationImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchPushNotificationFaliour(error.message))
        )
    }
}

export const deletePushNotificationImage = (body) => {
    return (dispatch) => {
        dispatch(fetchPushNotificationRequest())
        postData(`remove_image`,body).then(
            response => dispatch(PushNotificationImage(''))
        ).catch(
            error => dispatch(fetchPushNotificationFaliour(error.message))
        )
    }
}