import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, InputAdornment, Typography, Box, makeStyles, colors } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { handleloginData, updatePhoneEmail} from "../../redux";
import style from "../authentication/authentication.module.css";
import { Autocomplete } from "@material-ui/lab";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { app } from "../../firebase/firebase";
import { postData } from "../../Api/api";
import authclass from "../Auth";

export default function UpdateMobileNumber() {
    const useStyles = makeStyles((theme) => ({
        boxSpaceBetween: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "50px"
        },
        textColor: {
            color: colors.grey[800],
        },
        divider: {
            margin: "0.5rem 0 1.5rem",
        },
    }));
    const classes = useStyles()
    const inputRef = useRef(null);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const auth = getAuth(app);
    const [updatephoneNumber, setupdatephoneNumber] = useState(false)
    const [newNumberPut, setnewNumberPut] = useState(false)
    const [confirmOtp, setconfirmOtp] = useState(null)
    const [authentication, setauthentication] = useState({
        status: "",
        message: ""
    })
    const [confirmNewPhoneNumber, setconfirmNewPhoneNumber] = useState(false)

    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        state?.countryCurrency?.allCountry?.map(res => res?.timezones?.includes(tzid) && setformValue({ ...formValue, phoneCode: res }))
    }, [tzid, state?.countryCurrency?.allCountry])

    const [loginPhoneOtp, setloginPhoneOtp] = useState(false)

    const [formValue, setformValue] = useState({ emailphone: '', emailphoneError: '', phoneCode: null, password: '', passwordSee: false })

    const [otp, setotp] = useState({
        otp1: '', inputRef1: useRef(null),
        otp2: '', inputRef2: useRef(null),
        otp3: '', inputRef3: useRef(null),
        otp4: '', inputRef4: useRef(null),
        otp5: '', inputRef5: useRef(null),
        otp6: '', inputRef6: useRef(null)
    })


    useEffect(() => {
        setformValue({
            ...formValue,
            phoneCode: state.countryCurrency.allCountry.find(res => res?.phone ===
                state?.auth?.data?.client_user_phone_number.substring(0, 3)),
            // emailphone: state?.auth?.data?.client_user_phone_number.substring(3)
        })
    }, [state?.auth?.data?.client_user_phone_number])

    const onchange = (e) => {
        setformValue({
            ...formValue, [e.target.name]: e.target.value ? e.target.value.match(/^[0-9\b]+$/) &&
                e.target.value.length <= 10 && e.target.value : "", emailphoneError: ''
        });
        setauthentication({
            status: "",
            message: ""
        })
    };

    const onSignInSubmit = (phoneNumber) => {
        var recaptchaWrapperRef = document.createElement("div");
        recaptchaWrapperRef.innerHTML = '<div id="recaptcha-container"></div>';
        document.body.appendChild(recaptchaWrapperRef);
        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            // 'callback': (response) => {
            //     // reCAPTCHA solved, allow signInWithPhoneNumber.
            //     // onSignInSubmit();
            // },
            // 'expired-callback': () => {
            //     // Response expired. Ask user to solve reCAPTCHA again.
            //     // ...
            //     alert("time out")
            // }
        }, auth);
        try {
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    // window.confirmationResult = confirmationResult;
                    // console.log(window.confirmationResult)
                    setconfirmOtp(confirmationResult)
                    setloginPhoneOtp(true)
                    // if (appVerifier && recaptchaWrapperRef) {
                    appVerifier.clear();
                    document.getElementById("recaptcha-container").remove()
                    //     recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
                    // }
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                        dispatch(handleloginData({
                        status: 'red',
                        message: "NetWork Error"
                    }))
                });

        } catch (error) {
            alert(error)
            dispatch(handleloginData({
                status: 'red',
                message: error
            }))
        }
    }

    const onSignInValid = () => {
        const testOtp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6
        try {
            confirmOtp.confirm(testOtp).then(
                response => {
                    if (!newNumberPut) {
                        setnewNumberPut(true)
                        setloginPhoneOtp(false)
                        setotp(
                            {
                                ...otp,
                                otp1: '',
                                otp2: '',
                                otp3: '',
                                otp4: '',
                                otp5: '',
                                otp6: '',
                            }
                        )
                    } else {
                        setconfirmNewPhoneNumber(true)
                    }
                    setauthentication({
                        status: "",
                        message: ""
                    })
                }
            ).catch(error => {
                setauthentication({
                    status: "error",
                    message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase()
                })
            }
            )
        } catch (error) {
            setauthentication({
                status: "error",
                message: "NetWork Error"
            })
            console.log(error)
        }
    }


    useEffect(() => {
        if (formValue.emailphone !== state?.auth?.data?.client_user_phone_number.substring(3) &&
            formValue.emailphone.length === 10) {
            postData(`check_client_phone_number`, {
                client_user_phone_number: formValue.phoneCode.phone.concat(formValue.emailphone),
            }).then(
                response => setauthentication({
                    ...authentication, ...response.data,
                    loader: false, status: response.data.status === "success" ? "" : "error"
                })
            ).catch(error => setauthentication({ ...authentication, ...error, loader: false }))
        }
    }, [formValue.emailphone, formValue.phoneCode, updatephoneNumber])

    const sendOtp = () => {
        if (newNumberPut) {
            if (formValue.emailphone.match(/^(0|91)?[6-9][0-9]{9}/) && formValue.phoneCode) {
                otp?.inputRef1?.current?.focus()
                onSignInSubmit(formValue.phoneCode.phone.concat(formValue.emailphone))
            } else {
                setformValue({ ...formValue, emailphoneError: "Please Enter a Valid Phone Number" })
            }
        } else {
            onSignInSubmit(state?.auth?.data?.client_user_phone_number)
        }
    }

    useEffect(() => {
        if (otp.otp1) {
            if (otp.otp2) {
                if (otp.otp3) {
                    if (otp.otp4) {
                        if (otp.otp5) {
                            if (otp.otp6) {
                                onSignInValid()
                            }
                        }
                    }
                }
            }
        }
    }, [otp])

    useEffect(() => {
        inputRef?.current?.focus();
        return () => {
            inputRef.current = null;
        };
    }, [inputRef]);

    const logout = () => {
        authclass.logout()
        window.location.reload()
        // dispatch(auth(localStorage.getItem("user-token")));
    }

    useEffect(() => {
        state?.auth?.status === "success" && logout()
    }, [state?.auth?.status])

    return (
        <form autoComplete="off">
            {newNumberPut ? <TextField fullWidth variant="outlined" size="small"
                label="Phone Number"
                name="emailphone" error={formValue.emailphoneError}
                helperText={formValue.emailphoneError}
                value={formValue.emailphone}
                onChange={(e) => onchange(e)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Autocomplete size="small" fullWidth
                                options={state.countryCurrency.allCountry}
                                value={formValue.phoneCode}
                                onChange={(event, newValue) => setformValue({ ...formValue, phoneCode: newValue, emailphoneError: "" })}
                                getOptionLabel={(option) => option.phone}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <img width="23px" alt="flag" src={`https://www.countryflags.io/${option?.country_code}/shiny/64.png`} />{option.phone}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField variant="standard"
                                        {...params}
                                        // label="Choose a country"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password'
                                        }}
                                    />
                                )}
                            />
                        </InputAdornment>
                    ),
                }}
            /> :
                <Box className={classes.boxSpaceBetween}>
                    <div className="d_flex justify_content_between w_100">
                        <Typography color="secondary">Phone</Typography>
                        <Typography className={classes.textColor}>{state?.auth?.data?.client_user_phone_number}</Typography>
                    </div>
                    <div className="d_flex justify_content_end w_100">
                        <Button color="primary" className="button"
                            variant="contained" type="button"
                            onClick={() => setupdatephoneNumber(!updatephoneNumber)}
                        >
                            {updatephoneNumber ? "Cancel" : "Update Phone Number"}
                        </Button>
                    </div>
                </Box>}
            {
                loginPhoneOtp &&
                <div className={style.otp}>
                    <input ref={otp.inputRef1} className={style.partitioned} value={otp.otp1} name="otp1"
                        type="text" maxLength="1" onChange={(e) => {
                            setotp({ ...otp, otp1: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp1 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef2.current.focus() : otp.inputRef1.current.focus() :
                                otp.inputRef1.current.focus()
                        }} />
                    <input ref={otp.inputRef2} className={style.partitioned} type="text" maxLength="1" name="otp2"
                        value={otp.otp2} onChange={(e) => {
                            setotp({ ...otp, otp2: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp2 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef3.current.focus() : otp.inputRef2.current.focus() :
                                otp.inputRef1.current.focus()
                        }} />
                    <input ref={otp.inputRef3} className={style.partitioned} type="text" maxLength="1" name="otp3"
                        value={otp.otp3} onChange={(e) => {
                            setotp({ ...otp, otp3: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp3 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef4.current.focus() : otp.inputRef3.current.focus() :
                                otp.inputRef2.current.focus()
                        }} />
                    <input ref={otp.inputRef4} className={style.partitioned} type="text" maxLength="1" name="otp4"
                        value={otp.otp4} onChange={(e) => {
                            setotp({ ...otp, otp4: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp4 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef5.current.focus() : otp.inputRef4.current.focus() :
                                otp.inputRef3.current.focus()
                        }} />
                    <input ref={otp.inputRef5} className={style.partitioned} type="text" maxLength="1" name="otp5"
                        value={otp.otp5} onChange={(e) => {
                            setotp({ ...otp, otp5: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp5 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef6.current.focus() : otp.inputRef5.current.focus() :
                                otp.inputRef4.current.focus()
                        }} />
                    <input ref={otp.inputRef6} className={style.partitioned} type="text" maxLength="1" name="otp6"
                        value={otp.otp6} onChange={(e) => {
                            setotp({ ...otp, otp6: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp6 : "" })
                            e.target.value ? e.target.value.match(/^[0-9\b]+$/) && otp.inputRef6.current.focus() :
                                otp.inputRef5.current.focus()
                        }} />
                </div>
            }
            {
                updatephoneNumber && !confirmNewPhoneNumber &&
                <div className=" d_flex justify_content_between w_100 my_20">
                    <Button color="primary" className="button"
                        variant="contained" type="button"
                        disabled={authentication.status === "error"}
                        onClick={() => sendOtp()}
                    >
                        {loginPhoneOtp ? "Resend Otp" : "send otp"}
                    </Button>
                </div>
            }
            {
                confirmNewPhoneNumber &&
                <div className=" d_flex justify_content_between w_100 my_20">
                    <Button color="primary" className="button"
                        variant="contained" type="button"
                        onClick={() =>
                            dispatch(updatePhoneEmail(`update_client_user`,{
                                _id: state?.auth?.data?._id,
                                client_user_phone_number: formValue.phoneCode.phone.concat(formValue.emailphone)
                            }, state?.auth?.data?._id))}
                    >
                        Update
                    </Button>
                </div>
            }


            {
                authentication.status &&
                <Typography color={authentication.status}>{authentication.message}</Typography>
            }
        </form>
    );
}
