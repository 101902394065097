import {
    FETCH_HANDLE_DELIVARY_BOY_FALIOUR, FETCH_HANDLE_DELIVARY_BOY_REQUEST, FETCH_HANDLE_DELIVARY_BOY_DATA,
    FETCH_HANDLE_DELIVARY_BOY_SUCCESS, HANDLE_STATUS_MESSAGE
} from "./delivaryboyTypes";
import { postData, fetchData, updateData, deleteData } from '../../Api/api'

export const fetchdelivaryBoyAccess = () => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_REQUEST
    }
}

export const fetchdelivaryBoySuccess = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_SUCCESS,
        payload: data
    }
}

export const fetchdelivaryBoyFaliour = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_FALIOUR,
        payload: data
    }
}

export const fetchdelivaryFormData = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_DATA,
        payload: data
    }
}

export const handledelivaryBoyStatus = () => {
    return {
        type: HANDLE_STATUS_MESSAGE,
    }
}

export const getDelivaryBoy = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchdelivaryBoySuccess(response.data))
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const postDelivaryBoy = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        postData(endpoint, body, user_id).then(
            response =>
                dispatch(fetchdelivaryFormData(response.data))
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const updateDelivaryBoy = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        updateData(endpoint, body, user_id).then(
            response =>
                dispatch(fetchdelivaryFormData(response.data))
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const deleteDelivaryBoy = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        deleteData(endpoint, body, user_id).then(
            response =>
                dispatch(fetchdelivaryFormData(response.data))
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}
