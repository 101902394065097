import {FETCH_INVENTORY_DATA, FETCH_INVENTORY_FALIOUR, FETCH_INVENTORY_REQUEST, FETCH_INVENTORY_SUCCESS,
    HANDLE_INVENTORY_STATUS_MESSAGE} from './inventory.types'
import { deleteData, fetchData, postData, updateData } from '../../../Api/api'

export const fetchInventoryAccess = () => {
    return {
        type: FETCH_INVENTORY_REQUEST
    }
}

export const fetchInventorySuccess = (data) => {
    return {
        type: FETCH_INVENTORY_SUCCESS,
        payload: data
    }
}

export const fetchInventoryFaliour = (data) => {
    return {
        type: FETCH_INVENTORY_FALIOUR,
        payload: data
    }
}

export const fetchInventoryFormData = (data) => {
    return {
        type: FETCH_INVENTORY_DATA,
        payload: data
    }
}

export const handleInventoryStatus = (data) => {
    return {
        type: HANDLE_INVENTORY_STATUS_MESSAGE,
        payload: data
    }
}

export const getInventory = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchInventorySuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchInventoryFaliour(error.message))
        )
    }
}

export const postInventory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryAccess())
        postData(`add_inventory`, body, user_id).then(
            response => {
                dispatch(fetchInventoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventory(`get_all_inventory/null/null?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryFaliour(error.message))
        )
    }
}

export const updateInventory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryAccess())
        updateData(`update_inventory`, body, user_id).then(
            response => {
                dispatch(fetchInventoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventory(`get_all_inventory/null/null?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryFaliour(error.message))
        )
    }
}

export const deleteInventory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryAccess())
        deleteData(`delete_many_inventory`, body, user_id).then(
            response => {
                dispatch(fetchInventoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventory(`get_all_inventory/null/null?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryFaliour(error.message))
        )
    }
}
