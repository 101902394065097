import {
    FETCH_HANDLE_COUNTRY_CURRENCY_REQUEST, FETCH_HANDLE_COUNTRY_CURRENCY_SUCCESS, FETCH_ALL_COUNTRY_CURRENCY_SUCCESS,
    FETCH_HANDLE_COUNTRY_CURRENCY_FALIOUR, FETCH_HANDLE_COUNTRY_CURRENCY_DATA, FETCH_ALL_COUNTRY_STATE_SUCCESS
} from "./countryTypes";

const initialState = {
    loader: false,
    allCountry: [],
    states: [],
    data: [],
    status: '',
    message: ''
}

export const countryCurrencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HANDLE_COUNTRY_CURRENCY_REQUEST:
            return {
                ...state, loader: true
            }
        case FETCH_ALL_COUNTRY_CURRENCY_SUCCESS:
            return {
                ...state,
                loader: false,
                allCountry: action.payload,
                status: '',
                message: ''
            }
        case FETCH_ALL_COUNTRY_STATE_SUCCESS:
            return {
                ...state,
                loader: false,
                states: action.payload,
                status: '',
                message: ''
            }
        case FETCH_HANDLE_COUNTRY_CURRENCY_SUCCESS:
            return {
                ...state,
                loader: false,
                data: [action.payload],
                status: '',
                message: ''
            }

        case FETCH_HANDLE_COUNTRY_CURRENCY_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_HANDLE_COUNTRY_CURRENCY_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}