import { TextField, Button, ButtonGroup, Typography } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import style from "../authentication.module.css";
import LongMenu from "../../GetCountryCode";
import { useSelector, useDispatch } from "react-redux";
import { postloginbyEmail } from "../../../redux";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { app } from "../../../firebase/firebase";
const RegisterPhoneNumber = () => {
  const inputRef = useRef(null);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const auth = getAuth(app);
  const [confirmOtp, setconfirmOtp] = useState(null);
  const [authentication, setauthentication] = useState({
    status: "",
    message: "",
  });

  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    state?.countryCurrency?.allCountry?.map(
      (res) => res?.timezones?.includes(tzid) && onchange("phoneCode", res)
    );
  }, [tzid, state?.countryCurrency?.allCountry]);

  const [loginPhoneOtp, setloginPhoneOtp] = useState(false);

  const [formValue, setformValue] = useState({
    emailphone: "",
    emailphoneError: "",
    phoneCode: null,
    password: "",
    passwordSee: false,
  });

  const [otp, setotp] = useState({
    otp1: "",
    inputRef1: useRef(null),
    otp2: "",
    inputRef2: useRef(null),
    otp3: "",
    inputRef3: useRef(null),
    otp4: "",
    inputRef4: useRef(null),
    otp5: "",
    inputRef5: useRef(null),
    otp6: "",
    inputRef6: useRef(null),
  });

  const onchange = (name, value) => {
    if (name === "emailphone") {
      setformValue({
        ...formValue,
        [name]: value
          ? value.match(/^[0-9\b]+$/) && value.length <= 11
            ? value
            : formValue[name]
          : "",
        emailphoneError: "",
      });
    } else {
      setformValue({ ...formValue, [name]: value, emailphoneError: "" });
    }
  };

  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const onSignInSubmit = () => {
    var recaptchaWrapperRef = document.createElement("div");
    recaptchaWrapperRef.innerHTML = '<div id="recaptcha-container"></div>';
    document.body.appendChild(recaptchaWrapperRef);
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    if (
      formValue.phoneCode.phone === "+880" &&
      formValue.emailphone.charAt(0) === "0" &&
      formValue.emailphone.length > 10
    ) {
      const phoneNumber = formValue.phoneCode.phone.concat(
        formValue.emailphone.slice(1)
      );
      try {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            setconfirmOtp(confirmationResult);
            setloginPhoneOtp(true);
            setSeconds(30);
            appVerifier.clear();
            document.getElementById("recaptcha-container").remove();
          })
          .catch((error) => {
            console.log(error);
            setauthentication({
              status: "red",
              message: error?.code?.slice(5)?.replace(/-/g, " "),
            });
          });
      } catch (error) {
        alert(error);
        setauthentication({
          status: "red",
          message: error,
        });
      }
    } else {
      const phoneNumber = formValue.phoneCode.phone.concat(
        formValue.emailphone
      );
      try {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            setconfirmOtp(confirmationResult);
            setloginPhoneOtp(true);
            setSeconds(30);
            appVerifier.clear();
            document.getElementById("recaptcha-container").remove();
          })
          .catch((error) => {
            console.log(error);
            setauthentication({
              status: "red",
              message: error?.code?.slice(5)?.replace(/-/g, " "),
            });
          });
      } catch (error) {
        alert(error);
        setauthentication({
          status: "red",
          message: error,
        });
      }
    }
  };

  const onSignInValid = () => {
    const testOtp =
      otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6;
    try {
      confirmOtp
        .confirm(testOtp)
        .then((response) => {
          setauthentication({
            status: "success",
            message: "User Authenticated SuccessFully",
          });
          dispatch(
            postloginbyEmail(`firebase_token_to_client_user`, {
              firebase_token: response.user.accessToken,
              country_name: formValue.phoneCode.name,
              country_code: formValue.phoneCode.country_code,
              country_currency_symbol: formValue.phoneCode.currency_symbol,
              country_timezones: formValue.phoneCode.timezones,
              currency: formValue.phoneCode.currency,
            })
          );
        })
        .catch((error) => {
          setauthentication({
            status: "error",
            message: error?.code?.slice(5)?.replace(/-/g, " "),
          });
          console.log(error);
        });
    } catch (error) {
      setauthentication({
        status: "error",
        message: error?.code?.slice(5)?.replace(/-/g, " "),
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      formValue.emailphone.match(/^[0-9\b]+$/) &&
      formValue.emailphone.length >= 10 &&
      formValue.phoneCode
    ) {
      if (
        formValue.phoneCode.phone === "+880" &&
        formValue.emailphone.charAt(0) === "0" &&
        formValue.emailphone.length > 10
      ) {
        const phone_number = formValue.emailphone.slice(1);
        dispatch(
          postloginbyEmail(`check_client_phone_number`, {
            client_user_phone_number:
              formValue.phoneCode.phone.concat(phone_number),
          })
        );
      } else {
        dispatch(
          postloginbyEmail(`check_client_phone_number`, {
            client_user_phone_number: formValue.phoneCode.phone.concat(
              formValue.emailphone
            ),
          })
        );
      }
    }
  }, [formValue]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (
      formValue.emailphone.match(/^[0-9\b]+$/) &&
      formValue.emailphone.length >= 10 &&
      formValue.phoneCode
    ) {
      otp?.inputRef1?.current?.focus();
      onSignInSubmit();
      setauthentication({
        status: "success",
        message: `A 6 digit OTP send to your mobile no ${formValue.phoneCode.phone} - ${formValue.emailphone}`,
      });
    } else {
      setformValue({
        ...formValue,
        emailphoneError: "Please Enter a Valid Phone Number",
      });
    }
  };

  useEffect(() => {
    if (otp.otp1) {
      if (otp.otp2) {
        if (otp.otp3) {
          if (otp.otp4) {
            if (otp.otp5) {
              if (otp.otp6) {
                onSignInValid();
              }
            }
          }
        }
      }
    }
  }, [otp]);

  useEffect(() => {
    inputRef?.current?.focus();
    return () => {
      inputRef.current = null;
    };
  }, [inputRef]);

  return (
    <form className="px_20" onSubmit={(e) => sendOtp(e)}>
      <div>
        <div className="d_flex">
          <ButtonGroup aria-label="outlined button group">
            {
              <LongMenu
                selectcountry={formValue.phoneCode}
                countryChange={(country) =>
                  setformValue({
                    ...formValue,
                    phoneCode: country,
                    emailphoneError: "",
                  })
                }
              />
            }
          </ButtonGroup>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="emailphone"
            label={`Write ${
              formValue?.phoneCode?.phone === "+880" ? "11" : "10"
            } digit mobile no`}
            error={formValue.emailphoneError}
            value={formValue.emailphone}
            onChange={(e) => onchange(e.target.name, e.target.value)}
          />
        </div>
        <Typography color="error" variant="caption">
          {formValue.emailphoneError}
        </Typography>
      </div>

      {loginPhoneOtp && (
        <div className={style.otp}>
          <input
            ref={otp.inputRef1}
            value={otp.otp1}
            name="otp1"
            autoComplete="off"
            type="text"
            maxLength="1"
            onChange={(e) => {
              setotp({
                ...otp,
                otp1: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp1
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/)
                  ? otp.inputRef2.current.focus()
                  : otp.inputRef1.current.focus()
                : otp.inputRef1.current.focus();
            }}
          />
          <input
            ref={otp.inputRef2}
            type="text"
            maxLength="1"
            name="otp2"
            autoComplete="off"
            value={otp.otp2}
            onChange={(e) => {
              console.log(e);
              setotp({
                ...otp,
                otp2: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp2
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/)
                  ? otp.inputRef3.current.focus()
                  : otp.inputRef2.current.focus()
                : otp.inputRef1.current.focus();
            }}
            onKeyDown={(e) =>
              e.key === "Backspace" &&
              !otp.otp2 &&
              otp.inputRef1.current.focus()
            }
          />
          <input
            ref={otp.inputRef3}
            type="text"
            maxLength="1"
            name="otp3"
            autoComplete="off"
            value={otp.otp3}
            onChange={(e) => {
              setotp({
                ...otp,
                otp3: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp3
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/)
                  ? otp.inputRef4.current.focus()
                  : otp.inputRef3.current.focus()
                : otp.inputRef2.current.focus();
            }}
          />
          <input
            ref={otp.inputRef4}
            type="text"
            maxLength="1"
            name="otp4"
            autoComplete="off"
            value={otp.otp4}
            onChange={(e) => {
              setotp({
                ...otp,
                otp4: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp4
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/)
                  ? otp.inputRef5.current.focus()
                  : otp.inputRef4.current.focus()
                : otp.inputRef3.current.focus();
            }}
          />
          <input
            ref={otp.inputRef5}
            type="text"
            maxLength="1"
            name="otp5"
            autoComplete="off"
            value={otp.otp5}
            onChange={(e) => {
              setotp({
                ...otp,
                otp5: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp5
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/)
                  ? otp.inputRef6.current.focus()
                  : otp.inputRef5.current.focus()
                : otp.inputRef4.current.focus();
            }}
          />
          <input
            ref={otp.inputRef6}
            type="text"
            maxLength="1"
            name="otp6"
            autoComplete="off"
            value={otp.otp6}
            onChange={(e) => {
              setotp({
                ...otp,
                otp6: e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? e.target.value
                    : otp.otp6
                  : "",
              });
              e.target.value
                ? e.target.value.match(/^[0-9\b]+$/) &&
                  otp.inputRef6.current.focus()
                : otp.inputRef5.current.focus();
            }}
          />
        </div>
      )}

      <div
        className={`d_flex align_item_center ${
          authentication.status
            ? "justify_content_between"
            : "justify_content_end"
        }`}
      >
        {authentication.status && (
          <Typography
            style={{
              width: "60%",
              color: authentication.status === "success" ? "green" : "red",
            }}
            color={authentication.status}
          >
            {authentication.message}
          </Typography>
        )}
        <Button
          color="primary"
          className={seconds ? "btn_black" : "button"}
          variant="contained"
          type="submit"
          disabled={state?.auth?.status === "red" || seconds}
        >
          {seconds ? seconds : loginPhoneOtp ? "resend Otp" : "send otp"}
        </Button>
      </div>
    </form>
  );
};

export default RegisterPhoneNumber;
