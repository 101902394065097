import { combineReducers } from 'redux'
import userReducers from './user/userReducers'
import { catagoryReducers } from "./catagory/catagoryReducers";
import {branchReduder} from './branch/branchReducers'
import {delivaryZoneReducer} from "./delivary-Zone/delivaryZoneReucers";
import {operationalhourReducer} from './operationalhours/operationalReducers'
import { ItemReducer } from './item/itemReducer';
import {delivaryBoyReducer} from './delivaryboy/delivaryboyReducers';
import { configartionReducer } from "./configation/configarationReducer";
import { iconLogoReducer } from "./iconlogo/iconlogoReducer";
import {socialmediaReducer} from './socialmedia/socialmediaReducer';
import {cmsPageReducer} from "./cmsPage/cmsPageReducer";
import {preferenceReducer} from './preference/preferenceReducer';
import {administratorReducer} from './administrator/administratorReducer'
import {loginReducer} from './login/loginReducer'
import {pushNotificationReducer} from './pushNotification/pushNotificationReducer'
import { fetureReducer } from './feture/fetureReducers';
import customerGroupReducer from './CustomerGroup/CustomerGroup.reducer'
import { couponReduder } from "./cupon/cuponReducer";
import { orderReduder } from './order/orderReducer';
import { countryCurrencyReducer } from "./countryCurrency/countryReducers";
import { bannerReducer } from "./banner/bannerReducers";
import { homePagePopupReducer } from './homePagePopupbanner/HomePagePopup.reducer'
import { menutimeReducer } from './menutime/menutimeReducer';
import { brandReduder } from './brand/brandeReducers';
import { paymentGateWayReducer } from './paymentgateWay/paymentGatewayReducer';
import { seoReducer } from './seo/seoReducer';
import { smsReducer } from './sms/smsReduder';
import { addonsReducer } from './addons/addonsReducer';
import { galaryReducer } from './galary/galary.reducer';
import { taxReducer } from './tax/taxReducer';
import { generalSettingsReducer } from './inventory/generalSettings/generalSetting.reducer';
import { unitReducer } from './inventory/unit/unit.reducer';
import { inventoryCategoryReducer } from './inventory/inventoryCategory/inventoryCategory.reducer';
import { inventoryReducer } from './inventory/inventory/inventory.reducer';
import { registerPosReducer } from './registerpos/registerpos.reducer';
import { reciepeReducer } from './reciepe/reciepe.reducer';
import { dashboardReducer } from './dashboard/dashboard.reducer';
import { planReducer } from './plan/plan.reducer';

export default combineReducers({
    dashboard : dashboardReducer,
    user : userReducers, 
    catagory : catagoryReducers,
    branch : branchReduder,
    delivaryZone : delivaryZoneReducer,
    operationalhours: operationalhourReducer,
    menutime: menutimeReducer,
    item: ItemReducer,
    delivaryBoy: delivaryBoyReducer,
    configarationSettings: configartionReducer,
    iconlogo: iconLogoReducer,
    socialmedia: socialmediaReducer,
    cmsPage: cmsPageReducer,
    preference: preferenceReducer,
    administrator: administratorReducer,
    auth: loginReducer,
    pushNotification: pushNotificationReducer,
    feture: fetureReducer,
    customerGroup: customerGroupReducer,
    coupon: couponReduder,
    order: orderReduder,
    countryCurrency: countryCurrencyReducer,
    banner: bannerReducer,
    homepagePopup: homePagePopupReducer,
    brand: brandReduder,
    payment: paymentGateWayReducer,
    seo: seoReducer,
    sms: smsReducer,
    addons: addonsReducer,
    galary: galaryReducer,
    tax: taxReducer,
    inventoryGeneral: generalSettingsReducer,
    unit: unitReducer,
    inventoryCategory: inventoryCategoryReducer,
    inventory: inventoryReducer,
    registerPos: registerPosReducer,
    reciepe: reciepeReducer,
    plan: planReducer
})