import {FETCH_ITEM_FALIOUR, FETCH_ITEM_REQUEST,FETCH_ITEM_SUCCESS,ITEM_DATA, ITEM_MESSAGE, ITEM_LOADER,
    ITEM_IMAGE ,IMAGE_DELETE, FETCH_ITEM_ADDONS_SUCCESS, ADDONS_WALA_ITEM, HANDLE_STATUS_MESSAGE} from "./itemTypes";
import { fetchData,postData,updateData,deleteData } from "../../Api/api";

export const fetchItemRequest = () => {
    return {
        type: FETCH_ITEM_REQUEST
    }
}

export const fetchItemSuccess = (data) => {
    return {
        type : FETCH_ITEM_SUCCESS,
        payload : data
    }
}

export const fetchItemAddonsSuccess = (data) => {
    return {
        type : FETCH_ITEM_ADDONS_SUCCESS,
        payload : data
    }
}

export const fetchItemFaliour = (data) => {
    return {
        type : FETCH_ITEM_FALIOUR, 
        payload : data
    }
}

export const fetchEditItem = (data) => {
    return {
        type : ADDONS_WALA_ITEM, 
        payload : data
    }
}

export const itemData = (data) => {
    return {
        type : ITEM_DATA,
        payload : data
    }
}

export const imageMessage = (data = {status: '', message: ''}) => {
    return {
        type : ITEM_MESSAGE,
        payload : data
    }
}

export const itemImage = (data) => {
    return {
        type : ITEM_IMAGE,
        payload: data
    }
}

export const fetchImageRequest = () => {
    return {
        type: ITEM_LOADER
    }
}

export const imageDelete = (data) => {
    return {
        type : IMAGE_DELETE,
        payload : data
    }
}

export const handleItemStatusMessage = () => {
    return {
        type : HANDLE_STATUS_MESSAGE,
    }
}

export const getItemDataAll = (endpoint = `get_all_item?page=1&limit=10`, user_id) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataAllForAddons = (endpoint = `get_all_item?page=1&limit=10`, user_id) => {
    return (dispatch) => {
        dispatch(fetchImageRequest())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchItemAddonsSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataById = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(`/get_item_by_id/${endpoint}?page=1&limit=10`).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataByName = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(`/search_item_by_name/${endpoint}`).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const postItemData = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        postData(endpoint,body, user_id).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll(`get_all_item?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const postAddonItem = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        postData(endpoint,body, user_id).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll(`get_all_item?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const updateItem = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        updateData(endpoint,body, user_id).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll(`get_all_item?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const deleteItem = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        deleteData(endpoint,{_id: body._id}, user_id).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll(`get_all_item?page=1&limit=10`, user_id))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const addItemImage = (body) => {
    return (dispatch) => {
        dispatch(fetchImageRequest())
        postData(`add_item_image`,body).then(
            response => {dispatch(itemImage(response.data))
                dispatch(itemImage(''))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const deleteItemImage = (body) => {
    return (dispatch) => {
            if (body.split("/")[7] === "digital_menu_item") {
            dispatch(fetchImageRequest())
            let public_id = body.split("/")[8].split(".")[0];
            postData(`remove_image`,{ "item_image": `digital_menu_item/${public_id}` }).then(
                response => dispatch(itemImage(''))
            ).catch(
                // error => dispatch(fetchItemFaliour(error.message))
            )
        }else{
            dispatch(imageMessage())
        }
    }
}