import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { handleLoginStatusMessage, updateUserDetails } from "../../../redux";
import { postData } from "../../../Api/api";
import { Link, useHistory } from "react-router-dom";
import style from "../authentication.module.css";
import { useForm } from "../../../hooks/useForm";

const Bussinessname = () => {
  const state = useSelector((state) => state);

  const history = useHistory();

  const dispatch = useDispatch();

  const [subdomain, setsubdomain] = useState("");

  const [responsefromApi, setresponsefromApi] = useState({
    loader: false,
    status: "",
    message: "",
    response: null,
  });

  const checkName = (value) => {
    postData(`check_sub_domain`, { subdomain: value })
      .then((response) =>
        setresponsefromApi({
          ...responsefromApi,
          ...response.data,
          loader: false,
        })
      )
      .catch((error) =>
        setresponsefromApi({ ...responsefromApi, ...error, loader: false })
      );
  };

  const checkDomain = (e) => {
    if (e.target.value.match(/^[a-z]+$/) || !e.target.value) {
      setsubdomain(e.target.value);
    }
    if (e.target.value) {
      setresponsefromApi({
        ...responsefromApi,
        loader: true,
      });
    } else {
      setresponsefromApi({
        ...responsefromApi,
        loader: false,
        status: "",
        message: "",
        response: null,
      });
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      subdomain && checkName(subdomain);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [subdomain]);

  useEffect(() => {
    if (state?.auth?.data?.client_user_subdomain) {
      state?.branch?.loader ? (
        <div className="loaderBg">
          <CircularProgress
            color={
              state?.auth?.data?.client_user_color_theme === "dark"
                ? "#ffffff"
                : "gray"
            }
          />
        </div>
      ) : (
        history.push("/store-basics/create-location")
      );
      dispatch(handleLoginStatusMessage());
    }
    setsubdomain(state?.auth?.data?.client_user_name_of_business);
  }, [state?.auth?.data, state?.branch]);

  const { formValues, errors, handleInputChange } = useForm(
    {
      client_user_custom_domain: "",
    },
    {}
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUserDetails(
        { client_user_subdomain: subdomain, ...formValues },
        state?.auth?.data?._id
      )
    );
  };

  return (
    <form className="px_20" onSubmit={(e) => handleSubmit(e)}>
      <div>
        <div>
          <TextField
            fullWidth
            value={formValues.client_user_custom_domain}
            variant="outlined"
            size="small"
            name="client_user_custom_domain"
            label="Do you have any www. domain ?"
            error={errors.client_user_custom_domain_error ? true : false}
            helperText={errors.client_user_custom_domain_error}
            InputProps={{
              startAdornment: "https://",
            }}
            onChange={(e) =>
              handleInputChange(
                true,
                e.target.name,
                e.target.value,
                true,
                e.target.value.match(
                  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                )
              )
            }
          />
        </div>
      </div>
      <h4 className="formField">Create Sub-Domain Profile</h4>
      <div>
        <div className="d_flex">
          <TextField
            type="out"
            size="small"
            autoComplete="off"
            placeholder="Enter Resturent Name"
            value={subdomain}
            name="subdomain"
            variant="outlined"
            fullWidth
            onChange={(e) => checkDomain(e)}
          />
          <div className={`${style.phone_code} px_20`}>.robofood.io</div>
        </div>
      </div>
      {responsefromApi.status !== "fail" && subdomain && (
        <Link to={`${subdomain}.robofood.io`}>{subdomain}.robofood.io</Link>
      )}
      {responsefromApi.status && (
        <Typography
          className="m_20"
          style={{
            color: responsefromApi.status === "success" ? "green" : "red",
          }}
        >
          {responsefromApi.message}
        </Typography>
      )}
      <h4 className="formField">
        You can upgrade from sub-domain to main domain from upgrade domain
        section later
      </h4>
      <div className="d_flex justify_content_end">
        <Button
          variant="contained"
          color="primary"
          className="button"
          type="submit"
          disabled={responsefromApi.status !== "success"}
        >
          {responsefromApi.loader ? (
            <CircularProgress size={25} color="secondary" />
          ) : (
            "submit"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Bussinessname;
