import {
    FETCH_PLAN_REQUEST, FETCH_PLAN_SUCCESS, FETCH_PLAN_FALIOUR, FETCH_PLAN_DATA,
    HANDLE_PLAN_STATUS, FETCH_BASIC_PLAN_SUCCESS
} from "./plan.types";
import { fetchData, updateData } from '../../Api/api'

export const fetcPlanAccess = () => {
    return {
        type: FETCH_PLAN_REQUEST
    }
}

export const fetcPlanSuccess = (data) => {
    return {
        type: FETCH_PLAN_SUCCESS,
        payload: data
    }
}

export const fetcPlanFaliour = (data) => {
    return {
        type: FETCH_PLAN_FALIOUR,
        payload: data
    }
}

export const fetcPlanFormData = (data) => {
    return {
        type: FETCH_PLAN_DATA,
        payload: data
    }
}

export const fetcBasicPlanStatus = (data) => {
    return {
        type: FETCH_BASIC_PLAN_SUCCESS,
        payload: data
    }
}

export const fetcPlanStatus = (data) => {
    return {
        type: HANDLE_PLAN_STATUS,
        payload: data
    }
}

export const getBasicPlan = (user_id) => {
    return (dispatch) => {
        dispatch(fetcPlanAccess())
        fetchData(`get_all_price_and_access_plan`, user_id).then(
            response => {
                const addondata = response.data.response
                const addonCountryData = addondata.find(res => res.country_name === user_id) ?
                    addondata.find(res => res.country_name === user_id) :
                    addondata.find(res => res?.country_name === "United States")
                dispatch(fetcBasicPlanStatus({ ...addonCountryData }))
            }
        ).catch(
            error => dispatch(fetcPlanFaliour(error.message))
        )
    }
}

export const getPlan = (user_id) => {
    return (dispatch) => {
        dispatch(fetcPlanAccess())
        fetchData(`get_client_user_addon_price`, user_id).then(
            response => dispatch(fetcPlanSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetcPlanFaliour(error.message))
        )
    }
}


export const updatePlan = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetcPlanAccess())
        updateData(`add_or_update_client_user_addon_price`, body, user_id).then(
            response => {
                dispatch(fetcPlanFormData(response.data))
                response.data.status === 'success' && dispatch(getPlan(user_id))
            }
        ).catch(
            error => dispatch(fetcPlanFaliour(error.message))
        )
    }
}
