import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { MdArrowDropDown } from "react-icons/all";

const ITEM_HEIGHT = 48;

export default function LongMenu({ selectcountry, countryChange }) {
  const state = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    country,
    // setcountry
  ] = useState(state.countryCurrency.allCountry);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (country) => {
    countryChange(country);
    setAnchorEl(null);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.secondary.light,
    },
    marginRight: {
      marginRight: "20px",
    },
    bgColorCondition: {
      backgroundColor: theme.palette.primary.light,
    },
    textSecondary: {
      color: theme.palette.secondary.main,
    },
  }));

  const classes = useStyles();

  // const searchbyWord = (value) => {
  //     setsearchvalue(value)
  //     // setcountry(state.countryCurrency.allCountry.filter(res => res.name.includes(value)))
  // }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<MdArrowDropDown />}
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={
          <img
            width="23px"
            alt=""
            src={`https://flagcdn.com/w20/${selectcountry?.country_code?.toLowerCase()}.png`}
          />
        }
      >
        {selectcountry?.phone}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
          },
        }}
      >
        {/* <div className={`${classes.bgColorCondition} stickySearchBar`}>
                    <TextField size="small" fullWidth value={searchvalue}
                        variant="outlined"
                        color="primary"
                        label="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <BiSearch color="primary" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) =>
                            setsearchvalue(e.target.value)}
                    />
                </div> */}
        {country.map((option) => (
          <MenuItem
            key={option.country_code}
            onClick={() => handleChange(option)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <img
              width="23px"
              alt="flag"
              style={{ marginRight: "10px" }}
              src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
            />
            <ListItemText>
              {option.name}{" "}
              <span className={classes.textSecondary}>({option.phone})</span>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
