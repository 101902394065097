import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  useMediaQuery,
  Menu,
  MenuItem,
  IconButton,
  Badge,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Divider } from "@material-ui/core";
import { Header } from "../header/header";
import {
  RiDashboardFill,
  FaShoppingCart,
  MdRestaurantMenu,
  AiFillNotification,
  FaUsers,
  MdOutlineInventory,
  MdOutlineInventory2,
  GiFruitBowl,
  AiOutlineUsergroupAdd,
  FiSettings,
  IoIosApps,
  HiDocumentReport,
  BiDuplicate,
  BiDish,
  CgMenuGridO,
  BiUserCheck,
  BsCardImage,
  SiGoogleanalytics,
  GoLocation,
  BsFillClockFill,
  RiClockwise2Line,
  ImLocation2,
  FaMoneyCheckAlt,
  FaAutoprefixer,
  SiCraftcms,
  IoShareSocial,
  MdLocalOffer,
  SiOpenaccess,
  GiFetus,
  AiFillHome,
  IoNotificationsCircleOutline,
  MdEmail,
  MdTextsms,
  CgSearchFound,
  ImQrcode,
  IoIosImages,
  MdScatterPlot,
  RiFileSearchFill,
  HiOutlineReceiptTax,
  FaUserTie,
  MdMonitorWeight,
  MdPointOfSale,
  FaSitemap,
  AiOutlineAppstoreAdd,
} from "react-icons/all";
import ColorPicker from "../color/colorpicker";
import { useHistory } from "react-router-dom";
import { AccountCircle, Mail, Notifications } from "@material-ui/icons";
import { useSelector } from "react-redux";
const drawerWidth = 110;

const sidebar =
  (ChildLanding) =>
  ({ ...props }) => {
    const state = useSelector((state) => state);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const history = useHistory();
    const [open, setOpen] = useState(true);
    const [openSubDrawer, setOpenSubDrawer] = useState(true);
    const useStyles = makeStyles((theme) => ({
      root: {
        display: "flex",
      },
      sideBarIconSyle: {
        fontSize: "32px",
        padding: "1.15rem 0",
        cursor: "pointer",
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding: ".5rem 0",
        // transition: theme.transitions.create(["margin", "width"], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.leavingScreen,
        // }),
        backgroundColor: theme.palette.secondary.light,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      hide: {
        display: "none",
      },
      drawer: {
        width: drawerWidth,
        marginTop: 80,
        // marginTop: theme.spacing(0, 1),
        flexShrink: 0,
        backgroundColor:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "rgb(27, 25, 27)"
            : "transparent",
      },
      drawerPaper: {
        width: drawerWidth,
        marginTop: 80,
        borderTopRightRadius: openSubDrawer ? 0 : 20,
        borderBottomRightRadius: openSubDrawer ? 0 : 20,
        height: "-webkit-fill-available",
        boxShadow: openSubDrawer
          ? "none"
          : "0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%)",
        overflowY: "scroll",
        backgroundColor:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#1e2022"
            : theme.palette.secondary.light,
      },
      colorChange: {
        top: "25%",
        height: "auto",
        border: "none",
        boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
      },
      drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
      },
      content: {
        flexGrow: 1,
        padding: 0,
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        minHeight: "100vh",
        backgroundColor:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "rgb(27, 25, 27)"
            : "transparent",
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        width: "100%",
        minHeight: "100vh",
      },
      listStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      subDwawer: {
        width: 220,
        flexShrink: 0,
        // zIndex: 2000,
      },
      subDrawerPaper: {
        width: 220,
        marginLeft: 100,
        marginTop: 80,
        background: "#ffffff",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "-webkit-fill-available",
        backgroundColor: theme.palette.secondary.light,
      },
      subList: {
        display: "flex",
        color: theme.palette.primary.contrastText,
        fontSize: "1.3rem",
        alignItems: "center",
        marginTop: "1rem",
      },
      bgColorCondition: {
        backgroundColor:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#1e2022"
            : theme.palette.secondary.light,
        borderColor:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#1e2022"
            : theme.palette.secondary.light,
        color: theme.palette.primary.contrastText,
      },
    }));
    const classes = useStyles();
    const [openColorPannel, setOpenColorPannel] = useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    // const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };

    const handleDrawerToggle = React.useCallback(() => {
      setOpen(!open);
    }, [open]);
    // const handleDrawerToggle = () => {
    //   setOpen(!open);
    // };

    // const handleDrawerClose = () => {
    //   setOpen(false);
    // };

    useEffect(() => {
      if (matches) {
        setOpen(true);
        lt?.find(
          (res) => res?.link === window?.location?.pathname?.split("/")[1]
        )?.children?.length > 0
          ? setOpenSubDrawer(true)
          : setOpenSubDrawer(false);
      } else {
        setOpen(false);
        setOpenSubDrawer(false);
      }
      return () => {
        setOpen(false);
        setOpenSubDrawer(false);
      };
    }, [matches]);

    const [activeChild, setactiveChild] = useState([]);

    const lt = [
      {
        name: "DashBoard",
        link: "dashboard",
        icon: <RiDashboardFill />,
        children: [
          {
            childrenName: "Marketing",
            pathname: "/dashboard/marketing",
            icon: <SiGoogleanalytics />,
          },
          {
            childrenName: "Report",
            pathname: "/dashboard/report",
            icon: <HiDocumentReport />,
          },
        ],
      },
      {
        name: "Product",
        link: "products",
        icon: <MdRestaurantMenu />,
        children: [
          {
            childrenName: "Category",
            pathname: "/products/category",
            icon: <CgMenuGridO />,
          },
          {
            childrenName: "Product",
            pathname: "/products/allproduct",
            icon: <BiDish />,
          },
          {
            childrenName: "Modifier",
            pathname: "/products/addons",
            icon: <BiDuplicate />,
          },
        ],
      },
      {
        name: "Orders",
        link: "orders",
        icon: <FaShoppingCart />,
        children: [],
      },
      {
        name: "Customer",
        link: "customer",
        icon: <AiOutlineUsergroupAdd />,
        children: [
          {
            childrenName: "Customers",
            pathname: "/customer/customerlist",
            icon: <BiUserCheck />,
          },
          {
            childrenName: "CustomerGroup",
            pathname: "/customer/customer-group",
            icon: <FaUsers />,
          },
        ],
      },
      {
        name: "Settings",
        link: "store-basics",
        icon: <FiSettings />,
        children: [
          {
            childrenName: "Branch Locations",
            pathname: "/store-basics/location",
            icon: <GoLocation />,
          },
          {
            childrenName: "Icon Logo Settings",
            pathname: "/store-basics/icon-logo-settings",
            icon: <BsCardImage />,
          },
          {
            childrenName: "Operational Hours",
            pathname: "/store-basics/operational-hours",
            icon: <BsFillClockFill />,
          },
          {
            childrenName: "MenuTime",
            pathname: "/store-basics/menutime",
            icon: <RiClockwise2Line />,
          },
          {
            childrenName: "Delivery Zones",
            pathname: "/store-basics/deliveryzone",
            icon: <ImLocation2 />,
          },
          {
            childrenName: "Payment Gateway",
            pathname: "/store-basics/payment-getway",
            icon: <FaMoneyCheckAlt />,
          },
          {
            childrenName: "Tax",
            pathname: "/store-basics/tax",
            icon: <HiOutlineReceiptTax />,
          },
          {
            childrenName: "Prefernces",
            pathname: "/store-basics/preferrences",
            icon: <FaAutoprefixer />,
          },
          // {
          //   childrenName:
          //     !state?.auth?.data?.client_user_is_sub_admin && "Access Control",
          //   pathname:
          //     !state?.auth?.data?.client_user_is_sub_admin &&
          //     "/store-basics/administator",
          //   icon: !state?.auth?.data?.client_user_is_sub_admin && (
          //     <SiOpenaccess />
          //   ),
          // },
          {
            childrenName: "CmsPage",
            pathname: "/store-basics/cms",
            icon: <SiCraftcms />,
          },
          {
            childrenName: "Social Settings",
            pathname: "/store-basics/social-settings",
            icon: <IoShareSocial />,
          },
          {
            childrenName: "QR Details",
            pathname: "/store-basics/qrdetails",
            icon: <ImQrcode />,
          },
          {
            childrenName: "Custom Domain",
            pathname: "/store-basics/custom-domain",
            icon: <RiFileSearchFill />,
          },
        ],
      },
      {
        name: "POS",
        icon: <MdPointOfSale />,
        link: "pos",
        children: [
          {
            childrenName: "Add Register",
            pathname: "/pos/add-register-pos",
            icon: <MdPointOfSale />,
          },
          {
            childrenName: "Manage Register",
            pathname: "/pos/manage-register-pos",
            icon: <MdPointOfSale />,
          },
          {
            childrenName: "Download Register",
            pathname: "/pos/download-register-pos",
            icon: <MdPointOfSale />,
          },
        ],
      },
      {
        name: "Inventory",
        icon: <MdOutlineInventory />,
        link: `https://robofood-branch.pages.dev/super-sign-in?branch=${
          state?.branch?.activeBranch[0]?._id
        }&jwt=${localStorage.getItem("user-token")}`,
        children: [],
      },
      {
        name: "Web & App Builder",
        link: "web-app-builder",
        icon: <IoIosApps />,
        children: [
          {
            childrenName: "Featured Products",
            pathname: "/web-app-builder/fetures",
            icon: <GiFetus />,
          },
          {
            childrenName: "Banners",
            pathname: "/web-app-builder/banners",
            icon: <IoIosImages />,
          },
          {
            childrenName: "Home Page Banner",
            pathname: "/web-app-builder/homepage-banner",
            icon: <AiFillHome />,
          },
        ],
      },
      {
        name: "Promotion",
        icon: <AiFillNotification />,
        link: "promotion",
        children: [
          {
            childrenName: "Coupon",
            pathname: "/promotion/coupon",
            icon: <MdLocalOffer />,
          },
          {
            childrenName: "Push Notification",
            pathname: "/promotion/push-notifications",
            icon: <IoNotificationsCircleOutline />,
          },
          {
            childrenName: "Email Notification",
            pathname: "/promotion/configure-email",
            icon: <MdEmail />,
          },
          {
            childrenName: "SMS Configaration",
            pathname: "/promotion/configure-sms",
            icon: <MdTextsms />,
          },
          {
            childrenName: "SEO",
            pathname: "/promotion/seo",
            icon: <CgSearchFound />,
          },
        ],
      },
      {
        name: "Stuff",
        icon: <FaUserTie />,
        link: "stuff",
        children: [],
      },
    ];

    const active = (value) => {
      if (value.name === "Inventory") {
        return window.open(value.link, "_blank");
      }

      if (window.location.pathname.split("/")[1] === value.link) {
        value.children.length > 0
          ? setOpenSubDrawer(!openSubDrawer)
          : setOpenSubDrawer(false);
      } else {
        history.push(
          value.children.length > 0
            ? `${value.children[0].pathname}`
            : `/${value.link}`
        );
      }
    };

    useEffect(() => {
      if (
        lt?.find(
          (res) => res?.link === window?.location?.pathname?.split("/")[1]
        )
      ) {
        setactiveChild(
          lt.find((res) => res.link === window.location.pathname.split("/")[1])
            .children
        );
      }
    }, [window.location.pathname]);

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <Mail />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem
        // onClick={handleProfileMenuOpen}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Header
            openDrawer={open}
            setOpenDrawer={handleDrawerToggle}
            openSubDrawer={openSubDrawer}
            setOpenSubDrawer={setOpenSubDrawer}
            handleMobileMenuOpen={handleMobileMenuOpen}
          />
        </AppBar>
        {renderMobileMenu}

        {/* {matches ? (
        <Drawer */}
        {/* {matches ? ( */}
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List style={{ padding: 0 }}>
            {lt.map((res, i) => (
              <>
                <ListItem
                  onClick={() => {
                    active(res);
                  }}
                  style={{
                    color:
                      window.location.pathname.split("/")[1] === res.link
                        ? theme.palette.primary.main
                        : theme.palette.primary.contrastText,
                  }}
                  className={`${classes.listStyle} ${classes.sideBarIconSyle}`}
                >
                  {res.icon}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    <span className="font_13" style={{ textAlign: "center" }}>
                      {res.name}
                    </span>
                  </div>
                </ListItem>
                {i < lt.length - 1 && <Divider />}
              </>
            ))}
          </List>
        </Drawer>
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.colorChange}
          classes={{
            paper: classes.colorChange,
          }}
          variant="persistent"
          anchor="right"
          open={openColorPannel}
        >
          <ColorPicker />
        </Drawer>
        <button
          className={`${classes.bgColorCondition} colorButton`}
          style={{ right: openColorPannel ? "300px" : 0 }}
          onClick={() => setOpenColorPannel(!openColorPannel)}
        >
          Color
        </button>

        <main
          onClick={() => {
            if (!matches) {
              setOpen(false);
              setOpenColorPannel(false);
              setOpenSubDrawer(false);
            }
          }}
          className={clsx(classes.content, {
            [classes.contentShift]: open && matches,
          })}
        >
          <div className={classes.drawerHeader} />
          <ChildLanding
            open={openSubDrawer}
            parentopen={open}
            children={activeChild}
            openClose={(value) => setOpenSubDrawer(value)}
            {...props}
          />
        </main>
      </div>
    );
  };

export default sidebar;
