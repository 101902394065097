import { FETCH_USER_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FALIUR, USER_DATA, USERS_FALIUR, FETCH_ACTIVE_USERS_FALIUR, FETCH_ACTIVE_USER_REQUEST } from './userTypes'

const initialStore = {
    loader: false,
    loading: false,
    userList: [],
    activeUser: [],
    error: '',
    status: '',
    message: ''
}

const userReducers = (state = initialStore, action) => {
    switch (action.type) {
        case FETCH_USER_REQUEST:
            return {
                ...state, loader: true,
            }
        case FETCH_ACTIVE_USER_REQUEST:
            return {
                ...state, loading: true,
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loader: false,
                userList: action.payload,
                error: '',
                status: '',
                message: ''
            }
        case FETCH_ACTIVE_USERS_FALIUR:
            return {
                ...state,
                loading: false,
                activeUser: action.payload,
                error: '',
                status: '',
                message: ''
            }
        case FETCH_USERS_FALIUR:
            return {
                ...state,
                loader: false,
                userList: [],
                error: action.payload
            }
        case USER_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? 'success' : 'error',
                message: action.payload.message
            }
        case USERS_FALIUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                error: action.payload
            }
        default:
            return state
    }
}

export default userReducers