import {FETCH_CONFIGARATION_DATA, FETCH_CONFIGARATION_REQUEST, FETCH_CONFIGARATION_SUCCESS, FETCH_CONFIGARATION_FALIOUR} from "./configarationTypes";
import {fetchData,updateData} from '../../Api/api'

export const fetchConfigarationAccess = () => {
    return {
        type: FETCH_CONFIGARATION_REQUEST
    }
}

export const fetchConfigarationSuccess = (data) => {
    return {
        type: FETCH_CONFIGARATION_SUCCESS,
        payload: data
    }
}

export const fetchConfigarationFaliour = (data) => {
    return {
        type: FETCH_CONFIGARATION_FALIOUR,
        payload : data
    }
}

export const fetchConfigartionFormData = (data) => {
    return {
        type: FETCH_CONFIGARATION_DATA,
        payload: data
    }
}

export const getConfigaration = () => {
    return (dispatch) => {
        dispatch(fetchConfigarationAccess())
        fetchData(`get_app_configuration`).then(
            response => dispatch(fetchConfigarationSuccess(response?.data?.response[0]))
        ).catch(
            error => dispatch(fetchConfigarationFaliour(error.message))
        )
    }
}


export const updateConfigaration = (body) => {
    return (dispatch) => {
        dispatch(fetchConfigarationAccess())
        updateData(`update_app_configuration`,body).then(
            response => {
                dispatch(fetchConfigartionFormData(response.data))
                response.data.status === 'success' && dispatch(getConfigaration())
            }
        ).catch(
            error => dispatch(fetchConfigarationFaliour(error.message))
        )
    }
}
