import {GET_ADMINISTATOR_DATA_BY_ID, HANDLE_ADMINISTATOR_FALIOUR,HANDLE_ADMINISTATOR_FORM_DATA, 
    HANDLE_ADMINISTATOR_REQUEST, HANDLE_ADMINISTATOR_SUCCESS} from './administratorTypes'
import { deleteData, fetchData, postData, updateData } from "../../Api/api";

export const fetchAdministratorRequest = () => {
    return {
        type: HANDLE_ADMINISTATOR_REQUEST
    }
}

export const fetchAdministratorSucess = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_SUCCESS,
        payload: data
    }
}

export const fetchAdministratorSucessById = (data) => {
    return {
        type: GET_ADMINISTATOR_DATA_BY_ID,
        payload: data
    }
}

export const fetchAdministratorFaliour = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_FALIOUR,
        payload: data
    }
}

export const handleAdministratorData = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_FORM_DATA,
        payload: data
    }
}

export const getAdministrator = (user_id, endpoint = `get_all_client_user_sub_admin?limit=10&page=1`) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchAdministratorSucess(response.data.response))
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const getAdministratorById = (id) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        fetchData(`get_Administrator/${id}`).then(
            response => dispatch(fetchAdministratorSucessById(response.data.response))
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const postAdministrator = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        postData(`create_client_user_sub_admin`, body, user_id).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(user_id))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const updateAdministrator = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        updateData(`update_client_user_sub_admin`, body, user_id).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(user_id))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const deleteAdministrator = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        deleteData(`delete_client_user_sub_admin`, {_id: body}, user_id).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(user_id))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}