import React, { useEffect, useState } from 'react';
import Logo from "../../assets/logo/Robofood.png";
import style from "./authentication.module.css";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const authentication = (Landing) => (props) => {
    const state = useSelector(state => state)
    const history = useHistory()
    const [setpNumber, setsetpNumber] = useState(0)
    useEffect(() => {
        if (state?.auth?.data?.client_user_subdomain) {
            state?.branch?.loader ?
                <div className="loaderBg">
                    <CircularProgress
                        color={
                            state?.auth?.data?.client_user_color_theme === "dark"
                                ? "#ffffff"
                                : "gray"
                        }
                    />
                </div> :
                history.push("/")
        } else {
            if (state?.auth?.data?.client_user_is_password_set) {
                if (!state?.auth?.data?.client_user_first_name ||
                    !state?.auth?.data?.client_user_last_name ||
                    !state?.auth?.data?.client_user_agreed_terms_and_condition ||
                    !state?.auth?.data?.client_user_address_1 ||
                    !state?.auth?.data?.client_user_address_2 ||
                    !state?.auth?.data?.client_user_city ||
                    !state?.auth?.data?.client_user_state ||
                    !state?.auth?.data?.client_user_country) {
                    setsetpNumber(2)
                    history.push("/sign-up")
                } else {
                    setsetpNumber(3)
                    history.push("/sign-up")
                }
            } else {
                if (state?.auth?.token) {
                    setsetpNumber(1)
                    history.push("/sign-up")
                } else {
                    setsetpNumber(0)
                }
            }
        }

    }, [state?.auth?.data, state?.branch])
    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <img src={Logo} alt='logo' width="300px" />
                <h2 className='formField'>Online Ordering and Point of Sales(POS)</h2>
                <div className={style.registration}>
                    <Landing {...props} currentStep={setpNumber} />
                </div>
            </div>
        </div>)
};

export default authentication