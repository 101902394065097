import { fetchData } from "../../Api/api";
import { FETCH_GALARY_NAME_FALIUR, FETCH_GALARY_NAME_SUCCESS, FETCH_GALARY_NAME_REQUEST, FETCH_LIBRAY_IMAGE_SUCCESS, FETCH_LIBRAY_IMAGE_FALIUR, FETCH_LIBRAY_IMAGE_REQUEST } from "./galary.type";

export const fetchGalaryNameRequest = () => {
    return {
        type: FETCH_GALARY_NAME_REQUEST
    }
}

export const fetchGalaryNameSuccess = (data) => {
    return {
        type: FETCH_GALARY_NAME_SUCCESS,
        payload: data
    }
}

export const fetchGalayFaliur = () => {
    return {
        type: FETCH_GALARY_NAME_FALIUR
    }
}

export const fetchImageRequest = () => {
    return {
        type: FETCH_LIBRAY_IMAGE_REQUEST
    }
}

export const getImageSuccess = (data) => {
    return {
        type: FETCH_LIBRAY_IMAGE_SUCCESS,
        payload: data
    }
}

export const fetchImageFaliur = () => {
    return {
        type: FETCH_LIBRAY_IMAGE_FALIUR
    }
}

export const getGalayName = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchGalaryNameRequest())
        fetchData(endpoint)
            .then(res => dispatch(fetchGalaryNameSuccess(res.data.response)))
                .catch(error => dispatch(fetchGalayFaliur()))
    }
}

export const getLibrayImage = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchImageRequest())
        fetchData(endpoint)
            .then(res => dispatch(getImageSuccess(res.data.response)))
                .catch(error => dispatch(fetchImageFaliur()))
    }
}