import { FETCH_RECIEPE_REQUEST, FETCH_RECIEPE_SUCCESS, FETCH_RECIEPE_FALIOUR, FETCH_RECIEPE_DATA, HANDLE_RECIEPE_STATUS_MESSAGE} from './reciepe.types'


const initialState = {
    loader: false,
    data: [],
    status: '',
    message: ''
}

export const reciepeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECIEPE_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_RECIEPE_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_RECIEPE_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_RECIEPE_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_RECIEPE_STATUS_MESSAGE: 
            return {
                ...state,
                loader: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}