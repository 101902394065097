import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import { updateBranch } from '../../redux';


const ColorPicker = () => {
    
    const state = useSelector(state => state)
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
        form_side: {
            width: "300px",
            padding: "1rem"
        },
        display: {
            display: "flex",
            flexWrap: "wrap"
        },
        commonColor: {
            backgroundColor: theme.palette.primary.main,
        },
        color: {
            color: theme.palette.primary.contrastText
        },
        bgColorCondition: {
            backgroundColor: state?.auth?.data?.client_user_color_theme === "dark" ? '#1e2022' : theme.palette.secondary.light,
        }
    }));

    const style = useStyles()
    
    const dispatch = useDispatch()


    const colorPannel = ['#00365a', '#145388', '#008ecc', '#576a3d', '#6fb327', '#922c88', '#ed7117', '#900604', '#c0a145', '#48494b']
    return (
        <div className={`${style.form_side} ${style.bgColorCondition}`}>
            <p className={`text-muted my-3 ${style.color}`}>Light Theme:</p>
            <div className={style.display}>
                {
                    colorPannel.map(res =>
                        <label className={`custom`} key={`${res}_light`}>
                            <input type="radio" name={`${res}light`}
                                onChange={() => dispatch(updateBranch({
                                    _id: state?.branch?.activeBranch[0]?._id,
                                    branch_color_hex_value: res, branch_color_theme: "light"
                                }, state?.auth?.data?._id))}
                                checked={res === state?.branch?.activeBranch[0]?.branch_color_hex_value &&
                                    state?.branch?.activeBranch[0]?.branch_color_theme === 'light'} />
                            <span className="checkmark" style={{ backgroundColor: res, borderColor: res }}></span>
                        </label>)
                }
            </div>
            <p className={`text-muted my-3 ${style.color}`}>Dark Theme:</p>
            <div className={style.display}>
                {
                    colorPannel.map(res =>
                        <label className="custom" key={`${res}_dark`}>
                            <input type="radio" name="dark" onChange={() => dispatch(updateBranch({
                                    _id: state?.branch?.activeBranch[0]?._id,
                                    branch_color_hex_value: res, branch_color_theme: "dark"
                                }, state?.auth?.data?._id))}
                                checked={res === state?.branch?.activeBranch[0]?.branch_color_hex_value &&
                                    state?.branch?.activeBranch[0]?.branch_color_theme === 'dark'} 
                            />
                            <span className="checkmark" style={{ backgroundColor: res, borderColor: res }}></span>
                        </label>)
                }
            </div>
            <p className={`text-muted my-3 ${style.color}`}>Border Radius:</p>
            <div className={style.display}>
                <label className="custom">Rounded
                    <input type="radio" name="borderRadius" checked={state?.auth?.data?.client_user_color_border_radius !== "0px"}
                        onChange={() => dispatch(updateBranch({
                            client_user_color_border_radius: "20px",
                        }, state?.auth?.data?._id))} />
                    <span className={`checkmark2 ${style.commonColor}`}></span>
                </label>
                <label className="custom">Flat
                    <input type="radio" name="borderRadius" checked={state?.auth?.data?.client_user_color_border_radius === "0px"} 
                        onChange={() => dispatch(updateBranch({
                            client_user_color_border_radius: "0px",
                        }, state?.auth?.data?._id))} />
                    <span className={`checkmark2 ${style.commonColor}`}></span>
                </label>
            </div>
        </div>
    );
};

export default ColorPicker;
