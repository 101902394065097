import {FETCH_GENERAL_SETTING_DATA, FETCH_GENERAL_SETTING_REQUEST, FETCH_GENERAL_SETTING_SUCCESS,
     FETCH_GENERAL_SETTING_FALIOUR, HANDLE_GENERAL_SETTING_STATUS_MESSAGE} from './generalSetting.types'
import {fetchData,updateData} from '../../../Api/api'

export const fetchGeneralSettingsAccess = () => {
    return {
        type: FETCH_GENERAL_SETTING_REQUEST
    }
}

export const fetchGeneralSettingsSuccess = (data) => {
    return {
        type: FETCH_GENERAL_SETTING_SUCCESS,
        payload: data
    }
}

export const fetchGeneralSettingsFaliour = (data) => {
    return {
        type: FETCH_GENERAL_SETTING_FALIOUR,
        payload : data
    }
}

export const fetchGeneralSettingsFormData = (data) => {
    return {
        type: FETCH_GENERAL_SETTING_DATA,
        payload: data
    }
}

export const handleinventoryGeneralStatus = (data) => {
    return {
        type: HANDLE_GENERAL_SETTING_STATUS_MESSAGE,
        payload: data
    }
}

export const getGeneralSettings = (user_id) => {
    return (dispatch) => {
        dispatch(fetchGeneralSettingsAccess())
        fetchData(`get_inventory_settings`, user_id).then(
            response => dispatch(fetchGeneralSettingsSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchGeneralSettingsFaliour(error.message))
        )
    }
}


export const updateGeneralSettings = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchGeneralSettingsAccess())
        updateData(`add_or_update_inventory_settings`,body, user_id).then(
            response => {
                dispatch(fetchGeneralSettingsFormData(response.data))
                response.data.status === 'success' && dispatch(getGeneralSettings(user_id))
            }
        ).catch(
            error => dispatch(fetchGeneralSettingsFaliour(error.message))
        )
    }
}
