import {
  TextField,
  Button,
  ButtonGroup,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import style from "../authentication.module.css";
import { MdEmail, AiOutlineMobile, FaLock } from "react-icons/all";
import LongMenu from "../../GetCountryCode";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postloginbyEmail } from "../../../redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { app } from "../../../firebase/firebase";
import authentication from "../index";
const SignIn = () => {
  const inputRef = useRef(null);
  const auth = getAuth(app);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loginPhone, setloginPhone] = useState(true);
  const [loginPhoneOtp, setloginPhoneOtp] = useState(false);
  const [confirmOtp, setconfirmOtp] = useState(null);
  const [authentication, setauthentication] = useState({
    status: "",
    message: "",
  });

  const [formValue, setformValue] = useState({
    emailphone: "",
    emailphoneError: "",
    passwordError: "",
    phoneCode: null,
    password: "",
    passwordSee: false,
  });

  const [otp, setotp] = useState({
    otp1: "",
    inputRef1: useRef(null),
    otp2: "",
    inputRef2: useRef(null),
    otp3: "",
    inputRef3: useRef(null),
    otp4: "",
    inputRef4: useRef(null),
    otp5: "",
    inputRef5: useRef(null),
    otp6: "",
    inputRef6: useRef(null),
  });

  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    state?.countryCurrency?.allCountry?.map(
      (res) =>
        res?.timezones?.includes(tzid) &&
        setformValue({ ...formValue, phoneCode: res })
    );
  }, [tzid, state?.countryCurrency?.allCountry]);

  const onchange = (e) => {
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
      emailphoneError: "",
    });
  };

  const onSignInSubmit = () => {
    var recaptchaWrapperRef = document.createElement("div");
    recaptchaWrapperRef.innerHTML = '<div id="recaptcha-container"></div>';
    document.body.appendChild(recaptchaWrapperRef);
    const phoneNumber = formValue.phoneCode.phone.concat(formValue.emailphone);
    // configureCapcha()
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
    try {
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          setconfirmOtp(confirmationResult);
          setloginPhoneOtp(true);
          if (appVerifier && recaptchaWrapperRef) {
            appVerifier.clear();
            document.getElementById("recaptcha-container").remove();
          }
        })
        .catch((error) => {
          console.log(error);
          setauthentication({
            status: "error",
            message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase(),
          });
        });
    } catch (error) {
      setauthentication({
        status: "error",
        message: "NetWork Error",
      });
    }
  };

  const onSignInValid = () => {
    const testOtp =
      otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6;
    try {
      confirmOtp
        .confirm(testOtp)
        .then((response) => {
          setauthentication({
            status: "success",
            message: "User Authenticated SuccessFully",
          });
          dispatch(
            postloginbyEmail(`firebase_token_to_client_user`, {
              firebase_token: response.user.accessToken,
            })
          );
        })
        .catch((error) => {
          setauthentication({
            status: "error",
            message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase(),
          });
        });
    } catch (error) {
      setauthentication({
        status: "error",
        message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase(),
      });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (!formValue.emailphone && !formValue.password) {
      setformValue({
        ...formValue,
        emailphoneError: "Please Enter a Valid Phone Number or Email id",
        passwordError: "Password is required",
      });
    } else if (!formValue.emailphone) {
      setformValue({
        ...formValue,
        emailphoneError: "Please Enter a Valid Phone Number or Email id",
      });
    } else if (!formValue.password) {
      setformValue({ ...formValue, passwordError: "Password is required" });
    } else {
      if (
        formValue.emailphone.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        dispatch(
          postloginbyEmail(`client_user_login_email_password`, {
            email: formValue.emailphone,
            password: formValue.password,
          })
        );
      } else if (formValue.emailphone && formValue?.phoneCode) {
        if (
          formValue.phoneCode.phone === "+880" &&
          formValue.emailphone.charAt(0) === "0" &&
          formValue.emailphone.length > 10
        ) {
          const phone_number = formValue.emailphone.slice(1);
          dispatch(
            postloginbyEmail(`client_user_login_phone_password`, {
              phone: formValue.phoneCode.phone.concat(phone_number),
              password: formValue.password,
            })
          );
        } else {
          dispatch(
            postloginbyEmail(`client_user_login_phone_password`, {
              phone: formValue.phoneCode.phone.concat(formValue.emailphone),
              password: formValue.password,
            })
          );
        }
      } else {
        setformValue({
          ...formValue,
          emailphoneError: "Please Enter a Valid Phone Number or Email id",
        });
      }
    }
  };

  const sendOtp = () => {
    if (
      formValue.emailphone.match(/^[0-9\b]+$/) &&
      formValue.emailphone.length >= 10 &&
      formValue.phoneCode
    ) {
      otp?.inputRef1?.current?.focus();
      onSignInSubmit();
    } else {
      setformValue({
        ...formValue,
        emailphoneError: "Please Enter a Valid Phone Number",
      });
    }
  };

  useEffect(() => {
    if (
      formValue.emailphone.match(/^[0-9\b]+$/) &&
      formValue.emailphone.length >= 10 &&
      formValue.phoneCode
    ) {
      if (
        formValue.phoneCode.phone === "+880" &&
        formValue.emailphone.charAt(0) === "0" &&
        formValue.emailphone.length > 10
      ) {
        const phone_number = formValue.emailphone.slice(1);
        dispatch(
          postloginbyEmail(`check_client_phone_number`, {
            client_user_phone_number:
              formValue.phoneCode.phone.concat(phone_number),
          })
        );
      } else {
        dispatch(
          postloginbyEmail(`check_client_phone_number`, {
            client_user_phone_number: formValue.phoneCode.phone.concat(
              formValue.emailphone
            ),
          })
        );
      }
    }
  }, [formValue?.emailphone, formValue?.phoneCode]);

  useEffect(() => {
    if (otp.otp1) {
      if (otp.otp2) {
        if (otp.otp3) {
          if (otp.otp4) {
            if (otp.otp5) {
              if (otp.otp6) {
                onSignInValid();
              }
            }
          }
        }
      }
    }
  }, [otp]);

  useEffect(() => {
    inputRef?.current?.focus();
    return () => {
      inputRef.current = null;
    };
  }, [inputRef]);

  return (
    <>
      <form className="px_20" onSubmit={(e) => submit(e)}>
        <div>
          <div className="d_flex">
            <ButtonGroup aria-label="outlined button group">
              <Button
                variant={!loginPhone ? "contained" : "outlined"}
                color="secondary"
                className={!loginPhone && "button"}
                onClick={() => setloginPhone(false)}
              >
                <MdEmail />
              </Button>
              <Button
                variant={loginPhone ? "contained" : "outlined"}
                color="secondary"
                className={loginPhone && "button"}
                onClick={() => setloginPhone(true)}
              >
                <AiOutlineMobile />
              </Button>
              {loginPhone && (
                <LongMenu
                  selectcountry={formValue?.phoneCode}
                  countryChange={(country) =>
                    setformValue({
                      ...formValue,
                      phoneCode: country,
                      emailphoneError: "",
                    })
                  }
                />
              )}
            </ButtonGroup>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={loginPhone ? "Phone" : "Email"}
              name="emailphone"
              error={formValue.emailphoneError}
              value={formValue.emailphone}
              onChange={(e) => onchange(e)}
            />
          </div>
          <Typography color="error" variant="caption">
            {formValue.emailphoneError}
          </Typography>
        </div>
        {loginPhoneOtp ? (
          <div className={style.otp}>
            <input
              ref={otp.inputRef1}
              value={otp.otp1}
              name="otp1"
              autoComplete="off"
              type="text"
              maxLength="1"
              onChange={(e) => {
                setotp({
                  ...otp,
                  otp1: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp1
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? otp.inputRef2.current.focus()
                    : otp.inputRef1.current.focus()
                  : otp.inputRef1.current.focus();
              }}
            />
            <input
              ref={otp.inputRef2}
              type="text"
              maxLength="1"
              name="otp2"
              autoComplete="off"
              value={otp.otp2}
              onChange={(e) => {
                console.log(e);
                setotp({
                  ...otp,
                  otp2: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp2
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? otp.inputRef3.current.focus()
                    : otp.inputRef2.current.focus()
                  : otp.inputRef1.current.focus();
              }}
              onKeyDown={(e) =>
                e.key === "Backspace" &&
                !otp.otp2 &&
                otp.inputRef1.current.focus()
              }
            />
            <input
              ref={otp.inputRef3}
              type="text"
              maxLength="1"
              name="otp3"
              autoComplete="off"
              value={otp.otp3}
              onChange={(e) => {
                setotp({
                  ...otp,
                  otp3: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp3
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? otp.inputRef4.current.focus()
                    : otp.inputRef3.current.focus()
                  : otp.inputRef2.current.focus();
              }}
            />
            <input
              ref={otp.inputRef4}
              type="text"
              maxLength="1"
              name="otp4"
              autoComplete="off"
              value={otp.otp4}
              onChange={(e) => {
                setotp({
                  ...otp,
                  otp4: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp4
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? otp.inputRef5.current.focus()
                    : otp.inputRef4.current.focus()
                  : otp.inputRef3.current.focus();
              }}
            />
            <input
              ref={otp.inputRef5}
              type="text"
              maxLength="1"
              name="otp5"
              autoComplete="off"
              value={otp.otp5}
              onChange={(e) => {
                setotp({
                  ...otp,
                  otp5: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp5
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/)
                    ? otp.inputRef6.current.focus()
                    : otp.inputRef5.current.focus()
                  : otp.inputRef4.current.focus();
              }}
            />
            <input
              ref={otp.inputRef6}
              type="text"
              maxLength="1"
              name="otp6"
              autoComplete="off"
              value={otp.otp6}
              onChange={(e) => {
                setotp({
                  ...otp,
                  otp6: e.target.value
                    ? e.target.value.match(/^[0-9\b]+$/)
                      ? e.target.value
                      : otp.otp6
                    : "",
                });
                e.target.value
                  ? e.target.value.match(/^[0-9\b]+$/) &&
                    otp.inputRef6.current.focus()
                  : otp.inputRef5.current.focus();
              }}
            />
          </div>
        ) : (
          <div>
            <div className="d_flex">
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button color="secondary">
                  <FaLock />
                </Button>
              </ButtonGroup>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Password"
                type={formValue.passwordSee ? "text" : "password"}
                className={style.auth}
                error={formValue.passwordError}
                name="password"
                value={formValue.password}
                onChange={(e) => onchange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        variant="text"
                        type="button"
                        size="small"
                        onClick={() =>
                          setformValue({
                            ...formValue,
                            passwordSee: !formValue.passwordSee,
                          })
                        }
                      >
                        {formValue.passwordSee ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Typography color="error" variant="caption">
              {formValue.passwordError}
            </Typography>
          </div>
        )}
        <div className="d_flex justify_content_between align_item_center">
          <Link to="/reset-password">Forget Password</Link>
          {/* {
                        loginPhone &&
                        <Button variant="contained" disabled={state?.auth?.message === "Phone Number is available !"}
                            color="primary" className="button" onClick={() => sendOtp()}>
                            {loginPhoneOtp ? "Resend Otp" : "send otp"}
                        </Button>
                    } */}
          <Button
            variant="contained"
            color="primary"
            className="button"
            type="submit"
          >
            Login
          </Button>
        </div>
      </form>
      {state?.auth?.status === "#55ed92" &&
        state?.auth?.message === "Phone Number is available !" && (
          <Typography color="error">
            Sorry Your Phone Number Is Not Registered , Choose Any Phone Number
            Which is Registered
          </Typography>
        )}
      {authentication.status && (
        <Typography color={authentication.status}>
          {authentication.message}
        </Typography>
      )}
      {state?.auth?.status &&
        state?.auth?.message !==
          "Sorry Your Phone Number Is Already Registered , Choose Another Phone Number !" &&
        state?.auth?.message !== "Phone Number is available !" &&
        state?.auth?.message !== "The Phone number is register with us !" && (
          <div
            className="m_20"
            style={{ color: state?.auth?.status }}
            dangerouslySetInnerHTML={{
              __html: state.auth.message,
            }}
          />
        )}
      <div className={style.footer}>
        <Link to="/sign-up">No Account ? Sign up here</Link>
      </div>
    </>
  );
};

export default authentication(SignIn);
