import { FETCH_SEO_REQUEST, FETCH_SEO_SUCCESS, FETCH_SEO_FALIOUR, FETCH_SEO_DATA, HANDLE_SEO_CONNTROL } from "./seoTypes";

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const seoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEO_REQUEST:
            return {
                ...state, loader: true
            }

        case HANDLE_SEO_CONNTROL:
            return {
                ...state,
                status: '',
                message: ''
            }

        case FETCH_SEO_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_SEO_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_SEO_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}