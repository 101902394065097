import { FETCH_HANDLE_DELIVARY_ZONES_REQUEST,FETCH_HANDLE_DELIVARY_ZONES_SUCCESS,FETCH_HANDLE_DELIVARY_ZONES_FALIOUR,FETCH_HANDLE_DELIVARY_ZONES_FORMDATA } from "./delivaryZoneType";
import {deleteData, fetchData, postData } from "../../Api/api";

export const fetchDelivaryZones = () => {
    return {
        type: FETCH_HANDLE_DELIVARY_ZONES_REQUEST
    }
}

export const fetchDelivarySuceess = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_ZONES_SUCCESS,
        payload: data
    }
}

export const fetchDelivaryFaliour = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_ZONES_FALIOUR,
        payload :data
    }
}

export const fetchDelivaryData = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_ZONES_FORMDATA,
        payload: data
    }
}

export const getDelivaryZones = (user_id) => {
    return (dispatch) => {
        dispatch(fetchDelivaryZones())
        fetchData(`get_all_delivery_zone_by_client_user_id`, user_id).then(
            response => dispatch(fetchDelivarySuceess(response.data.response))
        ).catch(
            error => fetchDelivaryFaliour(error.message)
        )
    }
}

export const postDelivaryZones = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchDelivaryZones())
        postData(`add_delivery_zone`,body, user_id).then(
            response => {
                dispatch(fetchDelivaryData(response.data))
                response.data.status === 'success' && dispatch(getDelivaryZones(user_id))
            }
        ).catch(
            error => fetchDelivaryFaliour(error.message)
        )
    }
}

export const deleteDelivaryZones = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchDelivaryZones())
        deleteData(`delete_delivery_zone`, body, user_id).then(
            response => {dispatch(fetchDelivaryData(response.data))
            response.data.status === 'success' && dispatch(getDelivaryZones(user_id))}
        ).catch(
            error => fetchDelivaryFaliour(error.message)
        )
    }
}