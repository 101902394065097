import {HANDLE_STATUS_MESSAGE,
    HANDLE_LOGIN_FALIOUR, HANDLE_LOGIN_SUCCESS, HANDLE_LOGIN_REQUEST, HANDLE_LOGIN_FORM_DATA, HANDLE_PROFILE_PICTURE,
    HANDLE_LOGIN_UPDATE_DATA, HANDLE_AUTH_REQUEST, HANDLE_LOGIN_UPDATE_PHONE_EMAIL
} from './loginTypes'

const initialState = {
    loading: true,
    loader: false,
    data: null,
    token: '',
    status: '',
    message: '',
    profileImage: ''
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_LOGIN_REQUEST:
            return {
                ...state, loader: true
            }
        case HANDLE_AUTH_REQUEST: {
            return {
                ...state, loading: true
            }
        }
        case HANDLE_STATUS_MESSAGE: {
            return {
                ...state, 
                status: '',
                message: '',
                loading: false,
                loader: false,
            }
        }
        case HANDLE_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loader: false,
                token: action.payload.token,
                data: action.payload.response,
                status: '',
                message: '',
                profileImage: ""
            }
        case HANDLE_LOGIN_UPDATE_DATA:
            return {
                ...state,
                loading: false,
                loader: false,
                data: action.payload.status === "success" ? action.payload.response : state.data,
                status: action.payload.status === "success" ? 'green' : 'red',
                message: action.payload.message,
                profileImage: ""
            }
        case HANDLE_LOGIN_FALIOUR:
            return {
                ...state,
                loading: false,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_LOGIN_FORM_DATA:
            return {
                ...state,
                loading: false,
                loader: false,
                token: action.payload.token,
                data: action.payload.response,
                status: action.payload.status === "success" ? '#55ed92' : 'red',
                message: action.payload.message,
                profileImage: ""
            }
        case HANDLE_LOGIN_UPDATE_PHONE_EMAIL:
            return {
                ...state,
                loading: false,
                loader: false,
                status: action.payload.status === "success" ? 'success' : 'error',
                message: action.payload.message,
                profileImage: ""
            }
        case HANDLE_PROFILE_PICTURE:
            return {
                ...state,
                loading: false,
                loader: false,
                profileImage: action.payload
            }
        default:
            return state
    }
}