import {
  createMuiTheme,
  colors,
  ThemeProvider,
  // useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  auth,
  getAllCatagory,
  getAllCountry,
  getBasicPlan,
  getBranchById,
  getIconLogoDataAll,
  getPlan,
} from "./redux";
import authclass from "./components/Auth";
import ReportPage from "./pages/DashBoard/ReportPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import Loader from "./components/Loader";
import SignIn from "./components/authentication/SignIn";
import Registration from "./components/authentication/Registration";
import ResetPassword from "./components/authentication/ForgetPassword";
import PrivacyPolicy from "./components/authentication/PrivacyPolicy/PrivacyPolicy";
import { AdvancePlanPage } from "./pages/Profile/AdvancePlanPage";

const route = [
  {
    link: "/dashboard/marketing",
    component: React.lazy(() => import("./pages/DashBoard/DashBoardPage")),
  },
  { link: "/dashboard/report", component: ReportPage },
  {
    link: "/products/allproduct",
    component: React.lazy(() => import("./pages/products/ProductPage")),
  },
  {
    link: "/products/category",
    component: React.lazy(() => import("./pages/products/Category")),
  },
  {
    link: "/products/addproduct",
    component: React.lazy(() => import("./pages/products/AddProduct")),
  },
  {
    link: "/products/addons",
    component: React.lazy(() => import("./pages/products/AddonsPage")),
  },
  {
    link: "/customer/customerlist",
    component: React.lazy(() => import("./pages/Customer/Customers")),
  },
  {
    link: "/customer/customer-group",
    component: React.lazy(() => import("./pages/Customer/CustomerGroupPage")),
  },
  {
    link: "/orders",
    component: React.lazy(() => import("./pages/Orders/Orders")),
  },
  {
    link: "/inventory/general-settings",
    component: React.lazy(() => import("./pages/Inventory/GeneralSettings")),
  },
  {
    link: "/inventory/view-inventory",
    component: React.lazy(() => import("./pages/Inventory/ManageReciepe")),
  },
  {
    link: "/inventory/inventory-group",
    component: React.lazy(() =>
      import("./pages/Inventory/ReciepeCategoryPage")
    ),
  },
  {
    link: "/inventory/unit",
    component: React.lazy(() => import("./pages/Inventory/UnitPage")),
  },
  {
    link: "/inventory/reciepe",
    component: React.lazy(() => import("./pages/products/ReciepePage")),
  },
  {
    link: "/inventory/add-reciepe",
    component: React.lazy(() => import("./pages/products/AddReciepe")),
  },
  {
    link: "/web-app-builder/fetures",
    component: React.lazy(() => import("./pages/WebAppBuilder/FeturePage")),
  },
  {
    link: "/web-app-builder/banners",
    component: React.lazy(() => import("./pages/WebAppBuilder/BannerPage")),
  },
  {
    link: "/web-app-builder/homepage-banner",
    component: React.lazy(() =>
      import("./pages/WebAppBuilder/PopupBannerPage")
    ),
  },
  {
    link: "/store-basics/location",
    component: React.lazy(() => import("./pages/Settings/BasicSettings")),
  },
  {
    link: "/store-basics/icon-logo-settings",
    component: React.lazy(() => import("./pages/Settings/IconLogoPage")),
  },
  {
    link: "/store-basics/social-settings",
    component: React.lazy(() => import("./pages/Settings/SocialPage")),
  },
  {
    link: "/store-basics/create-location",
    component: React.lazy(() => import("./pages/Settings/AddBasicSettings")),
  },
  {
    link: "/store-basics/operational-hours",
    component: React.lazy(() =>
      import("./pages/Settings/OperationalHoursPage")
    ),
  },
  {
    link: "/store-basics/payment-getway",
    component: React.lazy(() => import("./pages/Settings/PaymentGateWayPage")),
  },
  {
    link: "/store-basics/preferrences",
    component: React.lazy(() => import("./pages/Settings/PrefferencePage")),
  },
  {
    link: "/store-basics/administator",
    component: React.lazy(() => import("./pages/Settings/AccessControlPage")),
  },
  {
    link: "/store-basics/create-administator",
    component: React.lazy(() =>
      import("./pages/Settings/CreateAdministratorPage")
    ),
  },
  {
    link: "/store-basics/menutime",
    component: React.lazy(() => import("./pages/Settings/MenutimePage")),
  },
  {
    link: "/store-basics/deliveryzone",
    component: React.lazy(() => import("./pages/Settings/DelivaryZonePage")),
  },
  {
    link: "/store-basics/tax",
    component: React.lazy(() => import("./pages/products/TaxPage")),
  },
  {
    link: "/store-basics/cms",
    component: React.lazy(() => import("./pages/Settings/CmsPage")),
  },
  {
    link: "/store-basics/qrdetails",
    component: React.lazy(() => import("./pages/Settings/QRCodePage")),
  },
  {
    link: "/store-basics/custom-domain",
    component: React.lazy(() => import("./pages/Settings/UpdateDomainPage")),
  },
  {
    link: "/pos/add-register-pos",
    component: React.lazy(() =>
      import("./pages/Settings/posRegister/AddRegisterPosPage")
    ),
  },
  {
    link: "/pos/manage-register-pos",
    component: React.lazy(() =>
      import("./pages/Settings/posRegister/ManageReggisterPage")
    ),
  },
  {
    link: "/pos/download-register-pos",
    component: React.lazy(() =>
      import("./pages/Settings/posRegister/DownloadPosRegister")
    ),
  },
  {
    link: "/promotion/coupon",
    component: React.lazy(() => import("./pages/promotion/Coupon")),
  },
  {
    link: "/promotion/seo",
    component: React.lazy(() => import("./pages/promotion/Seo")),
  },
  {
    link: "/promotion/coupon",
    component: React.lazy(() => import("./pages/promotion/Coupon")),
  },
  {
    link: "/promotion/configure-email",
    component: React.lazy(() => import("./pages/promotion/ConfigureEmailPage")),
  },
  {
    link: "/promotion/configure-sms",
    component: React.lazy(() => import("./pages/promotion/ConfigureSmsPage")),
  },
  {
    link: "/promotion/push-notifications",
    component: React.lazy(() =>
      import("./pages/promotion/PushNotificationPage")
    ),
  },
  {
    link: "/stuff",
    component: React.lazy(() => import("./pages/Stuff/StuffPage")),
  },
  {
    link: "/change-password",
    component: React.lazy(() => import("./pages/Profile/ChangePasswordPage")),
  },
  { link: "/advance-plan", component: AdvancePlanPage },
];

function App() {
  const history = useHistory();
  const state = useSelector((state) => state);
  const queryParameters = new URLSearchParams(window.location.search);
  let branch = queryParameters.get("branch");
  let jwt = queryParameters.get("jwt");
  if (!jwt) {
    jwt = localStorage.getItem("user-token");
  }

  if (!branch) {
    branch = localStorage.getItem("branch-id");
  }

  const dispatch = useDispatch();
  const themes = createMuiTheme({
    palette: {
      primary: {
        main:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_hex_value
            ? state?.branch?.activeBranch[0]?.branch_color_hex_value
            : "#ff0000",
        contrastText:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#8f8f8f"
            : "#707070",
        light:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#232223"
            : "#ffffff",
      },
      secondary: {
        main:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#707070"
            : "#707070",
        contrastText:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#ffffff"
            : "#1e2022",
        light:
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#1e2022"
            : "#ffffff",
      },
    },
    typography: {
      h2: {
        fontSize: "1.5rem",
        color:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#8f8f8f"
            : "#3a3a3a",
      },
      body1: {
        fontSize: "1rem",
        color:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#8f8f8f"
            : "#3a3a3a",
      },
      body3: {
        fontSize: "0.75rem",
        color:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#8f8f8f"
            : "#3a3a3a",
      },
      h6: {
        fontSize: "1.1rem",
        color:
          state?.auth?.data?.client_user_subdomain &&
          state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
            ? "#8f8f8f"
            : "#3a3a3a",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius:
            state?.auth?.data?.client_user_subdomain &&
            state?.auth?.data?.client_user_color_border_radius
              ? state?.auth?.data?.client_user_color_border_radius
              : "4px",
        },
      },
      MuiTextField: {
        root: {
          backgroundColor:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "#ffffff",
          color:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#ffffff"
              : "#000000",
        },
      },
      MuiSelect: {
        root: {
          backgroundColor:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "transparent",
        },
      },
      MuiOutlinedInput: {
        root: {
          // borderColor: "2px soild red",
          color:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#ffffff"
              : "#000000",
        },
      },
      MuiInput: {
        root: {
          borderColor: "2px soild red",
          // backgroundColor: state?.branch?.activeBranch[0]?.branch_color_theme === "dark" ? "#232223" : "transparent",
          // color: "#8f8f8f"
        },
      },
      MuiInputBase: {
        root: {
          borderColor: "2px soild red",
          color:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#ffffff"
              : "#000000",
        },
      },
      MuiMenu: {
        paper: {
          backgroundColor:
            state?.auth?.data?.client_user_subdomain &&
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "#ffffff",
        },
      },
      MuiAccordionSummary: {
        content: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "transparent",
        },
      },
      MuiAccordionDetails: {
        root: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "transparent",
        },
      },
      MuiInputLabel: {
        shrink: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#232223"
              : "#ffffff",
        },
      },
      MuiDialogContent: {
        root: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#1e2022"
              : "#ffffff",
        },
      },
      MuiDialogActions: {
        root: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#1e2022"
              : "#ffffff",
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor:
            state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
              ? "#1e2022"
              : "#ffffff",
        },
      },
      // MuiTypography: {
      //   colorInherit: {
      //     color: "#707070"
      //   },
      //   root: {
      //     color: "#707070 !important"
      //   }
      // },
      // MuiIconButton: {
      //   label: {
      //     color: "#707070 !important"
      //   }
      // }
    },
  });

  // const matchs = useMediaQuery(themes.breakpoints.up("sm"));

  useEffect(() => {
    if (jwt) {
      dispatch(auth(jwt));
      dispatch(getAllCountry());
    } else {
      window.location.href = "https://admin.robofood.io/";
    }
  }, [dispatch, jwt]);

  useEffect(() => {
    state?.auth?.token && authclass.setToken(state?.auth?.token);
  }, [state?.auth?.token]);
  useEffect(() => {
    if (branch) localStorage.setItem("branch-id", branch);
  }, [branch]);

  // window.requestFullscreen();

  // const some = document.getElementById("app");

  // some.requestFullscreen();

  useEffect(() => {
    if (state?.auth?.data?.client_user_id) {
      dispatch(
        getAllCatagory(
          `populate_all_category`,
          state?.auth?.data?.client_user_id
        )
      );
      dispatch(getBranchById(state?.auth?.data?.client_user_id, branch));
      dispatch(getIconLogoDataAll(state?.auth?.data?.client_user_id));
      dispatch(getBasicPlan(state?.auth?.data?.client_user_id));
      dispatch(getPlan(state?.auth?.data?.client_user_id));
    }
  }, [dispatch, state?.auth?.data]);

  return (
    <ThemeProvider theme={themes}>
      {state?.auth?.loading ? (
        <div className="loaderBg">
          <CircularProgress
            color={
              state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
                ? "#ffffff"
                : colors.grey[400]
            }
          />
        </div>
      ) : (
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/sign-in" component={SignIn} exact />
            <Route path="/sign-up" component={Registration} exact />
            <Route path="/reset-password" component={ResetPassword} exact />
            <Route path="/legal" component={PrivacyPolicy} exact />
            {route.map((res) => {
              return !state?.auth?.data?.client_user_is_sub_admin ? (
                <PrivateRoute
                  path={res.link}
                  key={res.link}
                  condition={res.link === "/store-basics/create-location"}
                  component={res.component}
                  exact
                />
              ) : (
                res.link !== "/store-basics/administator" &&
                  res.link !== "/store-basics/create-administator" && (
                    <PrivateRoute
                      path={res.link}
                      key={res.link}
                      condition={res.link === "/store-basics/create-location"}
                      component={res.component}
                      exact
                    />
                  )
              );
            })}
            <PrivateRoute path="/profile" component={ProfilePage} exact />
            <Redirect strict from="/" to="/dashboard/marketing" exact />
          </Switch>
        </React.Suspense>
      )}
    </ThemeProvider>
  );
}

export default App;
