import {
    HANDLE_CUPON_REQUEST, HANDLE_CUPON_SUCCESS, HANDLE_CUPON_FALIOUR, HANDLE_CUPON_FORM_DATA, HANDLE_CUPON_STATUS_MESSAGE,
    GET_CUPON_DATA_BY_ID
} from "./cuponTypes";
import { fetchData, postData, updateData,deleteData } from "../../Api/api";

export const fetchCuponRequest = () => {
    return {
        type: HANDLE_CUPON_REQUEST
    }
}

export const fetchCuponSucess = (data) => {
    return {
        type: HANDLE_CUPON_SUCCESS,
        payload: data
    }
}

export const fetchCuponSucessById = (data) => {
    return {
        type: GET_CUPON_DATA_BY_ID,
        payload: data
    }
}

export const fetchCuponFaliour = (data) => {
    return {
        type: HANDLE_CUPON_FALIOUR,
        payload: data
    }
}

export const handleCuponData = (data) => {
    return {
        type: HANDLE_CUPON_FORM_DATA,
        payload: data
    }
}

export const handleCouponStatusMessage = (data) => {
    return {
        type: HANDLE_CUPON_STATUS_MESSAGE,
        payload: data
    }
}

export const getAllCupon = (user_id, endpoint = `get_all_coupons?limit=10&page=1`) => {
    return (dispatch) => {
        dispatch(fetchCuponRequest())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchCuponSucess(response.data))
        ).catch(
            error => dispatch(fetchCuponFaliour(error.message))
        )
    }
}

export const getCuponById = (id, user_id) => {
    return (dispatch) => {
        dispatch(fetchCuponRequest())
        fetchData(`get_Cupon/${id}`, user_id).then(
            response => dispatch(fetchCuponSucessById(response.data.response))
        ).catch(
            error => dispatch(fetchCuponFaliour(error.message))
        )
    }
}

export const postCupon = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchCuponRequest())
            postData(`add_coupon`, body, user_id).then(
                response => {
                    dispatch(handleCuponData(response.data))
                    response.data.status === 'success' && dispatch(getAllCupon(user_id, `get_all_coupons?limit=50&page=1`))
                }
            ).catch(
                error => dispatch(fetchCuponFaliour(error.message))
            )
        console.log(body);
    }
}

export const updateCupon = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchCuponRequest())
        updateData(`update_coupon`, body, user_id).then(
            response => {
                dispatch(handleCuponData(response.data))
                response.data.status === 'success' && dispatch(getAllCupon(user_id, `get_all_coupons?limit=10&page=1`))
            }
        ).catch(
            error => dispatch(fetchCuponFaliour(error.message))
        )
    }
}

export const deleteCupon = (body, user_id) => {
    return (dispatch) => {
        deleteData(fetchCuponRequest())
        postData(`delete_coupon`, body, user_id).then(
            response => {
                dispatch(handleCuponData(response.data))
                response.data.status === 'success' && dispatch(getAllCupon(user_id, `get_all_coupons?limit=10&page=1`))
            }
        ).catch(
            error => dispatch(fetchCuponFaliour(error.message))
        )
    }
}