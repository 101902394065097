class Auth {
    constructor() {
        this.authenticated = false
    }

    setToken(value){
        localStorage.setItem("user-token", value)
    }

    login(cb) {
        cb()
    }

    logout() {
        localStorage.removeItem("user-token")
    }

    // isAuthenticated() {
    //     return localStorage.getItem("auth-token")
    // }
}

export default new Auth()

