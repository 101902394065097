import { FETCH_PAYMENT_REQUEST, FETCH_PAYMENT_FALIOUR, FETCH_PAYMENT_SUCCESS, 
    FETCH_PAYMENT_DATA, FETCH_PAYMENT_STATUS_CONTROL} from "./paymentgateWayTypes";
import {fetchData,updateData} from '../../Api/api'

export const fetchpaymentAccess = () => {
    return {
        type: FETCH_PAYMENT_REQUEST
    }
}

export const fetchpaymentStatusControl = () => {
    return {
        type: FETCH_PAYMENT_STATUS_CONTROL
    }
}

export const fetchpaymentSuccess = (data) => {
    return {
        type: FETCH_PAYMENT_SUCCESS,
        payload: data
    }
}

export const fetchpaymentFaliour = (data) => {
    return {
        type: FETCH_PAYMENT_FALIOUR,
        payload : data
    }
}

export const fetchpaymentFormData = (data) => {
    return {
        type: FETCH_PAYMENT_DATA,
        payload: data
    }
}

export const getpayment = (user_id) => {
    return (dispatch) => {
        dispatch(fetchpaymentAccess())
        fetchData(`get_payment_gateway`, user_id).then(
            response => dispatch(fetchpaymentSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchpaymentFaliour(error.message))
        )
    }
}


export const updatepayment = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchpaymentAccess())
        updateData(`add_or_update_payment_gateway`,body, user_id).then(
            response => {
                dispatch(fetchpaymentFormData(response.data))
                response.data.status === 'success' && dispatch(getpayment(user_id))
            }
        ).catch(
            error => dispatch(fetchpaymentFaliour(error.message))
        )
    }
}
