import { FETCH_REGISTER_POS_REQUEST, FETCH_REGISTER_POS_SUCCESS, FETCH_REGISTER_POS_FALIOUR, FETCH_REGISTER_POS_DATA,
    HANDLE_REGISTER_POS_STATUS_MESSAGE
} from './registerpos.types'
import { deleteData, fetchData, postData, updateData } from '../../Api/api'

export const fetchregisterPosAccess = () => {
    return {
        type: FETCH_REGISTER_POS_REQUEST
    }
}

export const fetchregisterPosSuccess = (data) => {
    return {
        type: FETCH_REGISTER_POS_SUCCESS,
        payload: data
    }
}

export const fetchregisterPosFaliour = (data) => {
    return {
        type: FETCH_REGISTER_POS_FALIOUR,
        payload: data
    }
}

export const fetchregisterPosFormData = (data) => {
    return {
        type: FETCH_REGISTER_POS_DATA,
        payload: data
    }
}

export const handleregisterPosStatus = (data) => {
    return {
        type: HANDLE_REGISTER_POS_STATUS_MESSAGE,
        payload: data
    }
}

export const getregisterPos = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchregisterPosAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchregisterPosSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchregisterPosFaliour(error.message))
        )
    }
}

export const postregisterPos = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchregisterPosAccess())
        postData(`add_register_pos`, body, user_id).then(
            response => {
                dispatch(fetchregisterPosFormData(response.data))
                response.data.status === "success" &&  dispatch(getregisterPos(`get_all_register_pos`, user_id))
            }
        ).catch(
            error => dispatch(fetchregisterPosFaliour(error.message))
        )
    }
}

export const updateregisterPos = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchregisterPosAccess())
        updateData(`update_register_pos`, body, user_id).then(
            response => {
                dispatch(fetchregisterPosFormData(response.data))
                response.data.status === "success" &&  dispatch(getregisterPos(`get_all_register_pos`, user_id))
            }
        ).catch(
            error => dispatch(fetchregisterPosFaliour(error.message))
        )
    }
}

export const deleteregisterPos = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchregisterPosAccess())
        deleteData(`delete_register_pos`, body, user_id).then(
            response => {
                dispatch(fetchregisterPosFormData(response.data))
                response.data.status === "success" &&  dispatch(getregisterPos(`get_all_register_pos`, user_id))
            }
        ).catch(
            error => dispatch(fetchregisterPosFaliour(error.message))
        )
    }
}
