import {
    GET_BRAND_DATA_BY_ID, HANDLE_BRAND_FORM_DATA, HANDLE_BRAND_FALIOUR,
    HANDLE_BRAND_SUCCESS, HANDLE_BRAND_REQUEST, BRAND_IMAGE, HANDLE_BRAND_CONNTROL
} from "./brandTypes";

const initialState = {
    loading: false,
    data: [],
    activeBrand: {},
    totalPages: 0,
    currentPage: 0,
    response: '',
    status: '',
    message: '',
    image_link: ''
}

export const brandReduder = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_BRAND_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_BRAND_CONNTROL:
            return {
                ...state, status: '', message: ''
            }
        case HANDLE_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                status: '',
                message: ''
            }
        case GET_BRAND_DATA_BY_ID:
            return {
                ...state,
                loading: false,
                activeBrand: action.payload
            }
        case BRAND_IMAGE:
            return {
                ...state,
                image_link: action.payload
            }
        case HANDLE_BRAND_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_BRAND_FORM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === "success" ? action.payload.status : 'error',
                message: action.payload.message,
            }
        default:
            return state
    }
}