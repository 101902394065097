import {FETCH_UNIT_DATA, FETCH_UNIT_REQUEST, FETCH_UNIT_SUCCESS, FETCH_UNIT_FALIOUR, HANDLE_UNIT_STATUS_MESSAGE} from './unit.types'


const initialState = {
    loader: false,
    data: [],
    status: '',
    message: ''
}

export const unitReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UNIT_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_UNIT_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_UNIT_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_UNIT_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_UNIT_STATUS_MESSAGE: 
            return {
                ...state,
                loader: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}