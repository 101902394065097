import {FETCH_ICON_LOGO_REQUEST,FETCH_ICON_LOGO_SUCCESS, FETCH_ICON_LOGO_FALIOUR, ICON_LOGO_DATA, ICON_IMAGE, LOGO_IMAGE} from "./iconlogoTypes";
import { fetchData,postData,updateData } from "../../Api/api";

export const fetchIconLogoRequest = () => {
    return {
        type: FETCH_ICON_LOGO_REQUEST
    }
}

export const fetchIconLogoSuccess = (data) => {
    return {
        type : FETCH_ICON_LOGO_SUCCESS,
        payload : data
    }
}

export const fetchIconLogoFaliour = (data) => {
    return {
        type : FETCH_ICON_LOGO_FALIOUR, 
        payload : data
    }
}

export const IconLogoData = (data) => {
    return {
        type : ICON_LOGO_DATA,
        payload : data
    }
}

export const iconImage = (data) => {
    return {
        type : ICON_IMAGE,
        payload: data
    }
}

export const logoImage = (data) => {
    return {
        type : LOGO_IMAGE,
        payload: data
    }
}

export const getIconLogoDataAll = (user_id) => {
    return (dispatch) => {
        dispatch(fetchIconLogoRequest())
        fetchData(`get_icon_logo_caption`, user_id).then(
            response => dispatch(fetchIconLogoSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}

export const postIconLogo = (body) => {
    return (dispatch) => {
        dispatch(fetchIconLogoRequest())
        postData(`add_icon_logo_caption`,body).then(
            response => {
                dispatch(IconLogoData(response.data))
            }
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}


export const updateIconLogo = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchIconLogoRequest())
        updateData(`update_icon_logo_caption`,body, user_id).then(
            response => {
                dispatch(IconLogoData(response.data))
                response.data.status === "success" && dispatch(getIconLogoDataAll(user_id))
            }
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}

export const deleteIconLogo = (body, storage, id, user_id) => {
    return (dispatch) => {
        dispatch(fetchIconLogoRequest())
        let public_id = body.split("/")[8].split(".")[0];
        postData(`remove_image`,{ "image_id": `ilc/${public_id}` }).then(
            response => {
                if (response.data.status === "success") {
                    storage === "logo_image" ? dispatch(updateIconLogo({_id: id, logo: ""}, user_id)) 
                    : dispatch(updateIconLogo({_id: id, icon: ""}, user_id)) 
                    // dispatch(IconLogoData(''))
                }
            }
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}


export const addIconImage = (body) => {
    return (dispatch) => {
        postData(`add_icon_image`,body).then(
            response => dispatch(iconImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}

export const addLogoImage = (body) => {
    return (dispatch) => {
        postData(`add_logo_image`,body).then(
            response => dispatch(logoImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchIconLogoFaliour(error.message))
        )
    }
}