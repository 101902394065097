import {HANDLE_LOGIN_FALIOUR, HANDLE_LOGIN_SUCCESS, HANDLE_LOGIN_REQUEST, HANDLE_LOGIN_FORM_DATA, HANDLE_STATUS_MESSAGE,
     HANDLE_LOGIN_UPDATE_DATA, HANDLE_AUTH_REQUEST, HANDLE_PROFILE_PICTURE, HANDLE_LOGIN_UPDATE_PHONE_EMAIL} from './loginTypes'
import { deleteData, postData, authentication, updateData } from "../../Api/api";

export const fetchloginRequest = () => {
    return {
        type: HANDLE_LOGIN_REQUEST
    }
}

export const fetchauthRequest = () => {
    return {
        type: HANDLE_AUTH_REQUEST
    }
}

export const fetchloginFaliour = (data) => {
    return {
        type: HANDLE_LOGIN_FALIOUR,
        payload: data
    }
}

export const handleloginData = (data) => {
    return {
        type: HANDLE_LOGIN_FORM_DATA,
        payload: data
    }
}

export const handleLoginStatusMessage = () => {
    return {
        type: HANDLE_STATUS_MESSAGE,
    }
}

export const authData = (data) => {
    return {
        type: HANDLE_LOGIN_SUCCESS,
        payload: data
    }
}

export const updateUser = (data) => {
    return {
        type: HANDLE_LOGIN_UPDATE_DATA,
        payload: data
    }
}

export const updatePhoneEmailUser = (data) => {
    return {
        type: HANDLE_LOGIN_UPDATE_PHONE_EMAIL,
        payload: data
    }
}

export const profileImage = (data) => {
    return {
        type: HANDLE_PROFILE_PICTURE,
        payload: data
    }
}

export const postloginbyEmail = (endpoint, body, userId) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        postData(endpoint, body, userId).then(
            response => {
                dispatch(handleloginData(response.data))
                if (response.data.status === 'success'  && response.data.token) {
                    auth(response.data.token) 
                }
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const auth = (body) => {
    return (dispatch) => {
        dispatch(fetchauthRequest())
        authentication(body).then(
            response => {
                dispatch(authData(response.data))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const deletelogin = (body) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        deleteData(`delete_login`, {_id: body._id}).then(
            response => {
                dispatch(handleloginData(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const setpassword = (endpoint, body, userId) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        postData(endpoint, body, userId).then(
            response => {
                dispatch(updateUser(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const updateUserDetails = (body, userId) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        updateData(`update_client_user`, body, userId).then(
            response => {
                dispatch(updateUser(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const updatePhoneEmail = (endpoint, body, userId) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        updateData(endpoint, body, userId).then(
            response => {
                dispatch(updatePhoneEmailUser(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const updatePassword = (endpoint, body, userId) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        postData(endpoint, body, userId).then(
            response => {
                dispatch(updatePhoneEmailUser(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const addProfileImage = (body) => {
    return (dispatch) => {
        // dispatch(fetchloginRequest())
        postData(`add_user_profile_picture`,body).then(
            response => dispatch(profileImage(response?.data?.image_url))
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const deleteProfileImage = (body) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        let public_id = body.split("/")[8].split(".")[0];
        postData(`remove_image`,{ "profile_image": `add_profile_image/${public_id}` }).then(
            response => dispatch(profileImage(''))
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}