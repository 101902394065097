import React, { useEffect } from 'react';
import style from "../authentication.module.css";
import { Link } from 'react-router-dom';
import authentication from '../index';
import RegisterPhoneNumber from './RegisterPhoneNumber';
import Password from './Password';
import Userdetails from "./UserDetails";
import Bussinessname from "./BussinessName";
import { useSelector } from "react-redux";

const Registration = ({ currentStep }) => {

    const state =  useSelector(state => state)

    const [activeStep, setActiveStep] = React.useState(3);

    useEffect(() => {
        setActiveStep(currentStep)
    }, [currentStep])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <RegisterPhoneNumber next={handleNext} />
            case 1:
                return <Password next={handleNext} back={handleBack} />
            case 2:
                return <Userdetails next={handleNext} back={handleBack} />;
            case 3:
                return <Bussinessname next={handleNext} back={handleBack} />
            default:
                return 'Unknown step';
        }
    }

    return (
        <>
            <h4 className='formField'>Create New Account</h4>
            {getStepContent(activeStep)}
            <div className={style.footer}>
                {
                    state?.auth?.message === "The Phone number is register with us !" &&
                    <div className="m_20" style={{ color: state?.auth?.status }}>{state?.auth?.message}</div>
                }
                <Link to="/sign-in">Have an account ? Sign In here</Link>
            </div>
        </>)
};

export default authentication(Registration) 