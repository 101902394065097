import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Snackbar,
  Paper,
  makeStyles,
  CircularProgress,
  colors,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { fetchData } from "../../../Api/api";
import {
  AiOutlineYoutube,
  AiFillMinusSquare,
  AiFillPlusSquare,
} from "react-icons/ai";
import style from "./advanceplan.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetcPlanStatus, getPlan } from "../../../redux";
import { Alert } from "@material-ui/lab";
import PaymentRazorpay from "./PaymentRazorpay";
import sidebar from "../../Sidebar/sidebar";
import protectedContainer from "../../protectedContainer";
import { useHistory } from "react-router-dom";
import { TiDelete } from "react-icons/all";
import Sticky from "react-sticky-el";

const AdvancePlan = () => {
  const [monthly, setmonthly] = useState("monthly");
  const history = useHistory();
  const [addon, _addon] = useState(null);
  const { auth, plan, iconlogo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [button, setbutton] = useState(0);
  const [usergetAddon, setusergetAddon] = useState({
    clientuserid: "",

    country_name: "",

    country_code: "",

    country_currency_symbol: "",

    country_timezones: [],

    country_currency_to_show_in_plan: "",

    inventory_stock_management: {
      plan_type: "",
      count: 0,
      no_of_days: 0,
      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,
      start_date: null,
    },

    recipe_management: {
      plan_type: "",
      count: 0,
      no_of_days: 0,
      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,
      start_date: null,
    },

    purchase_management: {
      plan_type: "",
      count: 0,
      no_of_days: 0,
      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,
      start_date: null,
    },

    vendor_management: {
      plan_type: "",
      count: 0,
      no_of_days: 0,
      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    low_stock_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    self_life_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    whatsapp_notifications_to_customer: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    email_notification_to_customer: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    sms_notification_to_customer: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    push_notification: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    pos_register_billing_user: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    kitchen_order_display: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    payroll: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    zomato: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    swiggy: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    central_kitchen_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * centralized_order_taking =========

    centralized_order_taking: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * create_multiple_brand_tabs =========

    create_multiple_brand_tabs: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * branded_ios_app =========

    branded_ios_app: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * branded_android_app =========

    branded_android_app: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * branded_captain_waiter_ordering_app =========

    branded_captain_waiter_ordering_app: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * branded_delivery_app =========

    branded_delivery_app: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * feedback_management =========

    feedback_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * inbuild_loyalty_program =========

    inbuild_loyalty_program: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    seo_and_analytics: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    staff_waiter_performance_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * excise_software_integration_for_bar_cum_restaurants =========

    excise_software_integration_for_bar_cum_restaurants: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * hotel_banquet_management =========

    hotel_banquet_management: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * cuisine_wise_bluetooth_bill_printing =========

    cuisine_wise_bluetooth_bill_printing: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * dynamic_competitive_price_module =========

    dynamic_competitive_price_module: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },

    // * cloud_telephony =========

    cloud_telephony: {
      plan_type: "",

      count: 0,

      no_of_days: 0,

      mrp: 0,
      discount_price: 0,
      users: 0,
      register: 0,

      start_date: null,
    },
  });

  function getNextItems(item, arr) {
    if (item.heading) {
      const index = arr.indexOf(item);
      const newArr = [];
      for (let i = index + 1; i < arr.length; i++) {
        const element = arr[i];
        newArr.push(element);
      }
      const newArrLength = newArr.findIndex((res) => res.heading);
      const getChildren = [];
      for (let i = index + 1; i < newArrLength; i++) {
        const element = arr[i];
        getChildren.push(element);
      }
      return getChildren;
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      margin: "2rem 0",
      padding: "2rem",
      position: "relative",
    },
    close: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData(`get_all_addon_price`)
      .then((response) => {
        const addondata = response.data.response;
        const addonCountryData = addondata.find(
          (res) => res.country_name === auth?.data?.client_user_country
        )
          ? addondata.find(
              (res) => res.country_name === auth?.data?.client_user_country
            )
          : addondata.find((res) => res?.country_name === "United States");
        _addon({ ...addonCountryData });
      })
      .catch((error) => error);
  }, [auth?.data?.client_user_country, auth?.data?.client_user_id, dispatch]);

  useEffect(() => {
    if (plan.addonPlanData && addon) {
      setusergetAddon({
        ...plan.addonPlanData,
        country_currency_to_show_in_plan:
          addon.country_currency_to_show_in_plan,
      });
      const addonParentArray = Object.keys(plan.addonPlanData).filter(
        (res) => plan.addonPlanData[res]?.count
      );
      plan.addonPlanData[addonParentArray[0]]?.plan_type
        ? setmonthly(plan.addonPlanData[addonParentArray[0]]?.plan_type)
        : setmonthly("monthly");
    }
  }, [plan?.addonPlanData, addon]);

  useEffect(() => {
    if (
      usergetAddon !== null &&
      addon !== null &&
      plan.addonPlanData !== null
    ) {
      const addonsArray = Object.keys(usergetAddon).filter(
        (res) =>
          typeof addon[res] === "object" &&
          addon[res][`${res}_${monthly}`]?.is_active &&
          usergetAddon[res]?.count
      );
      const addonParentArray = Object.keys(plan.addonPlanData).filter(
        (res) =>
          typeof addon[res] === "object" &&
          addon[res][`${res}_${monthly}`]?.is_active &&
          plan.addonPlanData[res]?.count
      );
      const addonsTotal =
        addonsArray.reduce(
          (previousValue, currentValue) =>
            previousValue +
            usergetAddon[currentValue].count *
              addon[currentValue][`${currentValue}_${monthly}`].discount_price,
          0
        ) -
        addonParentArray.reduce(
          (previousValue, currentValue) =>
            previousValue +
            plan.addonPlanData[currentValue].count *
              plan.addonPlanData[currentValue].discount_price,
          0
        );
      setbutton(addonsTotal > 0 ? addonsTotal : 0);
    }
  }, [usergetAddon, addon, monthly, plan.addonPlanData]);

  const planTypeChange = (plan_type) => {
    setmonthly(plan_type);
    const newPlan = Object.keys(usergetAddon)
      .filter((res) => usergetAddon[res]?.count)
      .reduce(
        (a, v) => ({
          ...a,
          [v]: {
            discount_price:
              addon[v][`${v}_${plan_type.toLowerCase()}`]["discount_price"],
            mrp: addon[v][`${v}_${plan_type.toLowerCase()}`].mrp,
            count: usergetAddon[v].count,
            plan_type: plan_type,
            users: addon[v][`${v}_${plan_type.toLowerCase()}`].users,
            register: addon[v][`${v}_${plan_type.toLowerCase()}`].register,
            no_of_days:
              plan_type === "monthly"
                ? 30
                : plan_type === "quarterly"
                ? 90
                : 365,
          },
        }),
        {}
      );
    setusergetAddon({
      ...usergetAddon,
      ...newPlan,
    });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.close}>
        <IconButton onClick={() => history.push("/profile")}>
          <TiDelete />
        </IconButton>
      </div>
      <div className={`${style.pricing_2}`}>
        <Grid container>
          {/* <Sticky> */}
          <div style={{ width: "100%" }}>
            <Grid container>
              <Grid
                style={{ display: "flex", justifyContent: "center" }}
                item
                xs={12}
              >
                <div className={style.block}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                  >
                    Add-on more special fetures with your Free plan
                  </Typography>
                </div>
              </Grid>
              <Divider style={{ width: "100%" }} />
              <Grid
                item
                xs={4}
                style={{
                  padding: ".5rem 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.grey[400],
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  Features
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  padding: ".5rem 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.grey[400],
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  Select plan Durations
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  style={{ width: 150, marginLeft: 10 }}
                >
                  {/* <InputLabel id="demo-simple-select-label">Select</InputLabel> */}
                  <Select
                    variant="outlined"
                    // label="Select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthly}
                    onChange={(e) => setmonthly(e.target.value)}
                  >
                    <MenuItem value="monthly">1 months</MenuItem>
                    <MenuItem value="quarterly">3 months</MenuItem>
                    <MenuItem value="yearly">1 Year</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  padding: ".5rem 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.grey[400],
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  Total:
                </Typography>
                <PaymentRazorpay
                  createPayment={usergetAddon}
                  userDetails={auth.data}
                  image={iconlogo.data?.icon}
                  price={button}
                  paymenthandler={(data) => {
                    dispatch(fetcPlanStatus(data));
                    dispatch(getPlan(auth?.data?.client_user_id));
                  }}
                />
              </Grid>
            </Grid>
          </div>
          {/* </Sticky> */}
          <Divider style={{ width: "100%" }} />
          {/* <Grid item xs={5}>
                        <div className={style.block}>
                            <Button variant='contained' type="button" className={monthly === "monthly" && 'button'}
                                style={{ backgroundColor: monthly === "monthly" && "#198754" }}
                                onClick={() => planTypeChange("monthly")}>1 months</Button>
                            <Button variant='contained' type="button" className={monthly === "quarterly" && 'button'}
                                style={{ backgroundColor: monthly === "quarterly" && "#198754" }}
                                onClick={() => planTypeChange("quarterly")}>3 months</Button>
                            <Button variant='contained' type="button" className={monthly === "yearly" && 'button'}
                                style={{ backgroundColor: monthly === "yearly" && "#198754" }}
                                onClick={() => planTypeChange("yearly")}>1 Year</Button>

                        </div>
                    </Grid> */}
          {/* <Grid item xs={3}></Grid> */}
        </Grid>
        {/* <p>Add-on more special fetures with your Free plan</p>
                    <small>Choose your plan</small>
                    <Button variant='contained' type="button" className={monthly === "monthly" && 'button'}
                        style={{ backgroundColor: monthly === "monthly" && "#198754" }}
                        onClick={() => setmonthly("monthly")}>1 months</Button>
                    <Button variant='contained' type="button" className={monthly === "quarterly" && 'button'}
                        style={{ backgroundColor: monthly === "quarterly" && "#198754" }}
                        onClick={() => setmonthly("quarterly")}>3 months</Button>
                    <Button variant='contained' type="button" className={monthly === "yearly" && 'button'}
                        style={{ backgroundColor: monthly === "yearly" && "#198754" }}
                        onClick={() => setmonthly("yearly")}>1 Year</Button> */}
      </div>
      {addon ? (
        [
          {
            text: "Online Marketplace Aggregations",
            heading: true,
            content: "",
          },
          { text: "Zomato", content: "User" },
          { text: "Swiggy", content: "User" },
          { text: "Pos register billing user", content: "User" },
          { text: "Add-Ons More Special Feature", heading: true, content: "" },
          { text: "Central Kitchen Management", content: "Yes" },
          { text: "Centralized Order Taking", content: "No" },
          { text: "Create multiple brand tabs", content: "Yes" },
          { text: "Branded IOS App", content: "No" },
          { text: "Branded Android App", content: "Yes" },
          { text: "Branded Captain/Waiter ordering App", content: "Yes" },
          { text: "Branded Delivery app", content: "No" },
          { text: "Feedback Management", content: "Yes" },
          { text: "Inbuild Loyalty Program", content: "No" },
          { text: "SEO & Analytics", content: "Yes" },
          { text: "Staff/Waiter Performance Management", content: "Yes" },
          {
            text: "Excise Software Integration for Bar Cum Restaurants",
            content: "No",
          },
          { text: "Hotel Banquet Management", content: "Yes" },
          { text: "Cuisine wise Bluetooth Bill Printing", content: "No" },
          { text: "Dynamic competitive price module", content: "Yes" },
          { text: "Cloud telephony", content: "Yes" },
          { text: "Inventory Management", heading: true, content: "" },
          { text: "Inventory Stock Management", content: "User" },
          { text: "Recipe Management", content: "User" },
          { text: "Purchase Management", content: "User" },
          { text: "Vendor Management", content: "User" },
          { text: "Low stock Management", content: "User" },
          { text: "Self Life Management", content: "User" },
          { text: "Notifications", heading: true, content: "" },
          { text: "WhatsApp Notifications to customer", content: "User" },
          { text: "Email Notification to customer", content: "User" },
          { text: "SMS Notification to customer", content: "User" },
          { text: "Push notification", content: "User" },
          { text: "Payroll", content: "User" },
          { text: "Kitchen Order Display", content: "User" },
          // eslint-disable-next-line array-callback-return
        ].map((res, index, elements) => {
          const fieldValue = res.text
            .toLowerCase()
            .replace(/ /g, "_")
            .replace("/", "_")
            .replace(/&/g, "and");
          if (res.heading) {
            if (
              getNextItems(res, elements).some(
                (active) =>
                  addon[
                    active.text
                      .toLowerCase()
                      .replace(/ /g, "_")
                      .replace("/", "_")
                      .replace(/&/g, "and")
                  ][
                    `${active.text
                      .toLowerCase()
                      .replace(/ /g, "_")
                      .replace("/", "_")
                      .replace(/&/g, "and")}_${monthly}`
                  ].is_active
              )
            ) {
              return (
                <Grid container key={index}>
                  <Grid
                    item
                    xs={4}
                    className={` ${style.left_pannel}`}
                    style={{
                      paddingLeft: res.heading
                        ? "1rem"
                        : addon[fieldValue][`video_link`]
                        ? "3rem"
                        : "5.8rem",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                      backgroundColor: colors.grey[300],
                    }}
                  >
                    {!res.heading && addon[fieldValue][`video_link`] && (
                      <a
                        href={`${addon[fieldValue][`video_link`]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className={style.youtube_link}>
                          <AiOutlineYoutube />
                        </span>
                      </a>
                    )}
                    <Typography variant="h6" style={{ color: "red" }}>
                      {res.text}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    className={`text-center py-2 
                                                                   `}
                    style={{ backgroundColor: colors.grey[300] }}
                  >
                    {!res.heading && (
                      <div className={style.addon_price}>
                        <h5
                          className={`${style.only_price} ${style.discounted_price}`}
                        >
                          {String.fromCharCode(
                            addon?.country_currency_to_show_in_plan
                          )}
                          {
                            addon[fieldValue][
                              `${fieldValue}_${monthly.toLowerCase()}`
                            ]["mrp"]
                          }
                        </h5>
                        <h5 className={style.only_price}>
                          {String.fromCharCode(
                            addon?.country_currency_to_show_in_plan
                          )}
                          {
                            addon[fieldValue][
                              `${fieldValue}_${monthly.toLowerCase()}`
                            ]["discount_price"]
                          }
                          <span>
                            /
                            <span className={style.text_dark}>
                              {
                                addon[fieldValue][
                                  `${fieldValue}_${monthly.toLowerCase()}`
                                ]["users"]
                              }
                            </span>{" "}
                            Users
                          </span>
                          <span>
                            /
                            <span className={style.text_dark}>
                              {
                                addon[fieldValue][
                                  `${fieldValue}_${monthly.toLowerCase()}`
                                ]["register"]
                              }
                            </span>{" "}
                            Register
                          </span>
                        </h5>
                      </div>
                    )}
                  </Grid>
                  {!res.heading && (
                    <Grid item xs={3}>
                      <div className={style.padding_1rem}>
                        {usergetAddon[fieldValue].count ? (
                          <div
                            className={style.add_to_cart}
                            type="button"
                            // style={{ backgroundColor: "green" }}
                          >
                            <span>
                              <IconButton
                                size="small"
                                className="button"
                                onClick={() =>
                                  setusergetAddon({
                                    ...usergetAddon,
                                    [fieldValue]: {
                                      ...usergetAddon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ],
                                      discount_price:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ]["discount_price"],
                                      mrp: addon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ].mrp,
                                      count: usergetAddon[fieldValue].count + 1,
                                      plan_type: monthly,
                                      users:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].users,
                                      register:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].register,
                                      no_of_days:
                                        monthly === "monthly"
                                          ? 30
                                          : monthly === "quarterly"
                                          ? 90
                                          : 365,
                                    },
                                  })
                                }
                              >
                                <AiFillPlusSquare />
                              </IconButton>
                              <span className="mx-2">
                                {usergetAddon[fieldValue].count}
                              </span>
                              <IconButton
                                size="small"
                                className="button"
                                onClick={() =>
                                  setusergetAddon({
                                    ...usergetAddon,
                                    [fieldValue]: {
                                      ...usergetAddon[fieldValue],
                                      discount_price:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ]["discount_price"],
                                      mrp: addon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ].mrp,
                                      count: usergetAddon[fieldValue].count - 1,
                                      plan_type: monthly,
                                      users:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].users,
                                      register:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].register,
                                      no_of_days:
                                        monthly === "monthly"
                                          ? 30
                                          : monthly === "quarterly"
                                          ? 90
                                          : 365,
                                    },
                                  })
                                }
                              >
                                <AiFillMinusSquare />
                              </IconButton>
                            </span>
                            <span>added</span>
                          </div>
                        ) : (
                          <Button
                            variant="contained"
                            type="button"
                            onClick={() =>
                              setusergetAddon({
                                ...usergetAddon,
                                [fieldValue]: {
                                  ...usergetAddon[fieldValue],
                                  count: 1,
                                  discount_price:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ]["discount_price"],
                                  mrp: addon[fieldValue][
                                    `${fieldValue}_${monthly.toLowerCase()}`
                                  ].mrp,
                                  plan_type: monthly,
                                  users:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ].users,
                                  register:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ].register,
                                  no_of_days:
                                    monthly === "monthly"
                                      ? 30
                                      : monthly === "quarterly"
                                      ? 90
                                      : 365,
                                },
                              })
                            }
                          >
                            Add to Cart
                          </Button>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              );
            }
          } else {
            if (
              addon[fieldValue][`${fieldValue}_${monthly.toLowerCase()}`]
                ?.is_active
            ) {
              return (
                <Grid container key={index}>
                  <Grid
                    item
                    xs={4}
                    className={` ${style.left_pannel}`}
                    style={{
                      paddingLeft: res.heading
                        ? "1rem"
                        : addon[fieldValue][`video_link`]
                        ? "3rem"
                        : "5.8rem",
                    }}
                  >
                    {!res.heading && addon[fieldValue][`video_link`] && (
                      <a
                        href={`${addon[fieldValue][`video_link`]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span
                          style={{ alignItems: "center", display: "flex" }}
                          className={style.youtube_link}
                        >
                          <AiOutlineYoutube />
                        </span>
                      </a>
                    )}
                    <Typography variant="h6">{res.text}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    className={`text-center  py-2
                                                                           `}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!res.heading && (
                      <div className={style.addon_price}>
                        <h5
                          className={`${style.only_price} ${style.discounted_price}`}
                        >
                          {String.fromCharCode(
                            addon?.country_currency_to_show_in_plan
                          )}
                          {
                            addon[fieldValue][
                              `${fieldValue}_${monthly.toLowerCase()}`
                            ]["mrp"]
                          }
                        </h5>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1rem",
                            marginLeft: 10,
                          }}
                        >
                          {String.fromCharCode(
                            addon?.country_currency_to_show_in_plan
                          )}
                          {
                            addon[fieldValue][
                              `${fieldValue}_${monthly.toLowerCase()}`
                            ]["discount_price"]
                          }
                          <span>
                            /
                            <span className={style.text_dark}>
                              {
                                addon[fieldValue][
                                  `${fieldValue}_${monthly.toLowerCase()}`
                                ]["users"]
                              }
                            </span>{" "}
                            Users
                          </span>
                          <span>
                            /
                            <span className={style.text_dark}>
                              {
                                addon[fieldValue][
                                  `${fieldValue}_${monthly.toLowerCase()}`
                                ]["register"]
                              }
                            </span>{" "}
                            Register
                          </span>
                        </h5>
                      </div>
                    )}
                  </Grid>
                  {!res.heading && (
                    <Grid item xs={3}>
                      <div className={style.padding_1rem}>
                        {usergetAddon[fieldValue].count ? (
                          <div
                            className={style.add_to_cart}
                            type="button"
                            style={{
                              color: "#000000",
                              backgroundColor: colors.grey[300],
                              cursor: "pointer",
                              border: "1px solid red",
                            }}
                          >
                            <span>
                              <IconButton
                                size="small"
                                className="button"
                                onClick={() =>
                                  setusergetAddon({
                                    ...usergetAddon,
                                    [fieldValue]: {
                                      ...usergetAddon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ],
                                      discount_price:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ]["discount_price"],
                                      mrp: addon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ].mrp,
                                      count: usergetAddon[fieldValue].count + 1,
                                      plan_type: monthly,
                                      users:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].users,
                                      register:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].register,
                                      no_of_days:
                                        monthly === "monthly"
                                          ? 30
                                          : monthly === "quarterly"
                                          ? 90
                                          : 365,
                                    },
                                  })
                                }
                              >
                                <AiFillPlusSquare
                                  style={{ color: "#000000" }}
                                />
                              </IconButton>
                              <span className="mx-2">
                                {usergetAddon[fieldValue].count}
                              </span>
                              <IconButton
                                size="small"
                                className="button"
                                onClick={() =>
                                  setusergetAddon({
                                    ...usergetAddon,
                                    [fieldValue]: {
                                      ...usergetAddon[fieldValue],
                                      discount_price:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ]["discount_price"],
                                      mrp: addon[fieldValue][
                                        `${fieldValue}_${monthly.toLowerCase()}`
                                      ].mrp,
                                      count: usergetAddon[fieldValue].count - 1,
                                      plan_type: monthly,
                                      users:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].users,
                                      register:
                                        addon[fieldValue][
                                          `${fieldValue}_${monthly.toLowerCase()}`
                                        ].register,
                                      no_of_days:
                                        monthly === "monthly"
                                          ? 30
                                          : monthly === "quarterly"
                                          ? 90
                                          : 365,
                                    },
                                  })
                                }
                              >
                                <AiFillMinusSquare
                                  style={{ color: "#000000" }}
                                  s
                                />
                              </IconButton>
                            </span>
                            <span>added</span>
                          </div>
                        ) : (
                          <Button
                            variant="contained"
                            type="button"
                            style={{ color: "#000000" }}
                            onClick={() =>
                              setusergetAddon({
                                ...usergetAddon,
                                [fieldValue]: {
                                  ...usergetAddon[fieldValue],
                                  count: 1,
                                  discount_price:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ]["discount_price"],
                                  mrp: addon[fieldValue][
                                    `${fieldValue}_${monthly.toLowerCase()}`
                                  ].mrp,
                                  plan_type: monthly,
                                  users:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ].users,
                                  register:
                                    addon[fieldValue][
                                      `${fieldValue}_${monthly.toLowerCase()}`
                                    ].register,
                                  no_of_days:
                                    monthly === "monthly"
                                      ? 30
                                      : monthly === "quarterly"
                                      ? 90
                                      : 365,
                                },
                              })
                            }
                            startIcon={<ShoppingCart />}
                          >
                            Add to Cart
                          </Button>
                        )}
                      </div>
                    </Grid>
                  )}
                  <Divider style={{ width: "100%" }} />
                </Grid>
              );
            }
          }
        })
      ) : (
        <CircularProgress />
      )}
      <div className="d_flex justify_content_between mt_11">
        <Button onClick={() => history.push("/profile")} variant="contained">
          Back
        </Button>
      </div>
      <Snackbar
        open={plan.status}
        autoHideDuration={2000}
        onClose={() => dispatch(fetcPlanStatus({ status: "", message: "" }))}
      >
        <Alert severity={plan.status}>{plan.message}</Alert>
      </Snackbar>
    </Paper>
  );
};

export default sidebar(protectedContainer(AdvancePlan));
