import "firebase/auth";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBR0FEpRNpAchjvq1XrbKkiRqepfELzk90",
  authDomain: "robofood-ba721.firebaseapp.com",
  projectId: "robofood-ba721",
  storageBucket: "robofood-ba721.appspot.com",
  messagingSenderId: "266785178383",
  appId: "1:266785178383:web:ad226be0a864f1b7f0b47a",
  measurementId: "G-GQ94SL6XR5"
};

export const app = initializeApp(firebaseConfig);
