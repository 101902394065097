import {
    FETCH_ITEM_FALIOUR, FETCH_ITEM_REQUEST, FETCH_ITEM_SUCCESS, ITEM_DATA, ITEM_IMAGE, IMAGE_DELETE, ITEM_MESSAGE, ITEM_LOADER,
    FETCH_ITEM_ADDONS_SUCCESS, ADDONS_WALA_ITEM, HANDLE_STATUS_MESSAGE
} from "./itemTypes";

const initialState = {
    loading: false,
    loader: false,
    data: [],
    addons: [],
    totalPage: 0,
    currentPage: 0,
    totalPageforAddons: 0,
    currentPageforAddons: 0,
    status: '',
    message: '',
    image_link: '',
    editItem: null,
}

export const ItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ITEM_REQUEST:
            return {
                ...state, loading: true,
            }
        case ITEM_LOADER:
            return {
                ...state, loader: true,
            }
        case FETCH_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                status: '',
                message: '',
                image_link: '',
                editItem: null,
            }

        case FETCH_ITEM_ADDONS_SUCCESS:
            return {
                ...state,
                loader: false,
                addons: action.payload.response,
                totalPageforAddons: action.payload.totalPages,
                currentPageforAddons: action.payload.currentPage,
                status: '',
                message: '',
                image_link: ''
            }
        case FETCH_ITEM_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case ITEM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case ITEM_IMAGE:
            return {
                ...state,
                loading: false,
                loader: false,
                image_link: action.payload.image_url
            }

        case IMAGE_DELETE:
            return {
                ...state,
                loading: false,
                loader: false,
                image_link: ''
            }
        case ITEM_MESSAGE:
            return {
                ...state,
                loading: false,
                loader: false,
                status: action.payload.status,
                message: action.payload.message
            }
        case ADDONS_WALA_ITEM:
            return {
                ...state,
                loader: false,
                loading: false,
                addons: action.payload.response,
                status: '',
                message: ''
            }
        case HANDLE_STATUS_MESSAGE:
            return {
                ...state,
                loader: false,
                loading: false,
                status: '',
                message: ''
            }
        default:
            return state
    }
}