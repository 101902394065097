import { FETCH_GALARY_NAME_FALIUR, FETCH_GALARY_NAME_REQUEST, FETCH_GALARY_NAME_SUCCESS, FETCH_LIBRAY_IMAGE_FALIUR, FETCH_LIBRAY_IMAGE_REQUEST, FETCH_LIBRAY_IMAGE_SUCCESS } from "./galary.type";

const initialState = {
    loading: false,
    loader: false,
    data: [],
    image: [],
    status: "",
    message: ""
}

export const galaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GALARY_NAME_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GALARY_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                status: "",
                message: ""
            }
        case FETCH_GALARY_NAME_FALIUR:
            return {
                ...state,
                loading: false,
                status: "error",
                message: "Network Error"
            }
        case FETCH_LIBRAY_IMAGE_REQUEST:
            return {
                ...state,
                loader: true
            }
        case FETCH_LIBRAY_IMAGE_SUCCESS:
            return {
                ...state,
                loader: false,
                image: action.payload,
                status: "",
                message: ""
            }
        case FETCH_LIBRAY_IMAGE_FALIUR:
            return {
                ...state,
                loader: false,
                status: "error",
                message: "Network Error"
            }

        default:
            return state
    }
}