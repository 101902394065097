export const FETCH_ITEM_REQUEST = 'FETCH_ITEM_REQUEST'
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS'
export const FETCH_ITEM_ADDONS_SUCCESS = 'FETCH_ITEM_ADDONS_SUCCESS'
export const FETCH_ITEM_FALIOUR = 'FETCH_ITEM_FALIOUR'
export const ITEM_DATA = 'ITEM_DATA'
export const ADDONS_WALA_ITEM = 'ADDONS_WALA_ITEM'
export const ITEM_LOADER = 'ITEM_LOADER'
export const ITEM_MESSAGE = 'ITEM_MESSAGE'
export const ITEM_IMAGE = 'ITEM_IMAGE'
export const IMAGE_DELETE = 'IMAGE_DELETE'
export const HANDLE_STATUS_MESSAGE = 'HANDLE_STATUS_MESSAGE'