import { deleteData, fetchData, postData, updateData } from "../../Api/api";
import { FETCH_TAX_DATA, FETCH_TAX_REQUEST, FETCH_TAX_SUCCESS, FETCH_TAX_ERROR, TAX_STATUS_MESSAGE} from "./taxTypes";

export const fetchTaxRequest = () => {
    return {
        type: FETCH_TAX_REQUEST
    }
}

export const fetchRTaxSuccess = (data) => {
    return {
        type: FETCH_TAX_SUCCESS,
        payload: data
    }
}

export const fetchTaxError = () => {
    return {
        type: FETCH_TAX_ERROR
    }
}

export const fetchTaxData = (data) => {
    return {
        type: FETCH_TAX_DATA,
        payload: data
    }
}

export const handleTaxStatus = () => {
    return {
        type: TAX_STATUS_MESSAGE,
    }
}

export const getAllTaxes = (endpoint, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchTaxRequest())
        fetchData(endpoint, clientUserId).then(
            result => dispatch(fetchRTaxSuccess(result.data)) 
        ).catch(
            error => dispatch(fetchTaxError())
        )
    }
}

export const postTax = (endpoint, body, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchTaxRequest())
        postData(endpoint, body, clientUserId).then(
            result => dispatch(fetchTaxData(result.data))
        ).catch(
            error => dispatch(fetchTaxError())
        )
    }
}

export const updateTax = (endpoint, body, clientUserId) => {
    return (dispatch) => {
        updateData(endpoint, body, clientUserId).then(
            result => dispatch(fetchTaxData(result.data))
        ).catch(
            error => dispatch(fetchTaxError())
        )
    }
}

export const deleteTax = (endpoint, body, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchTaxRequest())
        deleteData(endpoint, body, clientUserId).then(
            result => dispatch(fetchTaxData(result.data))
        ).catch(
            error => dispatch(fetchTaxError())
        )
    }
}