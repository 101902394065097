import React from "react";
import { makeStyles, InputBase, fade } from "@material-ui/core";
import { IoIosSearch } from "react-icons/all";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    width: 250,

    "& input": {
      border: "initial",
      background: "transparent",
      outline: "initial",
      padding: "0.5rem 1rem",
      lineHeight: 1.75,
      fontSize: "0.8rem",
      width: "93%",
      color: theme.palette.primary.main,
    },
    "& .search-icon": {
      fontSize: 17,
      borderRadious: 10,
      color: theme.palette.secondary.main,
      position: "absolute",
      width: 40,
      height: 40,
      bottom: "-10px",
      right: 3,
      textAlign: "center",
      cursor: "pointer",

      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  inputBase: {
    background: fade(theme.palette.common.black, 0.1),
    borderRadius: 30,
    width: "100%",
    padding: ".2rem",
  },
}));

export const SearchInput = ({ value, onChange, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.search} data-search-path="/app/pages/search">
      <InputBase
        name="searchKeyword"
        placeholder="Search..."
        className={classes.inputBase}
        value={value}
        onChange={onChange}
        // onKeyPress={(e) => this.handleSearchInputKeyPress(e)}
      />
      <span className="search-icon" onClick={onClick}>
        <IoIosSearch />
      </span>
    </div>
  );
};
