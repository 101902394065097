import {
    FETCH_PLAN_REQUEST, FETCH_PLAN_SUCCESS, FETCH_PLAN_DATA, FETCH_PLAN_FALIOUR, HANDLE_PLAN_STATUS,
    FETCH_BASIC_PLAN_SUCCESS
} from "./plan.types";

const initialState = {
    loader: false,
    basicPlanData: null,
    addonPlanData: null,
    status: '',
    message: ''
}

export const planReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PLAN_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_PLAN_SUCCESS:
            return {
                ...state,
                loader: false,
                addonPlanData: action.payload,
                status: '',
                message: ''
            }
        case FETCH_BASIC_PLAN_SUCCESS:
            return {
                ...state,
                loader: false,
                basicPlanData: action.payload,
                status: '',
                message: ''
            }
        case FETCH_PLAN_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_PLAN_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_PLAN_STATUS:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}