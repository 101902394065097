import {FETCH_SOCIAL_MEDIA_REQUEST, FETCH_SOCIAL_MEDIA_SUCCESS, FETCH_SOCIAL_MEDIA_FALIOUR, FETCH_SOCIAL_MEDIA_DATA} from './socialmediaTypes'

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const socialmediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SOCIAL_MEDIA_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_SOCIAL_MEDIA_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_SOCIAL_MEDIA_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}