import React from "react";
import { Route, Redirect } from "react-router-dom";
// import auth from '../redux'
import {
  useSelector,
  // useDispatch
} from "react-redux";
import { CircularProgress } from "@material-ui/core";

export default function PrivateRoute({
  component: Component,
  condition,
  ...rest
}) {
  const state = useSelector((state) => state);
  const inventoryStockManagment =
    state?.plan?.addonPlanData?.inventory_stock_management;
  return (
    <Route
      {...rest}
      render={
        (props) =>
          state?.auth?.loader ? (
            <div className="loaderBg">
              <CircularProgress
                color={
                  state?.auth?.data?.client_user_color_theme === "dark"
                    ? "#ffffff"
                    : "gray"
                }
              />
            </div>
          ) : state?.branch?.loader ? (
            <div className="loaderBg">
              <CircularProgress
                color={
                  state?.auth?.data?.client_user_color_theme === "dark"
                    ? "#ffffff"
                    : "gray"
                }
              />
            </div>
          ) : state?.branch?.activeBranch?.length > 0 ? (
            <Component {...props} />
          ) : condition ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/store-basics/create-location",
              }}
            />
          )
        // state?.auth?.data?.client_user_subdomain ? (
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: "/sign-in",
        //       state: { from: props.location },
        //     }}
        //   />
        // )
      }
    />
  );
}
