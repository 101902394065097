import { FETCH_TAX_DATA, FETCH_TAX_REQUEST, FETCH_TAX_SUCCESS, FETCH_TAX_ERROR, TAX_STATUS_MESSAGE } from "./taxTypes";

const initialState = {
    loading: false,
    data: [],
    status: "",
    message: "",
    totalpage: 0,
    currentPage: 0
}

export const taxReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TAX_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_TAX_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                status: "",
                message: "",
                totalpage: action.payload.totalPages,
                currentPage: action.payload.currentPage
            }
        case FETCH_TAX_ERROR:
            return {
                ...state,
                loading: false,
                status: "error",
                message: "Network Error"
            }
        case FETCH_TAX_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === "success" ? action.payload.status : "error",
                message: action.payload.message
            }
        case TAX_STATUS_MESSAGE:
            return {
                ...state,
                loading: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}