import {FETCH_SOCIAL_MEDIA_REQUEST, FETCH_SOCIAL_MEDIA_SUCCESS, FETCH_SOCIAL_MEDIA_FALIOUR, FETCH_SOCIAL_MEDIA_DATA} from './socialmediaTypes'
import {fetchData,updateData} from '../../Api/api'

export const fetchSocialMediaAccess = () => {
    return {
        type: FETCH_SOCIAL_MEDIA_REQUEST
    }
}

export const fetchSocialMediaSuccess = (data) => {
    return {
        type: FETCH_SOCIAL_MEDIA_SUCCESS,
        payload: data
    }
}

export const fetchSocialMediaFaliour = (data) => {
    return {
        type: FETCH_SOCIAL_MEDIA_FALIOUR,
        payload : data
    }
}

export const fetchSocialMediaFormData = (data) => {
    return {
        type: FETCH_SOCIAL_MEDIA_DATA,
        payload: data
    }
}

export const getSocialMedia = (user_id) => {
    return (dispatch) => {
        dispatch(fetchSocialMediaAccess())
        fetchData(`get_social_media_urls`, user_id).then(
            response => dispatch(fetchSocialMediaSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchSocialMediaFaliour(error.message))
        )
    }
}


export const updateSocialMedia = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchSocialMediaAccess())
        updateData(`update_or_add_social_media_page_urls`,body, user_id).then(
            response => {
                dispatch(fetchSocialMediaFormData(response.data))
                response.data.status === 'success' && dispatch(getSocialMedia(user_id))
            }
        ).catch(
            error => dispatch(fetchSocialMediaFaliour(error.message))
        )
    }
}
