import {PUSH_NOTIFICATION_DATA, PUSH_NOTIFICATION_IMAGE,FETCH_PUSH_NOTIFICATION_BY_ID_SUCCESS,
    FETCH_PUSH_NOTIFICATION_FALIOUR,FETCH_PUSH_NOTIFICATION_REQUEST,FETCH_PUSH_NOTIFICATION_SUCCESS } from "./pushNotificationTypes";

const initialState = {
    loading: false,
    totalPage: 0,
    currentPage: 0,
    data: [],
    status: '',
    message: '',
    image_link: ''
}

export const pushNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PUSH_NOTIFICATION_REQUEST:
            return {
                ...state, loading: true,
            }
        case FETCH_PUSH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                data: action.payload.response,
                status: '',
                message: '',
                image_link: ''
            }
        case FETCH_PUSH_NOTIFICATION_BY_ID_SUCCESS :
            return {
                 ...state, data: action.payload
            }
        
        case FETCH_PUSH_NOTIFICATION_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case PUSH_NOTIFICATION_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case PUSH_NOTIFICATION_IMAGE: 
            return {
                ...state,
                image_link : action.payload
            }

        default:
            return state
    }
}