import React from "react";

import {
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  makeStyles,
  Switch,
  FormControlLabel,
  FormGroup,
  withStyles,
  Tooltip,
  Box,
  Avatar,
  Menu,
} from "@material-ui/core";
import { More } from "@material-ui/icons";
import {
  IoIosMenu,
  IoNotificationsOutline,
  TiThLargeOutline,
  IoIosExpand,
  ImShrink,
} from "react-icons/all";
import { SearchInput } from "../Inputs/searchInput";
import { CustomBadge } from "../CustomBadge/customBadge";
import { Menus } from "../Menus/Menus";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import authclass from "../Auth";
import { auth } from "../../redux";
import { updateUserDetails, updateBranch } from "../../redux";

// import logo from "../../assets/logo/logo.png";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      width: 100,
      objectFit: "contain",
    },
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  iconStyle: {
    fontSize: "1.5rem",
    color: theme.palette.primary.contrastText,
  },
  toolbar: theme.mixins.toolbar,
  bg: {
    backgroundColor: "#e6e5e3",
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: ".5rem 0",
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const Header = ({
  openDrawer,
  setOpenDrawer,
  openSubDrawer,
  setOpenSubDrawer,
  handleMobileMenuOpen,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [fullScreen, setfullScreen] = React.useState(false);
  const [openProfileMenu, setOpenProfileMenu] = React.useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  // const anchorRef = React.useRef(null);
  const [anchorRef, setanchorRef] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logout = () => {
    authclass.logout();
    dispatch(auth(localStorage.getItem("user-token")));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.grey[500],
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.common.white,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const onFullScreen = () => {
    document.documentElement.requestFullscreen().catch((e) => console.log(e));
    setfullScreen(true);
  };

  const closeFullScreen = () => {
    document.exitFullscreen();
    setfullScreen(false);
  };

  return (
    // {/* <AppBar position="fixed" className={classes.appbar}> */}
    <Toolbar>
      <Box className={classes.box}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            if (openSubDrawer) {
              setOpenSubDrawer(false);
            } else {
              setOpenDrawer(!openDrawer);
            }
          }}
        >
          <IoIosMenu className={classes.iconStyle} />
        </IconButton>
        {state?.iconlogo?.data?.icon && (
          <div className={classes.menuButton}>
            <img
              src={state?.iconlogo?.data?.icon}
              width="60px"
              height="60px"
              alt="icon"
            />
          </div>
        )}
        {state?.iconlogo?.data?.logo ? (
          <div>
            <img src={state?.iconlogo?.data?.logo} height="45px" alt="logo" />
          </div>
        ) : (
          <Typography variant="h2">
            {state?.branch?.activeBranch[0]?.branch_name}
          </Typography>
        )}
        {/* <SearchInput /> */}
      </Box>
      <div className={classes.grow} />
      {/* <img alt="logo" src={logo} className={classes.title} /> */}
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <FormGroup row>
          <Tooltip title="Black/White" placement="left">
            <FormControlLabel
              control={
                <AntSwitch
                  checked={
                    state?.branch?.activeBranch[0]?.branch_color_theme ===
                    "dark"
                    // state?.auth?.data?.client_user_color_theme === "dark"
                  }
                  onChange={(e) =>
                    dispatch(
                      updateBranch(
                        {
                          _id: state?.branch?.activeBranch[0]?._id,
                          branch_color_theme:
                            state?.branch?.activeBranch[0]
                              ?.branch_color_theme === "dark"
                              ? "light"
                              : "dark",
                        },
                        state?.auth?.data?._id
                      )
                      // updateUserDetails({
                      //   user_id: state?.auth?.data?._id,
                      //   client_user_color_theme: e.target.checked
                      //     ? "dark"
                      //     : "light",
                      // }, state?.auth?.data?._id)
                    )
                  }
                  name="checkedA"
                />
              }
            />
          </Tooltip>
        </FormGroup>
        <IconButton aria-label="show 17 new notifications" color="inherit">
          <TiThLargeOutline className={classes.iconStyle} />
        </IconButton>
        <IconButton aria-label="show 17 new notifications" color="inherit">
          <CustomBadge badgeContent={17} color="secondary">
            <IoNotificationsOutline className={classes.iconStyle} />
          </CustomBadge>
        </IconButton>
        {fullScreen ? (
          <IconButton
            color="inherit"
            onClick={() => {
              closeFullScreen();
            }}
          >
            <ImShrink className={classes.iconStyle} />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            onClick={() => {
              onFullScreen();
            }}
          >
            <IoIosExpand className={classes.iconStyle} />
          </IconButton>
        )}
        {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
        <Typography variant="body2" style={{ marginRight: "1rem" }}>
          {state?.auth?.data?.client_user_first_name
            ? state?.auth?.data?.client_user_first_name
            : "User"}
        </Typography>
        <Avatar
          innerRef={anchorRef}
          onClick={(e) => {
            setOpenProfileMenu(true);
            setanchorRef(e.currentTarget);
          }}
          alt="user"
          src={state?.auth?.data?.client_user_profile_image}
        />
        <Menus
          open={openProfileMenu}
          anchorRef={anchorRef}
          handleClose={() => setOpenProfileMenu(false)}
        >
          <MenuItem onClick={() => history.push(`/profile`)}>
            Account Settings
          </MenuItem>
          <MenuItem onClick={() => history.push(`/change-password`)}>
            Change Password
          </MenuItem>
          <MenuItem onClick={() => logout()}>Log Out</MenuItem>
        </Menus>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <More />
        </IconButton>
      </div>
    </Toolbar>
    // {/* </AppBar> */}
    // {/* <div style={{ height: 90 }} /> */}
    // {/* <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} /> */}
  );
};
