import { FETCH_ADDONS_REQUEST, FETCH_ADDONS_SUCCESS, FETCH_ADDONS_FALIOUR, FETCH_ADDONS_DATA } from "./addonsTypes";

const initialState = {
    loading: false,
    data: [],
    status: '',
    message: '',
    currentPage: 0,
    totalPages: 0
}

export const addonsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADDONS_REQUEST:
            return {
                ...state, loading: true
            }

        case FETCH_ADDONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                status: '',
                message: ''
            }

        case FETCH_ADDONS_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_ADDONS_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}