import { FETCH_ADDONS_REQUEST, FETCH_ADDONS_SUCCESS, FETCH_ADDONS_FALIOUR, FETCH_ADDONS_DATA } from "./addonsTypes";
import {fetchData,updateData,deleteData,postData} from '../../Api/api'

export const fetchAddonPageAccess = () => {
    return {
        type: FETCH_ADDONS_REQUEST
    }
}

export const fetchAddonPageSuccess = (data) => {
    return {
        type: FETCH_ADDONS_SUCCESS,
        payload: data
    }
}

export const fetchAddonPageFaliour = (data) => {
    return {
        type: FETCH_ADDONS_FALIOUR,
        payload : data
    }
}

export const fetchAddonPageFormData = (data) => {
    return {
        type: FETCH_ADDONS_DATA,
        payload: data
    }
}

export const getAddonPage = (user_id, endpoint= "get_item_addons?page=1&limit=10") => {
    return (dispatch) => {
        dispatch(fetchAddonPageAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchAddonPageSuccess(response?.data))
        ).catch(
            error => dispatch(fetchAddonPageFaliour(error.message))
        )
    }
}

export const getAddonPageById = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchAddonPageAccess())
        fetchData(endpoint).then(
            response => dispatch(fetchAddonPageSuccess([response?.data?.response]))
        ).catch(
            error => dispatch(fetchAddonPageFaliour(error.message))
        )
    }
}

export const postAddonPage = (endpoint, body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAddonPageAccess())
        postData(endpoint,body, user_id).then(
            response => {
                dispatch(fetchAddonPageFormData(response.data))
                response.data.status === 'success' && dispatch(getAddonPage(user_id))
            }
        ).catch(
            error => dispatch(fetchAddonPageFaliour(error.message))
        )
    }
}


export const updateAddonPage = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAddonPageAccess())
        updateData(`update_item_addons`,body, user_id).then(
            response => {
                dispatch(fetchAddonPageFormData(response.data))
                response.data.status === 'success' && dispatch(getAddonPage(user_id))
            }
        ).catch(
            error => dispatch(fetchAddonPageFaliour(error.message))
        )
    }
}

export const deleteAddonPage = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchAddonPageAccess())
        deleteData(`delete_item_addons`,body).then(
            response => {
                dispatch(fetchAddonPageFormData(response.data))
                response.data.status === 'success' && dispatch(getAddonPage(user_id))
            }
        ).catch(
            error => dispatch(fetchAddonPageFaliour(error.message))
        )
    }
}
