import {
    HANDLE_BRANCH_REQUEST, HANDLE_BRANCH_SUCCESS, HANDLE_BRANCH_FALIOUR, HANDLE_BRANCH_FORM_DATA, GET_BRANCH_DATA_BY_ID,
    GET_BRANCH_DATA_FOR_ID, GET_ADDRESS, GET_BRANCH_TIMEZONE
} from "./branchTypes";

const initialState = {
    loader: true,
    loading: false,
    data: [],
    address: null,
    time_zone: "",
    activeBranch: [],
    status: '',
    message: ''
}

export const branchReduder = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_BRANCH_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }
        case GET_BRANCH_DATA_FOR_ID:
            return {
                ...state, loader: true
            }
        case GET_BRANCH_DATA_BY_ID:
            return {
                ...state,
                loading: false,
                loader: false,
                status: '',
                message: '',
                activeBranch: action?.payload ? [action?.payload] : []
            }
        case GET_ADDRESS: {
            return {
                ...state,
                loading: false,
                address: action.payload
            }
        }
        case GET_BRANCH_TIMEZONE: {
            return {
                ...state,
                loading: false,
                time_zone: action.payload
            }
        }
        case HANDLE_BRANCH_FALIOUR:
            return {
                ...state,
                loading: false,
                data: [],
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_BRANCH_FORM_DATA:
            return {
                ...state,
                loading: false,
                loader: false,
                status: action.payload.status === "success" ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}