import { GET_ORDER_DATA_BY_ID, HANDLE_ORDER_SUCCESS, HANDLE_ORDER_FALIOUR, HANDLE_ORDER_FORM_DATA, HANDLE_ORDER_REQUEST, GET_ORDER_DATA_BY_NAME } from "./orderTypes";

const initialState = {
    loading: false,
    data: [],
    activeBranch: {},
    status: '',
    message: '',
    totalPages: 0,
    currentPage: 0,
    total_orders: 0,
    accepted_count: 0,
    delivered_count: 0,
    pending_count: 2,
    total_pending_orders: 0,
    total_accepted_orders: 0,
    total_rejected_orders: 0,
    total_delivered_orders: 0,
    total_on_the_way_orders: 0,
    total_delivered_amount: 0,
    total_accepted_amount: 0
}

export const orderReduder = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_ORDER_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                status: '',
                message: '',
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                accepted_count: action.payload.accepted_count,
                delivered_count: action.payload.delivered_count,
                pending_count: action.payload.pending_count,
                total_orders: action.payload.total_orders,
                // total_pending_orders: action.payload.total_pending_orders,
                // total_accepted_orders: action.payload.total_accepted_orders,
                // total_rejected_orders: action.payload.total_rejected_orders,
                // total_delivered_orders: action.payload.total_delivered_orders,
                // total_on_the_way_orders: action.payload.total_on_the_way_orders,
                // total_delivered_amount: action.payload.response.reduce((a , b) => {
                //     return a + b.order_status_delivered.status ? parseFloat(b.order_total_amount) : 0
                // },0),
                // total_accepted_amount: action.payload.response.reduce((a , b) => {
                //     return a + parseFloat(b.order_total_amount)
                // },0),
            }
        case GET_ORDER_DATA_BY_ID:
            return {
                ...state,
                loading: false,
                activeBranch: action.payload
            }
        case GET_ORDER_DATA_BY_NAME:
            return {
                ...state,
                loading: false,
                data: action.payload.response
            }
        case HANDLE_ORDER_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_ORDER_FORM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === "success" ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}