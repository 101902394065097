import {
  TextField,
  Button,
  ButtonGroup,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { FaLock } from "react-icons/all";
import { useSelector, useDispatch } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { setpassword } from "../../../redux";

const Password = ({ next }) => {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const [message, setmessage] = useState("");

  const [formData, setformData] = useState({
    client_user_password: "",
    client_user_password_valid: "",
    client_user_confirm_password: "",
    client_user_confirm_password_valid: "",
  });

  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
      client_user_password_valid:
        e.target.name === "client_user_password"
          ? ""
          : formData.client_user_password_valid,
      client_user_confirm_password_valid:
        e.target.name === "client_user_confirm_password"
          ? ""
          : formData.client_user_confirm_password_valid,
    });
  };

  useEffect(() => {
    state?.auth?.data?.client_user_is_password_set && next(1);
  }, [state?.auth?.data]);

  const onsubmit = (e) => {
    e.preventDefault();
    if (formData.client_user_password) {
      setformData({
        ...formData,
        client_user_confirm_password_valid:
          formData.client_user_confirm_password
            ? ""
            : "Confirm Password is required",
      });
      if (formData.client_user_password.length < 8) {
        setformData({
          ...formData,
          client_user_password_valid:
            "Password length should not be less than 8",
        });
      } else if (
        formData.client_user_password !== formData.client_user_confirm_password
      ) {
        setformData({
          ...formData,
          client_user_confirm_password_valid: "Password is not match",
        });
      } else {
        setmessage("");
        setformData({
          ...formData,
          client_user_confirm_password_valid: "",
          client_user_password_valid: "",
        });
        dispatch(
          setpassword(
            `set_client_user_password`,
            formData,
            state?.auth?.data?._id
          )
        );
      }
    } else {
      setformData({
        ...formData,
        client_user_password_valid: "Password is required",
        client_user_confirm_password_valid:
          formData.client_user_confirm_password
            ? ""
            : "Confirm Password is required",
      });
    }
  };

  return (
    <form className="px_20" onSubmit={(e) => onsubmit(e)} autoComplete="off">
      <div>
        <div className="d_flex">
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button color="secondary">
              <FaLock />
            </Button>
          </ButtonGroup>
          <TextField
            label="Password"
            variant="outlined"
            size="small"
            type={formData.passwordSee ? "text" : "password"}
            fullWidth
            error={formData.client_user_password_valid}
            value={formData.client_user_password}
            name="client_user_password"
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="button"
                    size="small"
                    onClick={() =>
                      setformData({
                        ...formData,
                        passwordSee: !formData.passwordSee,
                      })
                    }
                  >
                    {formData.passwordSee ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Typography color="error" variant="caption">
          {formData.client_user_password_valid}
        </Typography>
      </div>
      <div>
        <div className="d_flex">
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button color="secondary">
              <FaLock />
            </Button>
          </ButtonGroup>
          <TextField
            label="Re-enter Password"
            variant="outlined"
            size="small"
            type={formData.passwordSee ? "text" : "password"}
            error={formData.client_user_confirm_password_valid}
            fullWidth
            value={formData.client_user_confirm_password}
            name="client_user_confirm_password"
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="button"
                    size="small"
                    onClick={() =>
                      setformData({
                        ...formData,
                        passwordSee: !formData.passwordSee,
                      })
                    }
                  >
                    {formData.passwordSee ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Typography color="error" variant="caption">
          {formData.client_user_confirm_password_valid}
        </Typography>
      </div>
      {message && (
        <Typography color="error" variant="body2">
          {message}
        </Typography>
      )}
      <div className="d_flex justify_content_end">
        <Button
          variant="contained"
          color="primary"
          className="button"
          type="submit"
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default Password;
