import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { postData } from "../../../Api/api";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

function PaymentRazorpay(props) {
  const { createPayment, userDetails, image, price, paymenthandler } = props;

  // const [phone_number, setphone_number] = useState(userDetails.client_user_phone_number.substring(3, userDetails.client_user_phone_number - 1))

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const data = await postData(
      `create_razorpay_payment_order`,
      {
        ...createPayment,
        amount: price,
      },
      createPayment.clientuserid
    ).then((t) => t.data);

    const options = {
      key: __DEV__ ? "rzp_test_g71ZMlSKQOEp7W" : "rzp_test_g71ZMlSKQOEp7W",
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: "Upgrade to premium",
      description: "Thanks for subscribing",
      image: image,
      handler: (response) => {
        if (response) {
          paymenthandler({
            status: "success",
            message: "Whoo! you are a Premium subcriber now",
          });
        }
      },
      prefill: {
        name: `${userDetails.client_user_first_name} ${userDetails.client_user_last_name}`,
        email: userDetails.client_user_email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body1" style={{ margin: "0 10px" }}>
        {String.fromCharCode(createPayment.country_currency_to_show_in_plan)}{" "}
        {price}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className="button"
        autoFocus
        onClick={displayRazorpay}
        disabled={!price}
      >
        Pay Now
      </Button>
    </div>
  );
}

export default PaymentRazorpay;
