import { CircularProgress, colors, Paper} from '@material-ui/core'
import React from 'react'
import { useSelector } from "react-redux";
import protectedContainer from '../protectedContainer';
import sidebar from '../Sidebar/sidebar';

const Loader = () => {
    const state = useSelector(state => state)
    return (
        <Paper>
            <div className="loaderBg">
                <CircularProgress
                    color={
                        state?.branch?.activeBranch[0]?.branch_color_theme === "dark"
                            ? "#ffffff"
                            : colors.grey[400]
                    }
                />
            </div>
        </Paper>
    )
}

export default sidebar(protectedContainer(Loader))