import { FETCH_CMS_PAGE_REQUEST,FETCH_CMS_PAGE_SUCCESS,FETCH_CMS_PAGE_FALIOUR,FETCH_CMS_PAGE_DATA } from "./cmsPageTypes";
import {fetchData,updateData,deleteData,postData} from '../../Api/api'

export const fetchCmsPageAccess = () => {
    return {
        type: FETCH_CMS_PAGE_REQUEST
    }
}

export const fetchCmsPageSuccess = (data) => {
    return {
        type: FETCH_CMS_PAGE_SUCCESS,
        payload: data
    }
}

export const fetchCmsPageFaliour = (data) => {
    return {
        type: FETCH_CMS_PAGE_FALIOUR,
        payload : data
    }
}

export const fetchCmsPageFormData = (data) => {
    return {
        type: FETCH_CMS_PAGE_DATA,
        payload: data
    }
}

export const getCmsPage = (user_id, endpoint= "get_all_cms_page?page=1&limit=10") => {
    return (dispatch) => {
        dispatch(fetchCmsPageAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchCmsPageSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchCmsPageFaliour(error.message))
        )
    }
}

export const getCmsPageById = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchCmsPageAccess())
        fetchData(endpoint).then(
            response => dispatch(fetchCmsPageSuccess([response?.data?.response]))
        ).catch(
            error => dispatch(fetchCmsPageFaliour(error.message))
        )
    }
}

export const postCmsPage = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchCmsPageAccess())
        postData(`add_cms_page`,body, user_id).then(
            response => {
                dispatch(fetchCmsPageFormData(response.data))
                response.data.status === 'success' && dispatch(getCmsPage(user_id))
            }
        ).catch(
            error => dispatch(fetchCmsPageFaliour(error.message))
        )
    }
}


export const updateCmsPage = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchCmsPageAccess())
        updateData(`update_cms_page`,body, user_id).then(
            response => {
                dispatch(fetchCmsPageFormData(response.data))
                response.data.status === 'success' && dispatch(getCmsPage(user_id))
            }
        ).catch(
            error => dispatch(fetchCmsPageFaliour(error.message))
        )
    }
}

export const deleteCmsPage = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchCmsPageAccess())
        deleteData(`delete_cms_page`,body,user_id).then(
            response => {
                dispatch(fetchCmsPageFormData(response.data))
                response.data.status === 'success' && dispatch(getCmsPage(user_id))
            }
        ).catch(
            error => dispatch(fetchCmsPageFaliour(error.message))
        )
    }
}
