import { FETCH_USER_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FALIUR, USER_DATA,USERS_FALIUR,FETCH_ACTIVE_USERS_FALIUR,FETCH_ACTIVE_USER_REQUEST } from './userTypes'
import {fetchData,postData,updateData,deleteData} from '../../Api/api'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USER_REQUEST
    }
}

export const fetchActiveUsersRequest = () => {
    return {
        type: FETCH_ACTIVE_USER_REQUEST
    }
}

export const fetchUesersSuccess = (users) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users
    }
}

export const fetchActiveUsersSuccess = (users) => {
    return {
        type: FETCH_ACTIVE_USERS_FALIUR,
        payload: users
    }
}

export const fetchUesersFaliour = (error) => {
    return {
        type: FETCH_USERS_FALIUR,
        payload: error
    }
}

export const postUserSuccess = (data) => {
    return {
        type : USER_DATA, 
        payload : data
    }
}

export const postUserFaliour = (error) => {
    return {
        type : USERS_FALIUR,
        payload : error
    }
}

export const getUserDataAll = (user_id, endpoint = `get_all_user`) => {
    return (dispatch) => {
        dispatch(fetchUsersRequest())
        fetchData(endpoint, user_id).then(
            response => {
                dispatch(fetchUesersSuccess(response.data.response))
            }
        ).catch(error => {
            const errorMsg = error.message
            dispatch(fetchUesersFaliour(errorMsg))
        })
    }
}

export const getActiveUser = (user_id, endpoint) => {
    return (dispatch) => {
        dispatch(fetchActiveUsersRequest())
        fetchData(endpoint, user_id).then(
            response => {
                const users = response.data.response
                dispatch(fetchActiveUsersSuccess(users))
            }
        ).catch(error => {
            const errorMsg = error.message
            dispatch(fetchUesersFaliour(errorMsg))
        })
    }
}

export const postUserData = (body, user_id) => {
    return (dispatch) => {
        fetchUsersRequest()
        postData(`add_user`,body, user_id).then(
            response => {
                dispatch(postUserSuccess(response.data))
                response.data.status === 'success' && dispatch(getUserDataAll(user_id))
            }).catch(error => {
                const errorMsg = error.message
                dispatch(fetchUesersFaliour(errorMsg))
            })
    }
}

export const updateUserData = (body, user_id) => {
    return (dispatch) => {
        fetchUsersRequest()
        updateData(`update_user`,body, user_id).then(
            response => {
                dispatch(postUserSuccess(response.data))
                response.data.status === 'success' && dispatch(getUserDataAll(user_id))
            }).catch(error => {
                const errorMsg = error.message
                dispatch(fetchUesersFaliour(errorMsg))
            })
    }
}

export const deleteUserData = (body, user_id) => {
    return (dispatch) => {
        fetchUsersRequest()
        deleteData(`delete_user`,body, user_id).then(
            response => {
                dispatch(postUserSuccess(response.data))
                response.data.status === 'success' && dispatch(getUserDataAll(user_id))
            }).catch(error => {
                const errorMsg = error.message
                dispatch(fetchUesersFaliour(errorMsg))
            })
    }
}
