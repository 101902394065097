import { FETCH_RECIEPE_REQUEST, FETCH_RECIEPE_SUCCESS, FETCH_RECIEPE_FALIOUR, FETCH_RECIEPE_DATA, HANDLE_RECIEPE_STATUS_MESSAGE } from './reciepe.types'
import { deleteData, fetchData, postData, updateData } from '../../Api/api'

export const fetchReciepeAccess = () => {
    return {
        type: FETCH_RECIEPE_REQUEST
    }
}

export const fetchReciepeSuccess = (data) => {
    return {
        type: FETCH_RECIEPE_SUCCESS,
        payload: data
    }
}

export const fetchReciepeFaliour = (data) => {
    return {
        type: FETCH_RECIEPE_FALIOUR,
        payload: data
    }
}

export const fetchReciepeFormData = (data) => {
    return {
        type: FETCH_RECIEPE_DATA,
        payload: data
    }
}

export const handleReciepeStatus = (data) => {
    return {
        type: HANDLE_RECIEPE_STATUS_MESSAGE,
        payload: data
    }
}

export const getReciepe = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchReciepeAccess())
        fetchData(endpoint, user_id).then(
            response => {
                const newreciepes = []
                const recipSort = []
                response?.data?.response.map(res => recipSort.push(res.item_varient_id))
                function removeDupicates(data) {
                    return data.filter((value, index) => data.indexOf(value) === index)
                }
                removeDupicates(recipSort).map(res =>
                    newreciepes.push({
                        item_id: response?.data?.response.find(recp => recp.item_varient_id === res).item_id,
                        item_varient_id: res,
                        recipe: [...response?.data?.response.filter(recp => recp.item_varient_id === res)]
                    })
                )
                dispatch(fetchReciepeSuccess(newreciepes))
            }
        ).catch (
error => dispatch(fetchReciepeFaliour(error.message))
        )
    }
}

export const postReciepe = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchReciepeAccess())
        postData(`add_many_recipe`, body, user_id).then(
            response => {
                dispatch(fetchReciepeFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchReciepeFaliour(error.message))
        )
    }
}

export const updateReciepe = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchReciepeAccess())
        updateData(`update_many_recipe`, body, user_id).then(
            response => {
                dispatch(fetchReciepeFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchReciepeFaliour(error.message))
        )
    }
}

export const deleteReciepe = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchReciepeAccess())
        deleteData(`delete_many_recipe`, body, user_id).then(
            response => {
                dispatch(fetchReciepeFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchReciepeFaliour(error.message))
        )
    }
}
