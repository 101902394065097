import {
    FETCH_PAYMENT_REQUEST, FETCH_PAYMENT_FALIOUR, FETCH_PAYMENT_SUCCESS,
    FETCH_PAYMENT_DATA, FETCH_PAYMENT_STATUS_CONTROL
} from "./paymentgateWayTypes";

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const paymentGateWayReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PAYMENT_REQUEST:
            return {
                ...state, loader: true
            }
        case FETCH_PAYMENT_STATUS_CONTROL:
            return {
                ...state,
                status: '',
                message: ''
            }
        case FETCH_PAYMENT_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_PAYMENT_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_PAYMENT_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}