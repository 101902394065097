import {FETCH_INVENTORY_CATEGORY_DATA, FETCH_INVENTORY_CATEGORY_FALIOUR, FETCH_INVENTORY_CATEGORY_REQUEST, FETCH_INVENTORY_CATEGORY_SUCCESS,
    HANDLE_INVENTORY_CATEGORY_STATUS_MESSAGE} from './inventoryCategory.types'
import { deleteData, fetchData, postData, updateData } from '../../../Api/api'

export const fetchInventoryCategoryAccess = () => {
    return {
        type: FETCH_INVENTORY_CATEGORY_REQUEST
    }
}

export const fetchInventoryCategorySuccess = (data) => {
    return {
        type: FETCH_INVENTORY_CATEGORY_SUCCESS,
        payload: data
    }
}

export const fetchInventoryCategoryFaliour = (data) => {
    return {
        type: FETCH_INVENTORY_CATEGORY_FALIOUR,
        payload: data
    }
}

export const fetchInventoryCategoryFormData = (data) => {
    return {
        type: FETCH_INVENTORY_CATEGORY_DATA,
        payload: data
    }
}

export const handleInventoryCategoryStatus = (data) => {
    return {
        type: HANDLE_INVENTORY_CATEGORY_STATUS_MESSAGE,
        payload: data
    }
}

export const getInventoryCategory = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryCategoryAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchInventoryCategorySuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchInventoryCategoryFaliour(error.message))
        )
    }
}

export const postInventoryCategory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryCategoryAccess())
        postData(`add_inventory_category`, body, user_id).then(
            response => {
                dispatch(fetchInventoryCategoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventoryCategory(`get_all_inventory_category/null`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryCategoryFaliour(error.message))
        )
    }
}

export const updateInventoryCategory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryCategoryAccess())
        updateData(`update_inventory_category`, body, user_id).then(
            response => {
                dispatch(fetchInventoryCategoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventoryCategory(`get_all_inventory_category/null`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryCategoryFaliour(error.message))
        )
    }
}

export const deleteInventoryCategory = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchInventoryCategoryAccess())
        deleteData(`delete_many_inventory_category`, body, user_id).then(
            response => {
                dispatch(fetchInventoryCategoryFormData(response.data))
                response.data.status === "success" &&  dispatch(getInventoryCategory(`get_all_inventory_category/null`, user_id))
            }
        ).catch(
            error => dispatch(fetchInventoryCategoryFaliour(error.message))
        )
    }
}
