import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const drawerWidth = 230;


const protectedContainer = (Landing) => ({ ...props }) => {
    const state = useSelector(state => state)
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            position: 'relative'
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            top: "auto",
            backgroundColor:state?.branch?.activeBranch[0]?.branch_color_theme === "dark" ? 'rgb(27, 25, 27)' : 'transparent',
        },
        drawerPaper: {
            width: drawerWidth,
            zIndex: 1110,
            left: 110,
            marginTop: 80,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            height: "-webkit-fill-available",
            backgroundColor:state?.branch?.activeBranch[0]?.branch_color_theme === "dark" ? '#1e2022' : theme.palette.secondary.light,
            boxShadow: "0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);"
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
            position: 'relative',
            backgroundColor: state?.branch?.activeBranch[0]?.branch_color_theme === "dark" ? 'rgb(27, 25, 27)' : "transparent",
            minHeight: "calc(100vh - 64px)",
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            position: 'relative',
            // backgroundColor: '#ffffff',
            minHeight: "calc(100vh - 64px)",
        },
        subList: {
            display: "flex",
            fontSize: "1.5rem",
            alignItems: "center",
            marginTop: "1rem",
            cursor: "pointer"
        },
        active: {
            color: theme.palette.primary.main
        },
        notActive: {
            color: theme.palette.primary.contrastText
        }
    }));
    const classes = useStyles();
    const history = useHistory()
    const { open, children } = props
    return (
        <div className={classes.root}>
            {/* <CssBaseline /> */}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Divider />
                <List>
                    {
                        children.map(res => {
                            return res.childrenName &&
                                <ListItem className={`${classes.subList} 
                                ${window.location.pathname === res.pathname ? classes.active :
                                        classes.notActive
                                    }`} onClick={() => history.push({
                                        pathname: res.pathname
                                    })}
                                >
                                    {res.icon}
                                    <span className="font_13" style={{ marginLeft: "10px" }}>{res.childrenName}</span>
                                </ListItem>
                        }
                        )
                    }
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <Landing {...props} />
                {/* <ChildsRoute /> */}
            </main>

        </div>
    );
}

export default protectedContainer