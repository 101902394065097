import React from "react";

import { Menu, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiMenu-paper": {
      background: theme.palette.secondary.light,
      outline: "1px grey",
    },
  },
}));

export const Menus = ({ open, children, handleClose, anchorRef }) => {
  const classes = useStyles();
  return (
    <Menu
      className={classes.root}
      style={{ top: "8%" }}
      open={open}
      anchorEl={anchorRef}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
};
