import { FETCH_CATAGORY_ACCESS, HANDLE_CATAGORY_DATA, FETCH_CATAGORY_SUCCESS, REQUEST_FALIOUR, HANDLE_IMAGE_UPLOAD,
    HANDLE_MESSAGE_STATUS
 } from "./catagoryActionTypes";
import { fetchData, postData, updateData, deleteData } from '../../Api/api'

export const fetchCatagory = () => {
    return {
        type: FETCH_CATAGORY_ACCESS
    }
}

export const fetchCatagorySuccess = (data) => {
    return {
        type: FETCH_CATAGORY_SUCCESS,
        payload: data
    }
}

export const requestFail = (error) => {
    return {
        type: REQUEST_FALIOUR,
        payload: error
    }
}

export const handleStatusMessage = () => {
    return {
        type: HANDLE_MESSAGE_STATUS
    }
}

export const handelSubmit = (data) => {
    return {
        type: HANDLE_CATAGORY_DATA,
        payload: data
    }
}

export const uploadImage = (data) => {
    return {
        type: HANDLE_IMAGE_UPLOAD,
        payload: data
    }
}


export const getAllCatagory = (endpoint = `populate_all_category`, clientuserId) =>
    async dispatch => {
        dispatch(fetchCatagory())
        await fetchData(endpoint, clientuserId).then(
            response => {
                dispatch(fetchCatagorySuccess(response.data))
            }
        ).catch(
            error => {
                dispatch(requestFail(error.message))
            }
        )
    }

export const postSubmit = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_main_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const postSubSubmit = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_sub_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editMainCatagoryStatus = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_main_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editSubCatagoryStatus = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_sub_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editMainCatagory = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_main_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editMainCatagoryPosition = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_many_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editSubCatagory = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_sub_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const deleteMainCatagory = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        deleteData(`delete_main_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const deleteSubCatagory = (body, clientuserId) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        deleteData(`delete_sub_category`, body, clientuserId).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === "success" && dispatch(getAllCatagory(`populate_all_category`, clientuserId))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const imageUpload = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_category_image`, body).then(
            response => {
                dispatch(uploadImage(response.data))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const imageDelete = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        const public_id = body.split("/")[8].split(".")[0];
        postData(`remove_image`, { "category_image": `digital_menu_category/${public_id}` }).then(
            response => {
                // dispatch(uploadImage(response.data))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}