import React, { useEffect, useState } from "react";
import {
  Paper,
  Avatar,
  makeStyles,
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  colors,
  IconButton,
  styled,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import sidebar from "../Sidebar/sidebar";
import protectedContainer from "../protectedContainer";
import { AiFillCamera } from "react-icons/all";
import { addProfileImage, updateUserDetails, getAllstate } from "../../redux";
import PropTypes from 'prop-types';
import { postData } from "../../Api/api";
import { Alert } from "@material-ui/lab";
import UpdateMobileNumber from "./UpdateMobileNumber";
import UpdateEmail from "./UpdateEmail";
import { useHistory } from "react-router-dom";

const Input = styled('input')({
  display: 'none',
});

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2rem",
  },
  paper: {
    padding: "2rem",
    margin: "2rem auto",
    backgroundColor: theme.palette.secondary.light
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  uploadProfile: {
    position: "absolute",
    right: 0,
    bottom: 0,
    border: 0,
    padding: "5px"
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  boxSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "50px"
  },
  divider: {
    margin: "0.5rem 0",
  },
  textColor: {
    color: colors.grey[800],
  },
  bg: {
    backgroundColor: theme.palette.secondary.light
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Profile = () => {
  const state = useSelector(state => state)
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles();
  const theme = useTheme();
  const matchs = useMediaQuery(theme.breakpoints.up("sm"));
  const [update, setupdate] = useState(false)
  const [updateSubdomain, setupdateSubdomain] = useState(false)
  const [value, setValue] = React.useState(0);
  const [upgradetoPremium, setupgradetoPremium] = useState(false)

  const handleChange = () => {
    setupgradetoPremium(false)
  };

  const uploadProfileImage = (image) => {
    const formData = new FormData();
    formData.append("user_profile_picture", image);
    dispatch(addProfileImage(formData))
  }

  useEffect(() => {
    state?.auth?.profileImage && dispatch(updateUserDetails({
      _id: state?.auth?.data?._id,
      client_user_profile_image: state?.auth?.profileImage
    }, state?.auth?.data?._id))
  }, [state?.auth?.profileImage, state?.auth?.data?._id])

  const [formValue, setformValue] = useState(
    {
      client_user_first_name: "",
      client_user_last_name: "",
      client_user_address_1: "",
      client_user_address_2: "",
      client_user_city: "",
      client_user_state: "",
      client_user_country: "",
      client_user_name_of_business: "",
      // client_user_email: "",
      country: {},
      client_user_subdomain: ""
    })


  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    state?.countryCurrency?.allCountry?.map(res => res?.timezones?.includes(tzid) &&
      setformValue({ ...formValue, client_user_country: res.name }))
  }, [tzid, state?.countryCurrency?.allCountry])

  useEffect(() => {
    formValue.client_user_country && dispatch(getAllstate(formValue.client_user_country))
  }, [formValue.client_user_country])

  useEffect(() => {
    setformValue({
      ...formValue, client_user_state: state?.auth?.data?.client_user_state !== null ?
        state?.auth?.data?.client_user_state : "",
      client_user_first_name: state?.auth?.data?.client_user_first_name,
      client_user_last_name: state?.auth?.data?.client_user_last_name,
      client_user_address_1: state?.auth?.data?.client_user_address_1,
      client_user_subdomain: state?.auth?.data?.client_user_subdomain,
      client_user_name_of_business: state?.auth?.data?.client_user_name_of_business,
      // country: state?.countryCurrency?.allCountry?.find(res =>
      //   res?.phone === state?.auth?.data?.client_user_phone_number?.substring(0, 3)),
      // client_user_phone_number: state?.auth?.data?.client_user_phone_number?.substring(3,
      //   state?.auth?.data?.client_user_phone_number?.length),
      client_user_country: state?.auth?.data?.client_user_country ? state?.auth?.data?.client_user_country :
        state?.countryCurrency?.allCountry?.find(res => res?.timezones?.includes(tzid)).name,
    })
  }, [state?.auth?.data, tzid])

  const onchange = (e) => {
    if (e.target.name === "client_user_subdomain") {
      setupdateSubdomain(true)
      setformValue({
        ...formValue, [e.target.name]: e.target.value ?
          e.target.value.match(/^[a-zA-Z_-]+$/) ? e.target.value : formValue.client_user_subdomain : ""
      })
    } else {
      setformValue({ ...formValue, [e.target.name]: e.target.value })
    }
  }

  const [responsefromApi, setresponsefromApi] = useState({
    loader: false,
    status: "",
    message: "",
    response: null
  })

  const checkName = (value) => {
    setresponsefromApi({ ...responsefromApi, loader: true })
    postData(`check_sub_domain`, { subdomain: value }).then(
      response => setresponsefromApi({
        ...responsefromApi, ...response.data,
        loader: false, status: response.data.status === "success" ? "success" : "error"
      })
    ).catch(error => setresponsefromApi({ ...responsefromApi, ...error, loader: false }))
  }

  useEffect(() => {
    if (formValue.client_user_subdomain && formValue.client_user_subdomain !== undefined && updateSubdomain) {
      setresponsefromApi({ ...responsefromApi, loader: true })
      let timer = setTimeout(() => {
        checkName(formValue.client_user_subdomain)
      }, 300);
      return () => {
        clearTimeout(timer)
      }
    }
  }, [formValue.client_user_subdomain, updateSubdomain])

  const onsubmit = () => {
    update ?
      dispatch(updateUserDetails(formValue, state?.auth?.data?._id)) : setupdate(true)
  }

  const closeSankeBar = () => {
    if (responsefromApi.status === "success") {
      setresponsefromApi({
        loader: false,
        status: "",
        message: "",
        response: null
      })
    }
  }

  useEffect(() => {
    if (state?.auth?.status && state?.auth?.status !== "#55ed92") {
      setresponsefromApi({
        ...responsefromApi, status: state?.auth?.status === "green" || state?.auth?.status === "success" ? "success" : "error",
        message: state?.auth?.message
      })
    }
    return () => {
      closeSankeBar()
    }
  }, [state?.auth?.status])

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ width: matchs ? "70%" : "100%" }}
        elevation={10}
      >
        <Box className={classes.boxSpaceBetween} marginBottom="2rem">
          <Box className={classes.box}>
            <div style={{ position: "relative" }}>
              <Avatar
                className={classes.avatar}
                alt="Remy Sharp"
                src={state?.auth?.data?.client_user_profile_image}
              />
              <label htmlFor="icon-button-file">
                <Input accept="image/*" type="file" id="icon-button-file" onChange={e => uploadProfileImage(e.target.files[0])} />
                <IconButton className={classes.uploadProfile} color="primary" component="span">
                  <AiFillCamera />
                </IconButton>
              </label>
            </div>
            <Box marginLeft="1rem">
              <Typography variant="h5">{state?.auth?.data?.client_user_first_name} {state?.auth?.data?.client_user_last_name}</Typography>
              <Typography variant="body1">{state?.auth?.data?.client_user_email}</Typography>
              <Typography variant="body1">{state?.auth?.data?.client_user_phone_number}</Typography>
              <Button size="small" variant="outlined" color="primary" onClick={() => history.push("/advance-plan")}>
                subscribe
              </Button>
            </Box>
          </Box>
          {matchs && (
            <Button className="button" disabled={responsefromApi.status === "error"}
              variant="contained"
              color="primary"
              onClick={() => onsubmit()}
            >
              {update ? responsefromApi.loader ? <CircularProgress color="secondary" size={25} className={classes.color} /> :
                "Save" : "Update Profile"}
            </Button>
          )}
        </Box>
        {/* <Box
          sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="UserInfo" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UpdateUser />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </Box> */}

        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Identifier</Typography>
              <Typography className={classes.textColor}>{state?.auth?.data?._id}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">First Name</Typography>
              {
                update ?
                  <TextField variant="outlined" size="small" type="type" className="form-control" required name="client_user_first_name"
                    value={formValue.client_user_first_name} onChange={(e) => onchange(e)} /> :
                  <Typography className={classes.textColor}>{state?.auth?.data?.client_user_first_name}</Typography>
              }
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Last Name</Typography>
              {
                update ?
                  <TextField variant="outlined" size="small" type="type" className="form-control" required name="client_user_last_name"
                    value={formValue.client_user_last_name} onChange={(e) => onchange(e)} /> :
                  <Typography className={classes.textColor}> {state?.auth?.data?.client_user_last_name}</Typography>
              }
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Location</Typography>
              {
                update ?
                  <TextField variant="outlined" size="small" type="type" className="form-control" required name="client_user_address_1"
                    value={formValue.client_user_address_1} onChange={(e) => onchange(e)} /> :
                  <Typography className={classes.textColor}>{state?.auth?.data?.client_user_address_1}</Typography>
              }
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Company</Typography>
              {
                update ?
                  <TextField variant="outlined" size="small" type="type" className="form-control" name="client_user_name_of_business"
                    value={formValue.client_user_name_of_business} onChange={(e) => onchange(e)} /> :
                  <Typography className={classes.textColor}>{state?.auth?.data?.client_user_name_of_business}</Typography>
              }
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Subdomain</Typography>
              {
                update ?
                  <TextField variant="outlined" size="small" type="type" className="form-control" name="client_user_subdomain"
                    value={formValue.client_user_subdomain} onChange={(e) => onchange(e)} /> :
                  <Typography className={classes.textColor}>{state?.auth?.data?.client_user_subdomain}</Typography>
              }
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Sign up</Typography>
              <Typography className={classes.textColor}>{moment(state?.auth?.data?.createdAt, "YYYYMMDD").fromNow()}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">First seen</Typography>
              <Typography className={classes.textColor}>{moment(state?.auth?.data?.createdAt, "YYYYMMDD").fromNow()}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Last seen</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Plan</Typography>
              <Typography className={classes.textColor}>{state?.auth?.data?.client_user_plan}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Trial ends on</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Total Spend</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Total Order</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Delivered Order</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Rejected Order</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Pending Order</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Albumes</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.boxSpaceBetween}>
              <Typography color="secondary">Photos</Typography>
              <Typography className={classes.textColor}></Typography>
            </Box>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={classes.paper}
        style={{ width: matchs ? "70%" : "100%" }}
        elevation={10}
      >
        <UpdateMobileNumber />
      </Paper>

      <Paper
        className={classes.paper}
        style={{ width: matchs ? "70%" : "100%" }}
        elevation={10}
      >
        <UpdateEmail />
      </Paper>

      <Snackbar open={responsefromApi.status} onClose={closeSankeBar} autoHideDuration={2000}>
        <Alert onClose={closeSankeBar} severity={responsefromApi.status}>
          {responsefromApi.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default sidebar(protectedContainer(Profile))