import { FETCH_SMS_DATA, FETCH_SMS_SUCCESS, FETCH_SMS_REQUEST, FETCH_SMS_FALIOUR, HANDLE_SMS_CONNTROL } from "./smsTypes";
import {fetchData,updateData} from '../../Api/api'

export const fetchsmsAccess = () => {
    return {
        type: FETCH_SMS_REQUEST
    }
}

export const fetchsmsstatuscontrol = () => {
    return {
        type: HANDLE_SMS_CONNTROL
    }
}

export const fetchsmsSuccess = (data) => {
    return {
        type: FETCH_SMS_SUCCESS,
        payload: data
    }
}

export const fetchsmsFaliour = (data) => {
    return {
        type: FETCH_SMS_FALIOUR,
        payload : data
    }
}

export const fetchsmsFormData = (data) => {
    return {
        type: FETCH_SMS_DATA,
        payload: data
    }
}

export const getsms = (user_id) => {
    return (dispatch) => {
        dispatch(fetchsmsAccess())
        fetchData(`get_sms_config`, user_id).then(
            response => dispatch(fetchsmsSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchsmsFaliour(error.message))
        )
    }
}


export const updatesms = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchsmsAccess())
        updateData(`add_or_update_sms_config`,body, user_id).then(
            response => {
                dispatch(fetchsmsFormData(response.data))
                response.data.status === 'success' && dispatch(getsms(user_id))
            }
        ).catch(
            error => dispatch(fetchsmsFaliour(error.message))
        )
    }
}
