import {FETCH_OPERATIONAL_HOURS_EDITDATA,FETCH_OPERATIONAL_HOURS_FALIOUR,
    FETCH_OPERATIONAL_HOURS_SUCCESS,FETCH_OPERATIONAL_HOURS_REQUEST} from "./operationalhoursType";
const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const operationalhourReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_OPERATIONAL_HOURS_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_OPERATIONAL_HOURS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_OPERATIONAL_HOURS_FALIOUR:
            return {
                ...state, 
                loader: false,
                data : {},
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_OPERATIONAL_HOURS_EDITDATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status==='success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}