import { FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS, FETCH_BANNER_FALIOUR, GET_ALL_BANNER_APPLIED_ON, 
     FETCH_BANNER_BY_APPLY_TO_ID_SUCCESS, BANNER_IMAGE, BANNER_DATA, HANDLE_BANNER_STATUS} from "./bannerTypes";
import { fetchData,postData,updateData,deleteData } from "../../Api/api";

export const fetchbannerRequest = () => {
    return {
        type: FETCH_BANNER_REQUEST
    }
}

export const fetchbannerSuccess = (data) => {
    return {
        type : FETCH_BANNER_SUCCESS,
        payload : data
    }
}

export const fetchbannerFaliour = (data) => {
    return {
        type : FETCH_BANNER_FALIOUR, 
        payload : data
    }
}

export const bannerFormData = (data) => {
    return {
        type : BANNER_DATA,
        payload : data
    }
}

export const bannerFormImage = (data) => {
    return {
        type : BANNER_IMAGE,
        payload: data
    }
}

export const handlebannerStatus = () => {
    return {
        type : HANDLE_BANNER_STATUS,
    }
}

export const fetchbannerByApplyToId = (data) => {
    return {
        type: FETCH_BANNER_BY_APPLY_TO_ID_SUCCESS,
        payload: data
    }
}

export const getbannerDataAll = (allTab) => {
    return {
        type: GET_ALL_BANNER_APPLIED_ON,
        payload: allTab
    }
}

export const getbannerAll = (endpoint = `featured_item_list`, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchbannerRequest())
        fetchData(endpoint, clientUserId).then(
            response => dispatch(fetchbannerSuccess(response.data.response))
        ).catch(
            error => dispatch(fetchbannerFaliour(error.message))
        )
    }
}

export const getbannerByApplyToId = (endpoint, clientUserId) => {
        return (dispatch) => {
            dispatch(fetchbannerRequest())
            fetchData(endpoint, clientUserId).then(
                response => dispatch(fetchbannerByApplyToId(response.data))
            ).catch(
                error => dispatch(fetchbannerFaliour(error.message))
            )
        }
}

export const postbannerData = (body, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchbannerRequest())
        postData(`add_page_design`,body, clientUserId).then(
            response => {
                dispatch(bannerFormData(response.data))
                dispatch(getbannerByApplyToId(`get_page_design_by_page_id/${response.data.response.applied_to_page_id}`, clientUserId))
                // dispatch(getbannerByApplyToId(`/featured_item_list/${body.branch_id}/${body.featured_item_applied_to_id}`))
            }
        ).catch(
            error => dispatch(fetchbannerFaliour(error.message))
        )
    }
}

export const updatebannerForm = (body, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchbannerRequest())
        updateData(`update_page_design`,body, clientUserId).then(
            response => {
                dispatch(bannerFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getbannerByApplyToId(`get_page_design_by_page_id/${response.data.response.applied_to_page_id}`, clientUserId))
            }
        ).catch(
            error => dispatch(fetchbannerRequest(error.message))
        )
    }
}
export const deletebannerForm = (body, clientUserId) => {
    return (dispatch) => {
        dispatch(fetchbannerRequest())
        deleteData(`delete_page_design`,{_id : body}, clientUserId).then(
            response => {
                dispatch(bannerFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getbannerByApplyToId(`get_page_design_by_page_id/${response.data.response.applied_to_page_id}`, clientUserId))
            }
        ).catch(
            error => dispatch(fetchbannerRequest(error.message))
        )
    }
}

export const addbannerFormImage = (body, clientUserId) => {
    return (dispatch) => {
        postData(`add_banner_image`,body, clientUserId).then(
            response => dispatch(bannerFormImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchbannerFaliour(error.message))
        )
    }
}

export const deletebannerFormImage = (body, clientUserId) => {
    return (dispatch) => {
        postData(`remove_image`,body, clientUserId).then(
            response => dispatch(bannerFormImage(''))
        ).catch(
            error => dispatch(fetchbannerFaliour(error.message))
        )
    }
}