import React, { useState } from 'react'

import {
  Box, Breadcrumbs, Button, Link, Paper, TextField, Typography, makeStyles, Grid
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ChevronRight, Autorenew, Search } from '@material-ui/icons'
import { useSelector } from "react-redux";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import sidebar from '../Sidebar/sidebar'
import protectedContainer from '../protectedContainer'
import Index from './OrdersReport';

const Report = () => {
  const state = useSelector(state => state)

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.secondary.light
    },
    bgColor: {
      backgroundColor: theme.palette.primary.main,
    },
    bgBlock: {
      backgroundColor: state?.auth?.data?.client_user_color_theme === "dark" ?
        "#232223" : "#ffffff"
    },
    textPrimary: {
      color: theme.palette.primary.main
    },
    textSecondary: {
      color: theme.palette.secondary.main
    },
    selectReport: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }));

  const classes = useStyles();

  const [autoCompleteOptions,
    //  setautoCompleteOptions
  ] = useState(['some',
    'SomeOne',
  ])

  const [value, setValue] = useState('')

  return (
    <>
      <Breadcrumbs color="secondary"
        aria-label="breadcrumb"
        separator={<ChevronRight />}
      >
        <Link color="inherit" href="/">Home</Link>
        <Typography color="primary">Report</Typography>
      </Breadcrumbs>
      <Paper style={{ padding: "2rem", margin: "15px auto 15px auto", width: "100%" }} className={classes.root}>
        <Box padding='1rem' border='1px solid grey' borderRadius='5px' width="100%">
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.selectReport}>
              <Autocomplete fullWidth
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue)
                }}
                options={autoCompleteOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Select a Report" variant="outlined" size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker fullWidth
                  margin="normal"
                  id="time-picker"
                  label="Start Date"
                  required
                  // value={res.starts_from}
                  // onChange={date => {
                  // res.starts_from = date
                  // setcreateItem({ ...createItem, item_start_and_end_time: [...createItem.item_start_and_end_time] })
                  // }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker fullWidth
                  margin="normal"
                  id="time-picker"
                  label="End Date"
                  required
                  // value={res.starts_from}
                  // onChange={date => {
                  // res.starts_from = date
                  // setcreateItem({ ...createItem, item_start_and_end_time: [...createItem.item_start_and_end_time] })
                  // }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginTop='1rem' >
            <Button variant="contained" className="button" color="primary" startIcon={<Autorenew />} >reset</Button>
            <Button variant="contained" className="button" color="primary" startIcon={<Search />}>filter</Button>
          </Box>
        </Box>
        <Index />
        {/* <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
          <Typography variant='h6' >Delivery Time Based Report</Typography>
          <Divider style={{ margin: '5px 0' }} />
          <Box display='flex' >
            <Button variant="contained" color="primary" style={{ marginRight: '2rem' }} >download to jpg</Button>
            <Button variant="contained" color="primary" style={{ marginRight: '2rem' }}>download to png</Button>
            <Button variant="contained" color="primary" >export to excel</Button>
          </Box>
          <TableContainer component={Paper} style={{ marginTop: '2rem' }} className={classes.bgBlock}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' className={classes.textPrimary}>Branch</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>Average of Delivery Time</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>Sum of Order</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>Sum of Order &#60; 30</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>Sum of Order &#62; 45</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>% &#60; 30 min</TableCell>
                  <TableCell align='center' className={classes.textPrimary}>% &#62; 45 min</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align='center' className={classes.textSecondary}>Ontabee</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>2</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>87</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>41</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>43</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>47.13%</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>49.43%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.textSecondary}>Ontabee</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>2</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>87</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>41</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>43</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>47.13%</TableCell>
                  <TableCell align='center' className={classes.textSecondary}>49.43%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}
      </Paper>
    </>
  );

}

export default sidebar(protectedContainer(Report))