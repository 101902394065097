import { FETCH_UNIT_DATA, FETCH_UNIT_REQUEST, FETCH_UNIT_SUCCESS, FETCH_UNIT_FALIOUR, HANDLE_UNIT_STATUS_MESSAGE } from './unit.types'
import { deleteData, fetchData, postData, updateData } from '../../../Api/api'

export const fetchUnitAccess = () => {
    return {
        type: FETCH_UNIT_REQUEST
    }
}

export const fetchUnitSuccess = (data) => {
    return {
        type: FETCH_UNIT_SUCCESS,
        payload: data
    }
}

export const fetchUnitFaliour = (data) => {
    return {
        type: FETCH_UNIT_FALIOUR,
        payload: data
    }
}

export const fetchUnitFormData = (data) => {
    return {
        type: FETCH_UNIT_DATA,
        payload: data
    }
}

export const handleUnitStatus = (data) => {
    return {
        type: HANDLE_UNIT_STATUS_MESSAGE,
        payload: data
    }
}

export const getUnit = (endpoint, user_id) => {
    return (dispatch) => {
        dispatch(fetchUnitAccess())
        fetchData(endpoint, user_id).then(
            response => dispatch(fetchUnitSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchUnitFaliour(error.message))
        )
    }
}

export const postUnit = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchUnitAccess())
        postData(`add_inventory_unit`, body, user_id).then(
            response => {
                dispatch(fetchUnitFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchUnitFaliour(error.message))
        )
    }
}

export const updateUnit = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchUnitAccess())
        updateData(`update_inventory_unit`, body, user_id).then(
            response => {
                dispatch(fetchUnitFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchUnitFaliour(error.message))
        )
    }
}

export const deleteUnit = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchUnitAccess())
        deleteData(`delete_many_inventory_unit`, body, user_id).then(
            response => {
                dispatch(fetchUnitFormData(response.data))
            }
        ).catch(
            error => dispatch(fetchUnitFaliour(error.message))
        )
    }
}
