import {
    FETCH_HANDLE_DELIVARY_BOY_FALIOUR, FETCH_HANDLE_DELIVARY_BOY_REQUEST, FETCH_HANDLE_DELIVARY_BOY_DATA,
    FETCH_HANDLE_DELIVARY_BOY_SUCCESS, HANDLE_STATUS_MESSAGE
} from "./delivaryboyTypes";

const initialState = {
    loader: false,
    data: [],
    status: '',
    message: '',
    currentPage: 0,
    totalPages: 0
}

export const delivaryBoyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HANDLE_DELIVARY_BOY_REQUEST:
            return {
                ...state, loader: true
            }
        case HANDLE_STATUS_MESSAGE:
            return {
                ...state, 
                loader: false,
                status: '',
                message: '',
            }
        case FETCH_HANDLE_DELIVARY_BOY_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload.response,
                status: '',
                message: '',
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages
            }

        case FETCH_HANDLE_DELIVARY_BOY_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_HANDLE_DELIVARY_BOY_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}