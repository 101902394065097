import React, { useEffect, useRef, useState } from 'react'
import { Button, IconButton, TextField, InputAdornment, ButtonGroup, Typography } from '@material-ui/core'
import style from "../authentication.module.css";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { app } from "../../../firebase/firebase";
import { useSelector } from "react-redux";
import { postData } from '../../../Api/api';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import authentication from '..';
import LongMenu from '../../GetCountryCode';
import { FaLock } from 'react-icons/fa';
import { Link} from 'react-router-dom';

const ResetPassword = () => {
    const auth = getAuth(app);
    const state = useSelector(state => state)
    const [formValue, setformValue] = useState({
        emailphone: '', emailphoneError: '', phoneCode: null, password: '',
        passwordSee: false, client_user_password: "",
        client_user_password_valid: "",
        client_user_confirm_password: "",
        client_user_confirm_password_valid: ""
    })
    const [loginPhoneOtp, setloginPhoneOtp] = useState(false)
    const [confirmOtp, setconfirmOtp] = useState(null)
    const [authentication, setauthentication] = useState({
        status: "",
        message: ""
    })
    const inputRef = useRef(null)
    const [otp, setotp] = useState({
        otp1: '', inputRef1: useRef(null),
        otp2: '', inputRef2: useRef(null),
        otp3: '', inputRef3: useRef(null),
        otp4: '', inputRef4: useRef(null),
        otp5: '', inputRef5: useRef(null),
        otp6: '', inputRef6: useRef(null)
    })

    const [idToken, setidToken] = useState(null)

    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        state?.countryCurrency?.allCountry?.map(res => res?.timezones?.includes(tzid) && setformValue({ ...formValue, phoneCode: res }))
    }, [tzid, state?.countryCurrency?.allCountry])

    const onchange = (e) => {
        if (e.target.name === "emailphone") {
            if ((e.target.value.match(/^[0-9\b]+$/) && e.target.value.length <= 11) || e.target.value === "") {
                setformValue({
                    ...formValue, [e.target.name]: e.target.value, emailphoneError: '',
                    client_user_password_valid: e.target.name === "client_user_password" ? "" : formValue.client_user_password_valid,
                    client_user_confirm_password_valid: e.target.name === "client_user_confirm_password" ? "" :
                        formValue.client_user_confirm_password_valid
                })
            }
        } else {
            setformValue({
                ...formValue, [e.target.name]: e.target.value, emailphoneError: '',
                client_user_password_valid: e.target.name === "client_user_password" ? "" : formValue.client_user_password_valid,
                client_user_confirm_password_valid: e.target.name === "client_user_confirm_password" ? "" :
                    formValue.client_user_confirm_password_valid
            })
        }
    };

    const onSignInSubmit = () => {
        var recaptchaWrapperRef = document.createElement("div");
        recaptchaWrapperRef.innerHTML = '<div id="recaptcha-container"></div>';
        document.body.appendChild(recaptchaWrapperRef);
        const phoneNumber = formValue.phoneCode.phone.concat(formValue.emailphone);
        // configureCapcha()
        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // onSignInSubmit();
            }
        }, auth);
        try {
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    setconfirmOtp(confirmationResult)
                    setloginPhoneOtp(true)
                    if (appVerifier && recaptchaWrapperRef) {
                        appVerifier.clear();
                        document.getElementById("recaptcha-container").remove()
                    }
                }).catch((error) => {
                    console.log(error)
                    setauthentication({
                        status: 'error',
                        message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase()
                    })
                });

        } catch (error) {
            setauthentication({
                status: 'error',
                message: "NetWork Error"
            })
        }
    }

    const onSignInValid = () => {
        const testOtp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6
        try {
            confirmOtp.confirm(testOtp).then(
                response => {
                    setauthentication({
                        status: "green",
                        message: ""
                    })
                    setidToken(response.user.accessToken)
                    // dispatch(postloginbyEmail(`firebase_token_to_user`, {
                    //     firebase_token: response.user.accessToken
                    // }))
                }
            ).catch(error => {
                setauthentication({
                    status: "fail",
                    message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase()
                })
                console.log(error)
            }
            )
        } catch (error) {
            setauthentication({
                status: "fail",
                message: error?.code?.slice(5)?.replace(/-/g, " ").toUpperCase()
            })
            console.log(error)
        }
    }

    useEffect(() => {
        if (otp.otp1) {
            if (otp.otp2) {
                if (otp.otp3) {
                    if (otp.otp4) {
                        if (otp.otp5) {
                            if (otp.otp6) {
                                onSignInValid()
                            }
                        }
                    }
                }
            }
        }
    }, [otp])

    const sendOtp = () => {
        if (formValue.emailphone.match(/^(0|91)?[6-9][0-9]{9}/) && formValue?.phoneCode) {
            otp?.inputRef1?.current?.focus()
            onSignInSubmit()
        } else {
            setformValue({ ...formValue, emailphoneError: "Please Enter a Valid Phone Number" })
        }
    }

    useEffect(() => {
        if (formValue?.emailphone?.match(/^[0-9\b]+$/) && formValue?.emailphone?.length === 10 && formValue?.phoneCode) {
            postData(`check_client_phone_number`, { client_user_phone_number: formValue.phoneCode.phone.concat(formValue.emailphone) }, "")
                .then(
                    response => {
                        if (response?.data?.status === "success") {
                            setauthentication({ ...authentication, ...response.data, message: "Phone Number Is not Register" })
                        } else {
                            setauthentication({ ...authentication, ...response.data, message: "" })
                        }
                    }
                ).catch(
                    error => setauthentication({
                        status: "error",
                        message: error
                    })
                )
            // dispatch(postloginbyEmail(`check_client_phone_number`, {
            //     client_user_phone_number: formValue.phoneCode.phone.concat(formValue.emailphone),
            // }))
        }
    }, [formValue?.emailphone, formValue?.phoneCode])

    const onsubmit = () => {
        if (formValue.client_user_password) {
            setformValue({
                ...formValue,
                client_user_confirm_password_valid: formValue.client_user_confirm_password ? "" : "Confirm Password is required"
            })
            if (formValue.client_user_password.length < 8) {
                setformValue({ ...formValue, client_user_password_valid: "Password length should not be less than 8" })
            } else if (formValue.client_user_password !== formValue.client_user_confirm_password) {
                setformValue({ ...formValue, client_user_confirm_password_valid: "Password is not match" })
            } else {
                setformValue({ ...formValue, client_user_confirm_password_valid: "", client_user_password_valid: "" })
                postData(`update_client_user_password_by_farebase_id_token`, {
                    "firebase_token": idToken,
                    "client_user_password": formValue.client_user_password
                }).then(response => {
                    setauthentication({
                        ...authentication,
                        status: response.data.status === "success" ? "green" : "red",
                        message: response.data.message
                    })
                    console.log(response.data)
                }).catch(
                    error => setauthentication({
                        ...authentication,
                        status: "red",
                        message: "network Error"
                    })
                )
            }
        } else {
            setformValue({
                ...formValue, client_user_password_valid: "Password is required",
                client_user_confirm_password_valid: formValue.client_user_confirm_password ? "" : "Confirm Password is required"
            })
        }
    }

    useEffect(() => {
        inputRef?.current?.focus()
        return () => {
            inputRef.current = null
        }
    }, [inputRef])
    return (
        <form className='px_20'>
            <h4 className='formField'>{ authentication.status === "green" ? "Create new Password" : "Forget Password" }</h4>
            {
                authentication.status === "green" ?
                    <>
                        <div>
                            <div className='d_flex'>
                                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button color="secondary"><FaLock /></Button>
                                </ButtonGroup>
                                <TextField label="Write new password" variant="outlined" 
                                    size="small" type={formValue.passwordSee ? "text" : "password"}
                                    fullWidth
                                    error={formValue.client_user_password_valid}
                                    value={formValue.client_user_password} name="client_user_password"
                                    onChange={(e) => onchange(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton type="button" size='small'
                                                    onClick={() => setformValue({ ...formValue, passwordSee: !formValue.passwordSee })}>
                                                    {formValue.passwordSee ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </div>
                            <Typography color='error' variant='caption'>{formValue.client_user_password_valid}</Typography>
                        </div>
                        <div>
                            <div className='d_flex'>
                                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button color="secondary"><FaLock /></Button>
                                </ButtonGroup>
                                <TextField
                                    label="Re Enter Password" variant="outlined" size="small" type={formValue.passwordSee ? "text" : "password"}
                                    error={formValue.client_user_confirm_password_valid}
                                    fullWidth value={formValue.client_user_confirm_password}
                                    name="client_user_confirm_password"
                                    onChange={(e) => onchange(e)} InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton type="button" size='small'
                                                    onClick={() => setformValue({ ...formValue, passwordSee: !formValue.passwordSee })}>
                                                    {formValue.passwordSee ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </div>
                            <Typography color='error' variant='caption'>{formValue.client_user_confirm_password_valid}</Typography>
                        </div>
                        {
                            authentication.status && authentication.message !== "Sorry Your Phone Number Is Already Registered , Choose Another Phone Number !" &&
                            <div style={{ color: authentication.status === 'green' ? 'green' : "red" }}>{authentication.message}</div>
                        }
                        <div className='d_flex justify_content_between align_item_center'>
                            <Link to='/sign-in'>Back to Sign In</Link>
                            <Button className="button" variant="contained" color="primary" onClick={() => onsubmit()}>Submit</Button>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <div className='d_flex'>
                                <ButtonGroup aria-label="outlined button group">
                                    {
                                        <LongMenu selectcountry={formValue.phoneCode} countryChange={(country) => setformValue({ ...formValue, phoneCode: country, emailphoneError: "" })} />
                                    }
                                </ButtonGroup>
                                <TextField fullWidth variant="outlined" size="small"
                                    label="Enter Your Mobile Number"
                                    name="emailphone" error={formValue.emailphoneError}
                                    value={formValue.emailphone}
                                    onChange={(e) => onchange(e)}
                                />
                            </div>
                            <Typography color='error' variant='caption'>{formValue.emailphoneError}</Typography>
                        </div>
                        {
                            loginPhoneOtp && authentication.status === "fail" &&
                            <div className={style.otp}>
                                <input ref={otp.inputRef1} value={otp.otp1} name="otp1"
                                    type="text" maxLength="1" onChange={(e) => {
                                        setotp({ ...otp, otp1: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp1 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef2.current.focus() : otp.inputRef1.current.focus() :
                                            otp.inputRef1.current.focus()
                                    }} />
                                <input ref={otp.inputRef2} type="text" maxLength="1" name="otp2"
                                    value={otp.otp2} onChange={(e) => {
                                        setotp({ ...otp, otp2: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp2 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef3.current.focus() : otp.inputRef2.current.focus() :
                                            otp.inputRef1.current.focus()
                                    }} />
                                <input ref={otp.inputRef3} type="text" maxLength="1" name="otp3"
                                    value={otp.otp3} onChange={(e) => {
                                        setotp({ ...otp, otp3: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp3 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef4.current.focus() : otp.inputRef3.current.focus() :
                                            otp.inputRef2.current.focus()
                                    }} />
                                <input ref={otp.inputRef4} type="text" maxLength="1" name="otp4"
                                    value={otp.otp4} onChange={(e) => {
                                        setotp({ ...otp, otp4: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp4 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef5.current.focus() : otp.inputRef4.current.focus() :
                                            otp.inputRef3.current.focus()
                                    }} />
                                <input ref={otp.inputRef5} type="text" maxLength="1" name="otp5"
                                    value={otp.otp5} onChange={(e) => {
                                        setotp({ ...otp, otp5: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp5 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? otp.inputRef6.current.focus() : otp.inputRef5.current.focus() :
                                            otp.inputRef4.current.focus()
                                    }} />
                                <input ref={otp.inputRef6} type="text" maxLength="1" name="otp6"
                                    value={otp.otp6} onChange={(e) => {
                                        setotp({ ...otp, otp6: e.target.value ? e.target.value.match(/^[0-9\b]+$/) ? e.target.value : otp.otp6 : "" })
                                        e.target.value ? e.target.value.match(/^[0-9\b]+$/) && otp.inputRef6.current.focus() :
                                            otp.inputRef5.current.focus()
                                    }} />
                            </div>
                        }

                        {
                            authentication.status && authentication.message !== "Sorry Your Phone Number Is Already Registered , Choose Another Phone Number !" &&
                            <div style={{ color: authentication.status === 'green' ? 'green' : "red" }}>{authentication.message}</div>
                        }

                        <div className='d_flex justify_content_between align_item_center'>
                            <Link to='/sign-in'>Back to Sign In</Link>
                            {
                                authentication.status === "fail" &&
                                <Button className="button" variant="contained" color="primary" onClick={() => sendOtp()}>
                                    {loginPhoneOtp ? "Resend Otp" : "Send Otp"}</Button>
                            }
                        </div>

                    </>
            }

        </form>
    )
}

export default authentication(ResetPassword) 