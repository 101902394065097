import React from 'react';
import style from "./privacypolicy.module.css";
const PrivacyPolicy = () => {
    return <div className={`${style.c5} ${style.c6}`}>
        <p className={style.c3}><span className={style.c2}>Last updated on: January 27, 2022</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span>By signing up for a ROBOFOOD Account </span><span className={style.c5}>(as defined in Section 1)
        </span><span>or by using any ROBOFOOD Services (as defined below), you are agreeing to be bound by the following
            terms and conditions (the &ldquo;Terms of Service&rdquo;). As used in these Terms of Service,
            &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;ROBOFOOD&rdquo; means the applicable ROBOFOOD Contracting
            Party </span><span className={style.c5}>(as defined in Section 4 below).</span><span className={style.c2}>&nbsp;The services
                offered by ROBOFOOD under the Terms of Service include various products and services to help you sell goods
                and services to buyers, whether online (&ldquo;Online Services&rdquo;) by enabling you to create and build
                your own online store, in person (&ldquo;POS Services&rdquo;), or both. Any such services offered by
                ROBOFOOD are referred to in these Terms of Services as the &ldquo;Services&rdquo;. Any new features or tools
                which are added to the current Services shall be also subject to the Terms of Service. You can review the
                current version of the Terms of Service at any time at https://www.admin.robofood.io/legal. ROBOFOOD
                reserves the right to update and change the Terms of Service by posting updates and changes to the ROBOFOOD
                website. You are advised to check the Terms of Service from time to time for any updates or changes that may
                impact you. and if you do not accept such amendments, you must cease using the Services. You must read,
                agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of
                Service, including ROBOFOOD&rsquo;s Acceptable Use Policy (&ldquo;ROBOFOOD Acceptable Use Policy&rdquo;) and
                Privacy Policy, and, if applicable, the Supplementary Terms of Service for E.U. Merchants (&quot;EU
                Terms&quot;), the ROBOFOOD API Licence and Terms of Use (&ldquo;API Terms&rdquo;) and the ROBOFOOD Data
                Processing Addendum (&ldquo;DPA&rdquo;) before you may sign up for a ROBOFOOD Account or use any ROBOFOOD
                Service. Additionally, if you offer goods or services in relation to COVID-19, you must read, acknowledge
                and agree to the Rules of Engagement for Sale of COVID-19 Related Products. Please read the Terms of
                Service, including any document referred to in these Terms of Service, for the complete picture of your
                legal requirements. By using ROBOFOOD or any ROBOFOOD services, you are agreeing to these terms. Be sure to
                occasionally check back for updates.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>1. Account Terms</span></p>
        <p className={style.c1}><span className={`${style.c4} ${style.c0}`}></span></p>
        <p className={style.c3}><span className={style.c2}>1. To access and use the Services, you must register for a ROBOFOOD account
            (&ldquo;Account&rdquo;) by providing your full legal name, current address, phone number, a valid email
            address, and any other information indicated as required. ROBOFOOD may reject your application for an
            Account, or cancel an existing Account, for any reason, at our sole discretion. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. You must be the older of: (i) 18 years, or (ii) at least the age of majority in
            the jurisdiction where you reside and from which you use the Services to open an Account. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You confirm that you are receiving any Services provided by ROBOFOOD for the
            purposes of carrying on a business activity and not for any personal, household or family purpose.</span>
        </p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. You acknowledge that ROBOFOOD will use the email address and mobile no you provide
            on opening an Account or as updated by you from time to time as the primary method for communication with
            you. You must monitor the primary Account email address you provide to ROBOFOOD and your primary Account
            email address must be capable of both sending and receiving messages. Your email communications with
            ROBOFOOD can only be authenticated if they come from your primary Account email address. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. You are responsible for keeping your password secure. ROBOFOOD cannot and will not
            be liable for any loss or damage from your failure to maintain the security of your Account and password.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. You acknowledge that you are responsible for the creation and operation of your
            ROBOFOOD Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. ROBOFOOD is not a marketplace. Any contract of sale through your ROBOFOOD Store is
            directly between you and the buyer. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>8. You are responsible for all activity and content such as photos, images, videos,
            graphics, written content, audio files, code, information, or data uploaded, collected, generated, stored,
            displayed, distributed, transmitted or exhibited on or in connection with your Account
            (&ldquo;Materials&rdquo;).</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>9. A breach or violation of any term in the Terms of Service, including the ROBOFOOD
            Acceptable Use Policy, as determined in the sole discretion of ROBOFOOD may result in an immediate
            termination of your Services.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>2. Account Activation </span></p>
        <p className={style.c1}><span className={`${style.c4} ${style.c0}`}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>2.1 Store Owner</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Subject to section 2.1, the person signing up for the Service by opening an
            Account will be the contracting party (&ldquo;Store Owner&rdquo;) for the purposes of our Terms of Service
            and will be the person who is authorised to use any corresponding Account we may provide to the Store Owner
            in connection with the Service. You are responsible for ensuring that the name of the Store Owner (including
            the legal name of the company that owns the Store, if applicable) is clearly visible on the Store&rsquo;s
            website. If you are signing up for the Services on behalf of your employer, your employer shall be the Store
            Owner. If you are signing up for the Services on behalf of your employer, then you must use your
            employer-issued email address and you represent and warrant that you have the authority to bind your
            employer to our Terms of Service. Your ROBOFOOD Store can only be associated with one Store Owner. A Store
            Owner may have multiple ROBOFOOD Stores. &ldquo;Store&rdquo; means the online store or physical retail
            location(s) associated with the Account. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>2.2 Staff Accounts</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Based on your ROBOFOOD pricing plan, you can create one or more staff accounts
            (&ldquo;Staff Accounts&rdquo;) allowing other people to access the Account. With Staff Accounts, the Store
            Owner can set permissions and let other people work in their Account while determining the level of access
            by Staff Accounts to specific business information (for example, you can limit Staff Account access to sales
            information on the Reports page or prevent Staff Accounts from changing general store settings). </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. The Store owner is responsible and liable for the acts, omissions and defaults
            arising from use of Staff Accounts in the performance of obligations under these Terms of Service as if they
            were the Store Owner&rsquo;s own acts, omissions or defaults. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. The Store Owner and the users under Staff Accounts are each referred to as a
            &ldquo;ROBOFOOD User&rdquo;.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>2.3 PayPal Express Checkout and ROBOFOOD Payments Accounts </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Upon completion of sign up for the Service, ROBOFOOD will create a PayPal Express
            Checkout account on your behalf, using your email address. Depending on your location, ROBOFOOD may also
            create a ROBOFOOD Payments account on your behalf. otherwise you will be required to activate your Apple Pay
            account within your Account admin.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span>2. You acknowledge that PayPal Express Checkout and/or ROBOFOOD Payments will be your default
            payments gateway(s) and that it is your sole responsibility as the Store Owner to activate and maintain
            these accounts. If you do not wish to keep either of the payment accounts active, it is your responsibility
            to deactivate them. For the avoidance of doubt, PayPal Express Checkout is a Third Party Service, as defined
            in</span><span className={style.c5}>&nbsp;Section 17 </span><span className={style.c2}>of these Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>2.4 Apple Pay for Safari Account </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span>1. Upon completion of sign up for the Service, ROBOFOOD will create an Apple Pay for Safari
            (&ldquo;Apple Pay&rdquo;) account on your behalf, using the URL(s) and business name associated with your
            Account. Depending on your location, ROBOFOOD may activate your Apple Pay account on your behalf, otherwise
            you will be required to activate your Apple Pay account within your Account admin. If you do not wish to
            keep your Apple Pay account active, it is your responsibility to deactivate it. For the avoidance of doubt,
            Apple Pay is a Third Party Service, as defined in</span><span className={style.c5}>&nbsp;Section 17 </span><span
                className={style.c2}>of these Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. If you use an Apple Pay supported payment gateway and your customers have enabled
            Apple Pay on their device, customers may purchase goods and services from your Store using Apple Pay.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. By using Apple Pay on your Store, you are agreeing to be bound by the Apple Pay
            Platform Web Merchant Terms and Conditions, as they may be amended by Apple from time to time. If Apple
            amends the Apple Pay Platform Web Merchant Terms and Conditions, the amended and restated version you will
            be personally Such amendments to the Apple Pay Platform Web Merchant Terms are effective as of the date of
            posting. Your continued use of Apple Pay on your Store after the amended Apple Pay Platform Web Merchant
            Terms are posted constitutes your agreement to, and acceptance of, the amended Apple Pay Platform Web
            Merchant Terms. If you do not agree to any changes to the Apple Pay Platform Web Merchant Terms, de-activate
            your Apple Pay account and do not continue to use Apple Pay on your Store. </span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>2.5 Google Payment </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Upon completion of sign up for the Service, if you have been enrolled in ROBOFOOD
            Payments, ROBOFOOD will also create a Google Payment account on your behalf. If you do not wish to keep your
            Google Payment account active, it is your responsibility to deactivate it. For the avoidance of doubt,
            Google Payment is a Third Party Service, as defined in Section 17 of these Terms of Service. If you use a
            Google Payment supported payment gateway and your customers have enabled Google Payment, customers may
            purchase goods and services from your Store using Google Payment. By using Google Payment on your Store, you
            are agreeing to be bound by the Google Payment API Terms of Service, as they may be amended by Google from
            time to time. If Google amends the Google Payment API Terms of Service, Such amendments to the Google
            Payment API Terms of Service are effective as of the date of posting. Your continued use of Google Payment
            on your Store after the amended Google Payment API Terms of Service are posted constitutes your agreement
            to, and acceptance of, the amended Google Payment API Terms of Service. If you do not agree to any changes
            to the Google Payment API Terms of Service, deactivate your Google Payment account and do not continue to
            use Google Payment on your Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>2.6 Domain Names</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Upon purchasing a domain name through ROBOFOOD, domain registration will be preset
            to automatically renew each year so long as your ROBOFOOD Account remains active. You acknowledge that it is
            your sole responsibility to deactivate the auto-renewal function should you choose to do so.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>3. General Conditions </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span>You must read, agree with and accept all of the terms and conditions contained in these Terms of
            Service, including the ROBOFOOD Acceptable Use Policy, the Privacy Policy, and, if you operate a</span><span
                className={style.c5}>&nbsp;E.U. based Store, the EU Terms, </span><span className={style.c2}>before you may become a ROBOFOOD
                    User. Additionally, you acknowledge and agree to the Rules of Engagement for the Sale of COVID-19 Related
                    Products if you sell such products. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Technical support in respect of the Services is only provided to ROBOFOOD Users.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. The Terms of Service shall be governed by and interpreted in accordance with the
            laws of the Province of New York and the laws of the USA applicable therein, without regard to principles of
            conflicts of laws. The United Nations Convention on Contracts for the International Sale of Goods will not
            apply to these Terms of Service and is hereby expressly excluded. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You acknowledge and agree that ROBOFOOD may amend these Terms of Service at any
            time by posting the relevant amended and restated Terms of Service on ROBOFOOD&rsquo;s website, and such
            amendments to the Terms of Service are effective as of the date of posting. Your continued use of the
            Services after the amended Terms of Service are posted to ROBOFOOD&rsquo;s website constitutes your
            agreement to, and acceptance of, the amended Terms of Service. If you do not agree to any changes to the
            Terms of Service, do not continue to use the Service. If the EU Terms apply to your Store, then amendments
            to these Terms of Service will be provided in accordance with the EU Terms. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. You may not use the ROBOFOOD Services for any illegal or unauthorised purpose nor
            may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to
            copyright laws), the laws applicable to you in your customer&rsquo;s jurisdiction, or the laws of USA and
            the Province of New york. You will comply with all applicable laws, rules and regulations in your use of the
            Service and your performance of obligations under the Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. The ROBOFOOD API License and Terms of Use govern your access to and use of the
            ROBOFOOD API (as defined therein). You are solely responsible for the activity that occurs using your API
            Credentials (as defined in the API Terms) and for keeping your API Credentials secure. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>&nbsp;6. You agree not to reproduce, duplicate, copy, sell, resell or exploit any
            portion of the Service, use of the Services, or access to the Services without the express written
            permission by ROBOFOOD. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. You shall not purchase search engines or other pay per click keywords (such as
            Google Ads), or domain names that use ROBOFOOD or ROBOFOOD trademarks and/or variations and misspellings
            thereof. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>8. Questions about the Terms of Service should be sent to ROBOFOOD Support. </span>
        </p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>9. You understand that your Materials (not including credit card information) may be
            transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices. Credit card information is always
            encrypted during transfer over networks. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>10.The Services allow you to send certain communications to your customers by short
            message service (SMS) messaging (for example, sending order confirmation notifications via SMS) (the
            &quot;SMS Services&quot;). You will only use the SMS Services in compliance with these Terms of Service, and
            the laws of the jurisdiction from which you send messages, and in which your messages are received. </span>
        </p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>11.You acknowledge and agree that your use of the Services, including information
            transmitted to or stored by ROBOFOOD, is governed by its privacy policy at
            https://www.admin.robofood.com/legal </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>12.The Terms of Service may be available in languages other than English. To the
            extent of any inconsistencies or conflicts between these English Terms of Service and ROBOFOOD&rsquo;s Terms
            of Service available in another language, the most current English version of the Terms of Service at
            https://www.robofood.com/legal will prevail. Any disputes arising out of these Terms of Service will be
            resolved in English unless otherwise determined by ROBOFOOD (acting in its sole discretion) or as required
            by applicable law. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>13. All the terms and provisions of the Terms of Service shall be binding upon and
            insure to the benefit of the parties to the Terms of Service and to their respective heirs, successors,
            permitted assigns and legal representatives. ROBOFOOD shall be permitted to assign these Terms of Service
            without notice to you or consent from you. You shall have no right to assign or otherwise transfer the Terms
            of Service, or any of your rights or obligations hereunder, to any third party without ROBOFOOD&rsquo;s
            prior written consent, to be given or withheld in ROBOFOOD&rsquo;s sole discretion. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>14.If any provision, or portion of the provision, in these Terms of Service is, for
            any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or
            unenforceability will not affect any other provision (or the unaffected portion of the provision) of the
            Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or unenforceable
            provision, or portion of the provision, had never been contained within the Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>15.Sections 1, 3(2)-(5), 4, 6-8, 14-15, 17(8)-(10), 19, 21 and 22 will survive the
            termination or expiration of these Terms of Service.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>4. ROBOFOOD Contracting Party </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. If the billing address of your Store is located in the United States or Canada,
            this Section 4(1) applies to you: </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>a. &ldquo;ROBOFOOD Contracting Party&rdquo; means D&amp;D TECH USA INC., a Delaware
            corporation, with offices located at 387 Grant avenue, Brooklyn, New York 11208. The parties irrevocably and
            unconditionally submit to the exclusive jurisdiction of the courts of the Province of New York with respect
            to any dispute or claim arising out of or in connection with the Terms of Service. The United Nations
            Convention on Contracts for the International Sale of Goods will not apply to these Terms of Service and is
            hereby expressly excluded. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4(2). If the billing address of your Store is located in the Asia Pacific region
            (including Antigua and Barbuda, Argentina, Armenia, Azerbaijan, Bahrain, Bahamas, Australia, Anguilla,
            Austria, Afghanistan, Albania, Angola, Andorra, Algeria, Bangladesh, Barbados, Belarus, Belgium,
            Belize,Benin, Bhutan, Bolivia, Bosnia and Herzegovina, Botswana, Brazil, Brunei, Bulgaria, Burkina Faso,
            Burundi, Cabo Verde, Cambodia, Cameroon, Central African Republic (CAR), Chad, Chile, China, Colombia,
            Comoros, Congo, Democratic Republic, Costa Rica, Cote d&rsquo;Ivoire, Croatia, Cyprus, Czechia, Democratic
            Republic of the Congo, Denmark, Djibouti, Dominica, Dominican Republic, Ecuador, Egypt, El Salvador,
            Eritrea, Estonia, Eswatini (formerly Swaziland), Ethiopia, Fiji, Finland, France, Gabon, Gambia, Georgia,
            Germany, Ghana, Gibraltar, Greece, Grenada, Guatemala, Guinea, Guinea-Bissau, Guyana, Haiti, Honduras, Hong
            Kong SAR, Hungary, Iceland, India, Indonesia, Iraq, Ireland, Isle of Man, Israel, Italy, Jamaica, Japan,
            Jersey, Jordan, Kazakhstan, Kenya, Kiribati, Kosovo, Kuwait, Kyrgyzstan, Laos, Latvia, Lebanon, Lesotho,
            Liberia, Libya, Liechtenstein, Lithuania, Luxembourg, Madagascar, Malawi, Maldives , Mali, Malta, Marshall
            Islands, Martinique, Mauritania, Mauritius, Mexico, Micronesia, Moldova, Monaco, Mongolia, Montenegro,
            Morocco, Mozambique, Myanmar (formerly Burma), Namibia, Nauru, Nepal, Netherlands, New Zealand, Nicaragua,
            Niger, Nigeria, North Macedonia (formerly Macedonia), Norway, Oman, Pakistan, Palau, Panama, Papua New
            Guinea, Paraguay, Peru, Philippines, Poland, Portugal, Puerto Rico, Qatar, Romania, Rwanda, Saint Kitts and
            Nevis, Saint Lucia, Saint Vincent and the Grenadines, Samoa, San Marino, Sao Tome and Principe, Saudi
            Arabia, Senegal, Serbia, Seychelles, Sierra Leone, Singapore, Slovakia, Slovenia, Solomon Islands, Somalia,
            South Africa, South Korea, South Sudan, Spain,Sri Lanka, Sudan, Suriname, Sweden, Switzerland, Taiwan,
            Tajikistan, Tanzania, Thailand, Timor-Leste, Togo, Tonga, Trinidad and Tobago, Tunisia, Turkey,
            Turkmenistan, Tuvalu, Uganda, United Arab Emirates (UAE), United Kingdom (UK), , Uruguay, Uzbekistan,
            Vanuatu, Vatican City (Holy See), Venezuela, Vietnam, Yemen, Zambia,Zimbabwe including including Europe and
            Russia, the Middle East, Africa, South America, Caribbean, or Mexico), this Section 4(2) applies to you: a.
            &ldquo;ROBOFOOD Contracting Party &#39;&#39; means D&amp;D Tech INDIA Pte. Ltd, a corporation formed under
            the laws of India, with offices located at 13/2 parui pucca road, Kolkata-700061 West Bengal. The parties
            irrevocably agree that the courts of India are to have exclusive jurisdiction to settle any dispute which
            may arise out of or in connection with the Terms of Service and that accordingly any proceedings arising out
            of or in connection with the Terms of Service shall be brought in such courts. The United Nations Convention
            on Contracts for the International Sale of Goods will not apply to these Terms of Service and is hereby
            expressly excluded.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>5. ROBOFOOD Rights </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. We reserve the right to modify or terminate the Services for any reason, without
            notice at any time. Not all Services and features are available in every jurisdiction and we are under no
            obligation to make any Services or features available in any jurisdiction. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. We reserve the right to refuse service to anyone for any reason at any time.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. We may, but have no obligation to, remove Materials and suspend or terminate
            Accounts if we determine in our sole discretion that the goods or services offered via a Store, or the
            Materials uploaded or posted to a Store, violate our Acceptable Use Policy (&ldquo;AUP&rdquo;) or these
            Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. Verbal or written abuse of any kind (including threats of abuse or retribution) of
            any ROBOFOOD customer, ROBOFOOD employee, member, or officer will result in immediate Account termination.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. ROBOFOOD does not pre-screen Materials and it is in our sole discretion to refuse
            or remove any Materials from the Service, including your Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. We reserve the right to provide our services to your competitors and make no
            promise of exclusivity in any particular market segment. You further acknowledge and agree that ROBOFOOD
            employees and contractors may also be ROBOFOOD customers/merchants and that they may compete with you,
            although they may not use your Confidential Information (as defined in Section 6) in doing so. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. In the event of a dispute regarding Account ownership, we reserve the right to
            request documentation to determine or confirm Account ownership. Documentation may include, but is not
            limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the
            credit card on file, your status as an employee of an entity, etc. </span></p>
        <p className={style.c3}><span className={style.c2}>8. ROBOFOOD retains the right to determine, in our sole judgment, rightful Account
            ownership and transfer an Account to the rightful Store Owner. If we are unable to reasonably determine the
            rightful Store Owner, without prejudice to our other rights and remedies, ROBOFOOD reserves the right to
            temporarily disable an Account until resolution has been determined between the disputing parties. </span>
        </p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>6. Confidentiality </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. &ldquo;Confidential Information&rdquo; shall include, but shall not be limited to,
            any and all information associated with a party&rsquo;s business and not publicly known, including specific
            business information, technical processes and formulas, software, customer lists, prospective customer
            lists, names, addresses and other information regarding customers and prospective customers, product
            designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial
            information, business plans and marketing data, and any other confidential and proprietary information,
            whether or not marked as confidential or proprietary. ROBOFOOD&rsquo;s Confidential Information includes all
            information that you receive relating to us, or to the Services, that is not known to the general public
            including information related to our security program and practices. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. Each party agrees to use the other party&rsquo;s Confidential Information solely
            as necessary for performing its obligations under these Terms of Service and in accordance with any other
            obligations in these Terms of Service including this Section 6. Each party agrees that it shall take all
            reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary
            information, to prevent the duplication, disclosure or use of any such Confidential Information, other than
        </span></p>
        <p className={style.c3}><span className={style.c2}>(i) by or to its employees, agents and subcontractors who must have access to such
            Confidential Information to perform such party&rsquo;s obligations hereunder, who each shall treat such
            Confidential Information as provided herein, and who are each subject to obligations of confidentiality to
            such party that are at least as stringent as those contained herein; or </span></p>
        <p className={style.c3}><span className={style.c2}>(ii) as required by any law, regulation, or order of any court of proper jurisdiction
            over the parties and the subject matter contained in these Terms of Service, provided that, if legally
            permitted, the receiving party shall give the disclosing party prompt written notice and use commercially
            reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential
            Information shall not include any information that the receiving party can prove: </span></p>
        <p className={style.c3}><span className={style.c2}>(A) was already in the public domain, or was already known by or in the possession of
            the receiving party, at the time of disclosure of such information; </span></p>
        <p className={style.c3}><span className={style.c2}>(B) is independently developed by the receiving party without use of or reference to
            the other party&rsquo;s Confidential Information, and without breaching any provisions of these Terms of
            Service; or </span></p>
        <p className={style.c3}><span className={style.c2}>(C) is thereafter rightly obtained by the receiving party from a source other than
            the disclosing party without breaching any provision of these Terms of Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c0} ${style.c4}`}>7. Limitation of Liability </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. You expressly understand and agree that, to the extent permitted by applicable
            laws, ROBOFOOD shall not be liable for any direct, indirect, incidental, special, consequential or exemplary
            damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible
            losses resulting from the use of or inability to use the Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. To the extent permitted by applicable laws, in no event shall ROBOFOOD or our
            suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or
            in connection with our site, our Services or these Terms of Service (however arising including negligence).
            You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, ROBOFOOD
            partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including
            reasonable attorneys&rsquo; fees, made by any third party due to or arising out of your breach of these
            Terms of Service or the documents it incorporates by reference (including the AUP), or your violation of any
            law or the rights of a third party. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. Your use of the Services is at your sole risk. The Services are provided on an
            &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis without any warranty or condition, express, implied
            or statutory. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. ROBOFOOD does not warrant that the Services will be uninterrupted, timely, secure,
            or error-free. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. ROBOFOOD does not warrant that the results that may be obtained from the use of
            the Services will be accurate or reliable. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. ROBOFOOD is not responsible for any of your tax obligations or liabilities related
            to the use of ROBOFOOD&rsquo;s Services. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. ROBOFOOD does not warrant that the quality of any products, services, information,
            or other materials purchased or obtained by you through the Services will meet your expectations, or that
            any errors in the Services will be corrected. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>8. Waiver, Severability, and Complete Agreement The failure of ROBOFOOD to exercise
            or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or
            provision. If any provision of the Terms of Service, including all terms and conditions and other documents
            it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such
            provision shall be changed and interpreted so as to best accomplish the objectives of the original provision
            to the fullest extent allowed by law, and the remaining provision of the Terms of Service shall remain in
            full force and effect. The Terms of Service, including the documents it incorporates by reference,
            constitute the entire agreement between you and ROBOFOOD and govern your use of the Services and your
            Account, superseding any prior agreements between you and ROBOFOOD (including, but not limited to, any prior
            versions of the Terms of Service). </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>9. Intellectual Property and Customer Content </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. We do not claim any intellectual property rights over the Materials you provide to
            the ROBOFOOD Service. All Materials you upload to your ROBOFOOD Store remain yours. You can remove your
            ROBOFOOD Store at any time by deleting your Account. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. By uploading Materials, you agree: </span></p>
        <p className={style.c3}><span className={style.c2}>(a) to allow other internet users to view the Materials you post publicly to your
            Store; </span></p>
        <p className={style.c3}><span className={style.c2}>(b) to allow ROBOFOOD to store, and in the case of Materials you post publicly,
            display and use your Materials; and </span></p>
        <p className={style.c3}><span className={style.c2}>(c) that ROBOFOOD can, at any time, review and delete all the Materials submitted to
            its Service, although ROBOFOOD is not obligated to do so. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You retain ownership over all Materials that you upload to the Store; however, by
            making your Store public, you agree to allow others to view Materials that you post publicly to your Store.
            You are responsible for compliance of the Materials with any applicable laws or regulations. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. ROBOFOOD shall have the non-exclusive right and license to use the names,
            trademarks, service marks and logos associated with your Store to promote the Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>10. POS Services </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>In addition to the terms applicable to Services generally, the following terms apply
            to your access and use of the POS Services. The POS Services include the ROBOFOOD POS software (&ldquo;POS
            Software&rdquo;), the ROBOFOOD POS website, POS programs, documentation, apps, tools, internet-based
            services and components, ROBOFOOD&rsquo;s POS hardware (&ldquo;POS Equipment&rdquo;) and any updates thereto
            provided to you by ROBOFOOD. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Access to and use of the POS Services requires that you have an active and valid
            Account. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. If your POS Services are enabled with ROBOFOOD Payments, you cannot concurrently
            use any other payment processing service. The payment gateway used for your POS Services must be the same as
            that used for your Online Services, if applicable. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You can terminate your POS Services without terminating your Account or any other
            Services you subscribe to, and in such case, you will continue to be billed, and will pay for, the Services
            (other than the terminated POS Services). </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. You agree to use the POS Services in accordance with all procedures that may be
            provided by ROBOFOOD from time to time. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. While the POS Services use encryption technology, and the law generally prohibits
            third parties from monitoring transmission, we cannot guarantee security with respect to the connection to
            the POS Services, and we will not be liable for any unauthorized access to or use of data transmitted via
            the POS Services. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. POS Equipment is purchased, Or &nbsp;leased. Purchaser of the POS Equipment is
            subject to the POS Equipment Agreement, located at https://www.robofood.com/legal Upon payment by you for
            the POS Equipment, and confirmation to you of shipment of the POS equipment, you will acquire ownership of
            and title to the hardware components of the POS Equipment, and you will be licensed to access and use all
            POS Software installed in the POS Equipment (and any updates thereto), on a limited, non-exclusive,
            revocable, non-sublicensable, non-transferable basis, solely for the purposes of using the POS Services. POS
            Equipment is subject to our Return Policy, which can be found at https://www.robofood.com/legal Returns and
            refunds are not available for the Chip &amp; Swipe Reader, Swipe (Audio Jack) and physical gift cards
            purchased from the ROBOFOOD Hardware Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. The Fees for POS Services shall be determined based on the number of locations at
            which you are using the POS Services. Locations are defined by business address and are used for associating
            orders with a specific business address. Locations are added through the ROBOFOOD web administrative console
            associated with your Account. The number of locations using POS Services will also be detected by us and
            billed accordingly as part of your regular billing cycle. In the event of any discrepancy between the number
            of locations added by you through the ROBOFOOD web administrative console and the number of locations
            detected by ROBOFOOD, the number of locations detected by ROBOFOOD shall be deemed to be correct.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>11. ROBOFOOD Shipping </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>You can purchase shipping labels from within your ROBOFOOD administrative console
            (&ldquo;ROBOFOOD Shipping&rdquo;) for delivery of goods purchased from you from your ROBOFOOD Store. In
            addition to these Terms of Service, your access to and use of ROBOFOOD Shipping is subject to the ROBOFOOD
            Shipping Terms of Service, located at (a) https://www.robofood.com/legal </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>12. Theme Store </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. You may establish the appearance of your ROBOFOOD Store with a design template
            from ROBOFOOD&rsquo;s Theme Store (&ldquo;a Theme&rdquo;). If you download a Theme, you are licensed to use
            it for a single Store only. You are free to transfer a Theme to a second one of your own Stores if you close
            your first Store. To initiate a transfer of a Theme to a second one of your Stores, please contact ROBOFOOD
            Support. You are not permitted to transfer or sell a Theme to any other person&rsquo;s Store on ROBOFOOD or
            elsewhere. Multiple Stores require multiple downloads and each download is subject to the applicable fee.
            ROBOFOOD gives no assurance that a particular Theme will remain available for additional downloads. </span>
        </p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. You may modify the Theme to suit your Store. ROBOFOOD may add or modify the footer
            in a Theme that refers to ROBOFOOD at its discretion. ROBOFOOD may modify the Theme where it contains, in
            our sole discretion, an element that violates the ROBOFOOD AUP or other provisions of the Terms of Service,
            even if you received the Theme in that condition. ROBOFOOD may modify the Theme to reflect technical changes
            and updates as required. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. The intellectual property rights of the Theme remain the property of the designer.
            If you exceed the rights granted by your purchase of a Theme, the designer may take legal action against
            you, and, without prejudice to our other rights or remedies, ROBOFOOD may take administrative action such as
            modifying your Store or closing your Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. Technical support for a Theme is the responsibility of the designer, and ROBOFOOD
            accepts no responsibility to provide such support. ROBOFOOD may be able to help you contact the designer.
        </span></p>
        <p className={style.c3}><span className={style.c2}>5. It is the responsibility of the user, and not ROBOFOOD, to ensure that the
            installation of a new theme does not overwrite or damage the current or preexisting theme, or UI, of the
            user.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>13. ROBOFOOD Email &amp; SMS Services</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>You may generate or send email or SMS from your Account using the ROBOFOOD email
            services (the &ldquo;Email Services&rdquo;). In addition to the terms applicable to the Services generally
            (including ROBOFOOD&rsquo;s Acceptable Use Policy, and Privacy Policy), the following terms apply to your
            access and use of the Email Services: </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. ROBOFOOD employs certain controls to scan the content of emails you deliver using
            the Email &amp; SMS Services prior to delivery (&ldquo;Content Scanning&rdquo;). Such Content Scanning is
            intended to limit spam, phishing, or other malicious content that contravenes these Terms of Service, or
            ROBOFOOD&rsquo;s Acceptable Use Policy (collectively, &ldquo;Threats&rdquo;). By using the Email &amp; SMS
            Services, you explicitly grant ROBOFOOD the right to employ such Content Scanning. ROBOFOOD does not warrant
            that the Email &amp; SMS Services will be free from Threats, and each ROBOFOOD merchant is responsible for
            all content generated by their respective Stores. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. By Generating Or Sending Email &amp; Sms Through The Email &amp; Sms Services, You
            Agree To Comply With The Following Requirements (The &ldquo;Email &amp; SMS Services Requirements&rdquo;).
            ROBOFOOD, Or Its Third Party Providers, May Suspend Or Terminate Your Access To And Use Of The Email &amp;
            SMS Services If You Do Not Comply With The Email &amp; SMS Services Requirements. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Your use of the Email &amp; SMS Services must comply with all applicable laws.
            Examples of applicable laws include laws relating to spam or unsolicited commercial email &amp; SMS
            (&ldquo;UCE&rdquo;), privacy, security, obscenity, defamation, intellectual property, pornography,
            terrorism, homeland security, gambling, child protection, and other applicable laws. It is your
            responsibility to know and understand the laws applicable to your use of the Email Services and the emails
            you generate or send through the Email &amp; SMS Services. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. Your use of the Email Services must comply with ROBOFOOD&rsquo;s Privacy Policy.
            It is your responsibility to read and understand the Privacy Policy applicable to your use of the Email
            &amp; SMS Services and the emails you generate or send through the Email Services. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You will use commercially reasonable efforts not to send sensitive personal data,
            including information regarding an individual&rsquo;s medical or health condition, race or ethnic origin,
            political opinions, religious or philosophical beliefs, or other sensitive data (collectively,
            &ldquo;Sensitive Data&rdquo;) through the Email &amp; SMS Services. It is your responsibility to read and
            understand your obligations in relation to Sensitive Data. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. Your use of the Email Services must follow all applicable guidelines established
            by ROBOFOOD. The guidelines below are examples of practices that may violate the Email &amp; SMS Services
            Requirements when generating, or sending emails &amp; SMS through the Email &amp; SMS Services: </span></p>
        <p className={style.c3}><span className={style.c2}>&#9632; using non-permission based email lists (i.e., lists in which each recipient
            has not explicitly granted permission to receive emails &amp; SMS from you by affirmatively opting-in to
            receive those emails); &#9632; using purchased or rented email &amp; SMS lists; &#9632; using third party
            email addresses &amp; mobile no, domain names, or mail servers without proper permission; &#9632; sending
            emails to non-specific addresses (e.g., webmaster@domain.com or info@domain.com); &#9632; sending emails
            that result in an unacceptable number of spam or UCE complaints (even if the emails themselves are not
            actually spam or UCE); &#9632; failing to include a working &ldquo;unsubscribe&rdquo; link in each email
            that allows the recipient to remove themselves from your mailing list; &#9632; failing to comply with any
            request from a recipient to be removed from your mailing list within 10 days of receipt of the request;
            &#9632; failing to include in each email a link to the then-current privacy policy applicable to that email;
            &#9632; disguising the origin or subject matter of any email or falsifying or manipulating the originating
            email address, subject line, headers, or transmission path information for any email; &#9632; failing to
            include in each email your valid physical mailing address or a link to that information; or &#9632;
            including &ldquo;junk mail,&rdquo; &ldquo;chain letters,&rdquo; &ldquo;pyramid schemes,&rdquo; incentives
            (e.g., coupons, discounts, awards, or other incentives) or other material in any email that encourages a
            recipient to forward the Email to another recipient. 3. If You or a customer knows of or suspects any
            violations of the Email &amp; SMS Services Requirements, please notify ROBOFOOD at help@robofood.io ROBOFOOD
            will determine compliance with the Email Services Requirements in its discretion. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. ROBOFOOD&rsquo;s Email &amp; SMS Services utilize Third Party Providers, including
            SendGrid (a Twilio Company). Your use of the Email Services is subject to SendGrid&rsquo;s Acceptable Use
            Policy as it may be amended by SendGrid from time to time. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>14. Payment of Fees </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. You will pay the Fees applicable to your subscription to Online Service and/or POS
            Services (&ldquo;Subscription Fees&rdquo;) and any other applicable fees, including but not limited to
            applicable fees relating to the value of sales made through your Store when using all payment providers
            other than ROBOFOOD Payments (&ldquo;Transaction Fees&rdquo;), and any fees relating to your purchase or use
            of any products or services such as ROBOFOOD Payments, POS Equipment, shipping, apps, Themes, domain names,
            Experts Marketplace, or Third Party Services (&ldquo;Additional Fees&rdquo;). Together, the Subscription
            Fees, Transaction Fees and the Additional Fees are referred to as the &ldquo;Fees&rdquo;. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. You must keep a valid payment method on file with us to pay for all incurred and
            recurring Fees. ROBOFOOD will charge applicable Fees to any valid payment method that you authorize
            (&ldquo;Authorized Payment Method&rdquo;), and ROBOFOOD will continue to charge the Authorized Payment
            Method for applicable Fees until the Services are terminated, and any and all outstanding Fees have been
            paid in full. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. Subscription Fees are paid in advance and will be billed in 30 day intervals (each
            such date, a &ldquo;Billing Date&rdquo;). Transaction Fees and Additional Fees will be charged from time to
            time at ROBOFOOD&rsquo;s discretion. You will be charged on each Billing Date for all outstanding Fees that
            have not previously been charged. Fees will appear on an invoice, which will be sent to the Store Owner via
            the email provided. As well, an invoice will appear on the Account page of your Store&rsquo;s administration
            console. Users have approximately two weeks to bring up and settle any issues with the billing of
            Subscription Fees. </span></p>
        <p className={style.c3}><span className={style.c2}>4. If we are not able to process payment of Fees using an Authorized Payment Method,
            we will make a second attempt to process payment using any Authorized Payment Method 3 days later. If the
            second attempt is not successful, we will make a final attempt 3 days following the second attempt. If our
            final attempt is not successful, we may suspend and revoke access to your Account and the Services. Your
            Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next
            billing cycle. You may not be able to access your Account or your storefront during any period of
            suspension. If the outstanding Fees remain unpaid for 60 days following the date of suspension, ROBOFOOD
            reserves the right to terminate your Account. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. All Fees are exclusive of applicable federal, provincial, state, local or other
            governmental sales, goods and services tax acts. harmonized or other taxes, fees or charges now in force or
            enacted in the future (&ldquo;Taxes&rdquo;). </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. You are responsible for all applicable Taxes that arise from or as a result of
            your subscription to or purchase of ROBOFOOD&rsquo;s products and services. To the extent that ROBOFOOD
            charges these Taxes, they are calculated using the tax rates that apply based on the billing address you
            provide to us. Such amounts are in addition to the Fees for such products and services and will be billed to
            your Authorized Payment Method. If you are exempt from payment of such Taxes, you must provide us with
            evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies
            applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after
            the date we receive evidence satisfactory to ROBOFOOD of your exemption. If you are not charged Taxes by
            ROBOFOOD, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the
            appropriate tax authorities in your jurisdiction. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>7. For the avoidance of doubt, all sums payable by you to ROBOFOOD under these Terms
            of Service shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes
            charged by ROBOFOOD to you and remitted to the appropriate tax authorities on your behalf, any deductions or
            withholdings that are required by law shall be borne by you and paid separately to the relevant taxation
            authority. ROBOFOOD shall be entitled to charge the full amount of Fees stipulated under these Terms of
            Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>8. You are responsible for all applicable Taxes that arise from or as a result of any
            sale on your ROBOFOOD Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>9. You must maintain an accurate location in the administration menu of your ROBOFOOD
            Store. If you change jurisdictions you must promptly update your location in the administration menu.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>10.ROBOFOOD does not provide refunds.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>15. Cancellation and Termination </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. You may cancel your Account and terminate the Terms of Service at any time by
            contacting ROBOFOOD Support and then following the specific instructions indicated to you in
            ROBOFOOD&rsquo;s response. </span></p>
        <p className={style.c3}><span className={style.c2}>2. Upon termination of the Services by either party for any reason: </span></p>
        <p className={style.c3}><span className={style.c2}>A. ROBOFOOD will cease providing you with the Services and you will no longer be able
            to access your Account; </span></p>
        <p className={style.c3}><span className={style.c2}>B. unless otherwise provided in the Terms of Service, you will not be entitled to any
            refunds of any Fees, pro rata or otherwise; </span></p>
        <p className={style.c3}><span className={style.c2}>C. any outstanding balance owed to ROBOFOOD for your use of the Services through the
            effective date of such termination will immediately become due and payable in full; and </span></p>
        <p className={style.c3}><span className={style.c2}>D. Your Store website will be taken offline. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. If you purchased a domain name through ROBOFOOD, upon cancellation your domain
            will no longer be automatically renewed. Following termination, it will be your sole responsibility to
            handle all matters related to your domain with the domain provider. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. If at the date of termination of the Service, there are any outstanding Fees owing
            by you, you will receive one final invoice via email. Once that invoice has been paid in full, you will not
            be charged again. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. We reserve the right to modify or terminate the ROBOFOOD Service, the Terms of
            Service and/or your Account for any reason, without notice at any time (unless otherwise required by
            applicable law). Termination of the Terms of Service shall be without prejudice to any rights or obligations
            which arose prior to the date of termination. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. Fraud: Without limiting any other remedies, ROBOFOOD may suspend or terminate your
            Account if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise)
            have engaged in fraudulent activity in connection with the use of the Services. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>16. Modifications to the Service and Prices </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. Prices for using the Services are subject to change upon 30 days&rsquo; notice
            from ROBOFOOD. Such notice may be provided at any time by posting the changes to the ROBOFOOD Site
            (robofood.io) or the administration menu of your ROBOFOOD Store via an announcement. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. ROBOFOOD reserves the right at any time, and from time to time, to modify or
            discontinue the Services (or any part thereof) with or without notice (unless otherwise required by
            applicable law). </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. ROBOFOOD shall not be liable to you or to any third party for any modification,
            price change, suspension or discontinuance of the Service. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>17. Third Party Services, Experts, and Experts Marketplace </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>1. ROBOFOOD may from time to time recommend, provide you with access to, or enable
            third party software, applications (&ldquo;Apps&rdquo;), products, services or website links (collectively,
            &ldquo;Third Party Services&rdquo;) for your consideration or use, including via the ROBOFOOD App Store, or
            Experts Marketplace. Such Third Party Services are made available only as a convenience, and your purchase,
            access or use of any such Third Party Services is solely between you and the applicable third party services
            provider (&ldquo;Third Party Provider&rdquo;). In addition to these Terms of Service, you also agree to be
            bound by the additional service-specific terms applicable to services you purchase from, or that are
            provided by, Third Party Providers. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>2. ROBOFOOD Experts is an online directory of independent third parties
            (&ldquo;Experts&rdquo;) that can help you build and operate your ROBOFOOD Store. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>3. You can engage and work with an Expert directly or through Experts Marketplace.
            Experts Marketplace provides you with recommendations on Experts that can assist you with different aspects
            of your Store. Using Experts Marketplace, you can find, hire, and communicate with Experts directly from
            your Account admin. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>4. Any use by you of Third Party Services offered through the Services, ROBOFOOD
            Experts, Experts Marketplace or ROBOFOOD&rsquo;s website is entirely at your own risk and discretion, and it
            is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third
            Party Services before using them. In some instances, ROBOFOOD may receive a revenue share from Third Party
            Providers that ROBOFOOD recommends to you or that you otherwise engage through your use of the Services,
            ROBOFOOD Experts, Experts Marketplace or ROBOFOOD&rsquo;s website. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>5. We do not provide any warranties or make representations to you with respect to
            Third Party Services. You acknowledge that ROBOFOOD has no control over Third Party Services and shall not
            be responsible or liable to you or anyone else for such Third Party Services. The availability of Third
            Party Services on ROBOFOOD&rsquo;s websites, including the ROBOFOOD App Store or Experts Marketplace, or the
            integration or enabling of such Third Party Services with the Services does not constitute or imply an
            endorsement, authorization, sponsorship, or affiliation by or with ROBOFOOD. ROBOFOOD does not guarantee the
            availability of Third Party Services and you acknowledge that ROBOFOOD may disable access to any Third Party
            Services at any time in its sole discretion and without notice to you. ROBOFOOD is not responsible or liable
            to anyone for discontinuation or suspension of access to, or disablement of, any Third Party Service.
            ROBOFOOD strongly recommends that you seek specialist advice before using or relying on Third Party
            Services, to ensure they will meet your needs. In particular, tax calculators should be used for reference
            only and not as a substitute for independent tax advice, when assessing the correct tax rates you should
            charge to your customers. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>6. If you install or enable a Third Party Service for use with the Services, you
            grant us permission to allow the applicable Third Party Provider to access your data and other Materials and
            to take any other actions as required for the interoperation of the Third Party Service with the Services,
            and any exchange of data or other Materials or other interaction between you and the Third Party Provider is
            solely between you and such Third Party Provider. ROBOFOOD is not responsible for any disclosure,
            modification or deletion of your data or other Materials, or for any corresponding losses or damages you may
            suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or other
            Materials. Google Maps is a Third Party Service that is used within the Services. Your use of the Services
            is subject to your acceptance of the Google Maps and Earth Enterprise Universal Acceptable Use Policy,
            Google Maps and Google Earth Additional Terms of Service and Google Privacy Policy as it may be amended by
            Google from time to time. </span></p>
        <p className={style.c3}><span className={style.c2}>7. You acknowledge and agree that: (i) by submitting a request for assistance or
            other information through Experts Marketplace, you consent to being contacted by one or more Experts at the
            Store Owner&rsquo;s registered email address (or such other email address provided by you) as well as the
            applicable user email address; and (ii) ROBOFOOD will receive all email communications exchanged via Experts
            Marketplace or in any reply emails (each a &ldquo;Reply&rdquo;) that originate from Experts Marketplace
            (directly or indirectly) between yourself and Experts. You further agree that ROBOFOOD may share your
            contact details and the background information that you submit via the Experts Marketplace with Experts.
            Experts may require access to certain admin pages on your ROBOFOOD Store. You choose the pages that the
            Experts can access. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>8. The relationship between you and any Third Party Provider is strictly between you
            and such Third Party Provider, and ROBOFOOD is not obligated to intervene in any dispute arising between you
            and a Third Party Provider. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>9. Under no circumstances shall ROBOFOOD be liable for any direct, indirect,
            incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that
            result from any Third Party Services or your contractual relationship with any Third Party Provider,
            including any Expert. These limitations shall apply even if ROBOFOOD has been advised of the possibility of
            such damages. The foregoing limitations shall apply to the fullest extent permitted by applicable law.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>10.You agree to indemnify and hold us and (as applicable) our parent, subsidiaries,
            affiliates, ROBOFOOD partners, officers, directors, agents, employees, and suppliers harmless from any claim
            or demand, including reasonable attorneys&rsquo; fees, arising out of your use of a Third Party Service or
            your relationship with a Third Party Provider.</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={`${style.c4} ${style.c0}`}>18. Beta Services </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>From time to time, ROBOFOOD may, in its sole discretion, invite you to use, on a
            trial basis, pre-release or beta features that are in development and not yet available to all merchants
            (&ldquo;Beta Services&rdquo;). Beta Services may be subject to additional terms and conditions, which
            ROBOFOOD will provide to you prior to your use of the Beta Services. Such Beta Services and all associated
            conversations and materials relating thereto will be considered ROBOFOOD Confidential Information and
            subject to the confidentiality provisions in this agreement. Without limiting the generality of the
            foregoing, you agree that you will not make any public statements or otherwise disclose your participation
            in the Beta Services without ROBOFOOD&rsquo;s prior written consent. ROBOFOOD makes no representations or
            warranties that the Beta Services will function. ROBOFOOD may discontinue the Beta Services at any time in
            its sole discretion. ROBOFOOD will have no liability for any harm or damage arising out of or in connection
            with a Beta Service. The Beta Services may not work in the same way as a final version. ROBOFOOD may change
            or not release a final or commercial version of a Beta Service at our sole discretion. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>19. Feedback and Reviews</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>ROBOFOOD welcomes any ideas and/or suggestions regarding improvements or additions to
            the Services. Under no circumstances shall any disclosure of any idea, suggestion or related material or any
            review of the Services, Third Party Services or any Third Party Provider (collectively,
            &ldquo;Feedback&quot;) to ROBOFOOD be subject to any obligation of confidentiality or expectation of
            compensation. By submitting Feedback to ROBOFOOD (whether submitted directly to ROBOFOOD or posted on any
            ROBOFOOD hosted forum or page), you waive any and all rights in the Feedback and that ROBOFOOD is free to
            implement and use the Feedback if desired, as provided by you or as modified by ROBOFOOD, without obtaining
            permission or license from you or from any third party. Any reviews of a Third Party Service or Third Party
            Provider that you submit to ROBOFOOD must be accurate to the best of your knowledge, and must not be
            illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights,
            or otherwise injurious to third parties or objectionable. ROBOFOOD reserves the right (but not the
            obligation) to remove or edit Feedback of Third Party Services or Third Party Providers, but does not
            regularly inspect posted Feedback. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>20. DMCA Notice and Takedown Procedure</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>ROBOFOOD supports the protection of intellectual property and asks ROBOFOOD merchants
            to do the same. It&rsquo;s our policy to respond to all notices of alleged copyright infringement. If
            someone believes that one of our merchants is infringing their intellectual property rights, they can send a
            DMCA Notice to ROBOFOOD&rsquo;s designated agent using our form. Upon receiving a DMCA Notice, we may remove
            or disable access to the Materials claimed to be a copyright infringement. Once provided with a notice of
            takedown, the merchant can reply with a counter notification using our form if they object to the complaint.
            The original complainant has 14 business days after we receive a counter notification to seek a court order
            restraining the merchant from engaging in the infringing activity, otherwise we restore the material.
        </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>21. Rights of Third Parties</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>Save for ROBOFOOD and its affiliates, ROBOFOOD Users or anyone accessing ROBOFOOD
            Services pursuant to these Terms of Service, unless otherwise provided in these Terms of Service, no person
            or entity who is not a party to these Terms of Service shall have any right to enforce any term of these
            Terms of Service, regardless of whether such person or entity has been identified by name, as a member of a
            className or as answering a particular description. For the avoidance of doubt, this shall not affect the rights
            of any permitted assignee or transferee of these Terms. </span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c0}>22. Privacy &amp; Data Protection</span><span className={style.c2}>&nbsp;</span></p>
        <p className={style.c1}><span className={style.c2}></span></p>
        <p className={style.c3}><span className={style.c2}>ROBOFOOD is firmly committed to protecting the privacy of your personal information
            and the personal information of your customers. By using the Service, you acknowledge and agree that
            ROBOFOOD&rsquo;s collection, usage and disclosure of this personal information is governed by our Privacy
            Policy. Additionally, if: (a) you are established in the European Economic Area (EEA); (b) you provide goods
            or services to customers in the EEA; or (c) you are otherwise subject to the requirements of the EU General
            Data Protection Regulation, ROBOFOOD&rsquo;s collection and use of personal information of any European
            residents is also subject to our Data Processing Addendum. ROBOFOOD.io (A Brand registered under the company
            name D&amp;D Tech USA INC. EIN 85-3286735 ) 347 Grant Avenue Brooklyn New York -11208 USA.</span></p>
    </div>;
};

export default PrivacyPolicy