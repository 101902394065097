import {FETCH_INVENTORY_CATEGORY_DATA, FETCH_INVENTORY_CATEGORY_FALIOUR, FETCH_INVENTORY_CATEGORY_REQUEST, FETCH_INVENTORY_CATEGORY_SUCCESS,
    HANDLE_INVENTORY_CATEGORY_STATUS_MESSAGE} from './inventoryCategory.types'

const initialState = {
    loader: false,
    data: [],
    status: '',
    message: ''
}

export const inventoryCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVENTORY_CATEGORY_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_INVENTORY_CATEGORY_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_INVENTORY_CATEGORY_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_INVENTORY_CATEGORY_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_INVENTORY_CATEGORY_STATUS_MESSAGE: 
            return {
                ...state,
                loader: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}