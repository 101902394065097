import {GET_ADMINISTATOR_DATA_BY_ID, HANDLE_ADMINISTATOR_FALIOUR,HANDLE_ADMINISTATOR_FORM_DATA, 
    HANDLE_ADMINISTATOR_REQUEST, HANDLE_ADMINISTATOR_SUCCESS} from './administratorTypes'

const initialState = {
    loading: false,
    data: [],
    activeBranch: {},
    status: '',
    message: ''
}

export const administratorReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_ADMINISTATOR_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_ADMINISTATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                status: '',
                message: ''
            }
        case GET_ADMINISTATOR_DATA_BY_ID:
            return {
                ...state,
                loading: false,
                activeBranch: action.payload
            }
        case HANDLE_ADMINISTATOR_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_ADMINISTATOR_FORM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === "success" ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}