import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Box, makeStyles, colors } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { updatePhoneEmail } from "../../redux";
import authclass from "../Auth";

export default function UpdateEmail() {
    const useStyles = makeStyles((theme) => ({
        boxSpaceBetween: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "50px"
        },
        textColor: {
            color: colors.grey[800],
        },
        divider: {
            margin: "0.5rem 0 1.5rem",
        },
    }));
    const classes = useStyles()
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [updateEmailId, setupdateEmailId] = useState(false)
    const [authentication, setauthentication] = useState({
        status: "",
        message: ""
    })

    const [formValue, setformValue] = useState({ client_user_email: '', client_user_emailError: ''})

    useEffect(() => {
        setformValue({
            ...formValue,
            client_user_email: state?.auth?.data?.client_user_email
        })
    }, [state?.auth?.data?.client_user_email])

    const onchange = (e) => {
        setformValue({
            ...formValue, [e.target.name]: e.target.value , client_user_emailError: ''
        });
        setauthentication({
            status: "",
            message: ""
        })
    };

    const onupdateEmail = () => {
        if (formValue.client_user_email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            dispatch(updatePhoneEmail(`update_client_user`,{
                client_user_email: formValue.client_user_email
            }, state?.auth?.data?._id))
        } else {
            setformValue({...formValue, client_user_emailError: "please Enter a Valid Email id"})
        }
    }

    const logout = () => {
        authclass.logout()
        window.location.reload()
    }

    useEffect(() => {
        state?.auth?.status === "success" && logout()
    }, [state?.auth?.status])

    return (
        <form autoComplete="off">

            <Box className={classes.boxSpaceBetween}>
                <div className="d_flex justify_content_between w_100">
                    <Typography color="secondary">Email</Typography>
                    {
                        updateEmailId ? <TextField variant="outlined" size="small"
                            label="Email Id"
                            name="client_user_email" error={formValue.client_user_emailError}
                            helperText={formValue.client_user_emailError}
                            value={formValue.client_user_email}
                            onChange={(e) => onchange(e)}
                        /> : <Typography className={classes.textColor}>{state?.auth?.data?.client_user_email}</Typography>
                    }
                </div>
                <div className="d_flex justify_content_end w_100">
                    {
                        updateEmailId &&
                        <Button color="primary" className="button"
                            variant="contained" type="button"
                            onClick={() => onupdateEmail()}
                        > Update
                        </Button>
                    }
                    <Button color="primary" className="button"
                        variant="contained" type="button"
                        onClick={() => setupdateEmailId(!updateEmailId)}
                    >
                        {updateEmailId ? "Cancel" : "Update Email"}
                    </Button>
                </div>
            </Box>


            {
                authentication.status &&
                <Typography color={authentication.status}>{authentication.message}</Typography>
            }
        </form>
    );
}
