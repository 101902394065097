import React from "react";
import { Badge, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      top: "-10px",
      color: theme.palette.primary.main,
      borderRadius: "30% !important",
    },
  },
}));

export const CustomBadge = ({ children, badgeContent, color }) => {
  const classes = useStyles();
  return (
    <Badge className={classes.badge} badgeContent={badgeContent} color={color}>
      {children}
    </Badge>
  );
};
