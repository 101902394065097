import { FETCH_REGISTER_POS_REQUEST, FETCH_REGISTER_POS_SUCCESS, FETCH_REGISTER_POS_FALIOUR, FETCH_REGISTER_POS_DATA,
    HANDLE_REGISTER_POS_STATUS_MESSAGE
} from './registerpos.types'

const initialState = {
    loader: false,
    data: [],
    status: '',
    message: ''
}

export const registerPosReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGISTER_POS_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_REGISTER_POS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_REGISTER_POS_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_REGISTER_POS_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_REGISTER_POS_STATUS_MESSAGE: 
            return {
                ...state,
                loader: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}