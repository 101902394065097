import { FETCH_PREFERENCE_REQUEST,FETCH_PREFERENCE_SUCCESS,FETCH_PREFERENCE_FALIOUR,FETCH_PREFERENCE_DATA } from "./preferenceTypes";

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const preferenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PREFERENCE_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_PREFERENCE_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_PREFERENCE_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_PREFERENCE_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}