import {FETCH_MENU_TIME_REQUEST, FETCH_MENU_TIME_SUCCESS, FETCH_MENU_TIME_EDITDATA, FETCH_MENU_TIME_FALIOUR} from "./menutimeTypes";
import {fetchData, updateData } from "../../Api/api";

export const fetchmenutime = () => {
    return {
        type: FETCH_MENU_TIME_REQUEST
    }
}

export const fetchmenutimeSuceess = (data) => {
    return {
        type: FETCH_MENU_TIME_SUCCESS,
        payload: data
    }
}

export const fetchmenutimeFaliour = (data) => {
    return {
        type: FETCH_MENU_TIME_FALIOUR,
        payload :data
    }
}

export const fetchmenutimeData = (data) => {
    return {
        type: FETCH_MENU_TIME_EDITDATA,
        payload: data
    }
}

export const getmenutime = (user_id) => {
    return (dispatch) => {
        dispatch(fetchmenutime())
        fetchData(`get_menu_setup_time`, user_id).then(
            response => dispatch(fetchmenutimeSuceess(response.data.response))
        ).catch(
            error => fetchmenutimeFaliour(error.message)
        )
    }
}


export const updatemenutime = (body, user_id) => {
    return (dispatch) => {
        updateData(`update_menu_setup_time`,body, user_id).then(
            response => {dispatch(fetchmenutimeData(response.data))
        }
        ).catch(
            error => fetchmenutimeFaliour(error.message)
        )
    }
}