import {
    FETCH_ALL_CUSTOMERS_GROUP, ADD_CUSTOMER_GROUP, DELETE_CUSTOMER_GROUP, HANDLE_CUSTOMERS_GROUP,
    UPDATE_CUSTOMER_GROUP, USER_ADD_REMOVE, USER_ADD_REMOVE_FALIUR, USER_ADD_REMOVE_SUCCESS
} from './CustomerGroup.type'

const initialState = {
    loading: false,
    data: null,
    message: '',
    status: ''
}

const customerGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_CUSTOMERS_GROUP:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_CUSTOMERS_GROUP:
            return {
                ...state,
                loading: false,
                customerGroupList: action.payload,
                message: '',
                status: ''
            }
        case ADD_CUSTOMER_GROUP:
            return {
                ...state,
                loading: false,
                addCustomerGroup: action.payload,
                status: 'success',
                message: 'Customer Group Added Successfully'
            }
        case DELETE_CUSTOMER_GROUP:
            return {
                ...state,
                loading: false,
                deleteCustomerGroup: action.payload,
                status: 'success',
                message: 'Customer Group Deleted Successfully'
            }
        case UPDATE_CUSTOMER_GROUP:
            return {
                ...state,
                loading: false,
                updateCustomerGroup: action.payload,
                status: 'success',
                message: 'Customer Group Updated Successfully'
            }
        case USER_ADD_REMOVE:
            return {
                ...state,
                loading: false,
            }
        case USER_ADD_REMOVE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                message: action.payload.message,
                status: 'success'
            }
        case USER_ADD_REMOVE_FALIUR:
            return {
                ...state,
                loading: false,
                message: action.payload,
                status: 'fail'
            }
        default:
            return state
    }
}

export default customerGroupReducer