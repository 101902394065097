import {FETCH_GENERAL_SETTING_DATA, FETCH_GENERAL_SETTING_REQUEST, FETCH_GENERAL_SETTING_SUCCESS, 
    FETCH_GENERAL_SETTING_FALIOUR, HANDLE_GENERAL_SETTING_STATUS_MESSAGE} from './generalSetting.types'

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const generalSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GENERAL_SETTING_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_GENERAL_SETTING_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_GENERAL_SETTING_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_GENERAL_SETTING_DATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_GENERAL_SETTING_STATUS_MESSAGE: 
            return {
                ...state,
                loader: false,
                status: "",
                message: ""
            }
        default:
            return state
    }
}