import { FETCH_SEO_DATA, FETCH_SEO_REQUEST, FETCH_SEO_FALIOUR, FETCH_SEO_SUCCESS, HANDLE_SEO_CONNTROL } from "./seoTypes";
import {fetchData,updateData} from '../../Api/api'

export const fetchseoAccess = () => {
    return {
        type: FETCH_SEO_REQUEST
    }
}

export const fetchseostatuscontrol = () => {
    return {
        type: HANDLE_SEO_CONNTROL
    }
}

export const fetchseoSuccess = (data) => {
    return {
        type: FETCH_SEO_SUCCESS,
        payload: data
    }
}

export const fetchseoFaliour = (data) => {
    return {
        type: FETCH_SEO_FALIOUR,
        payload : data
    }
}

export const fetchseoFormData = (data) => {
    return {
        type: FETCH_SEO_DATA,
        payload: data
    }
}

export const getseo = (user_id) => {
    return (dispatch) => {
        dispatch(fetchseoAccess())
        fetchData(`get_promotion_seo`, user_id).then(
            response => dispatch(fetchseoSuccess(response?.data?.response))
        ).catch(
            error => dispatch(fetchseoFaliour(error.message))
        )
    }
}


export const updateseo = (body, user_id) => {
    return (dispatch) => {
        dispatch(fetchseoAccess())
        updateData(`add_or_update_promotion_seo`,body, user_id).then(
            response => {
                dispatch(fetchseoFormData(response.data))
                response.data.status === 'success' && dispatch(getseo())
            }
        ).catch(
            error => dispatch(fetchseoFaliour(error.message))
        )
    }
}
