import * as React from 'react';
import {
    Box, Typography, Table, TableContainer, TableCell, TableBody, Divider, Button, makeStyles,
    Paper, TableHead, TableRow, Grid
} from "@material-ui/core";

const OrderReport = (props) => {

    const {heading} = props

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.secondary.light
        },
        formControl: {
            marginBottom: "1rem"
        },
        bgColor: {
            backgroundColor: theme.palette.primary.light,
        },
        textPrimary: {
            color: theme.palette.primary.main
        },
        textSecondary: {
            color: theme.palette.secondary.main
        },
    }));

    const classes = useStyles();

    return (
        <Box padding='1rem'>
            <Typography variant='h6' >{heading}</Typography>
            <Divider className={classes.formControl} />
            <Grid container spacing={2} className={classes.formControl} >
                <Grid item>
                    <Button variant="contained" className="button" color="primary" >download to jpg</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" className="button" color="primary" >download to png</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" className="button" color="primary" >export to excel</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.bgColor}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' className={classes.textPrimary}>Hour</TableCell>
                            <TableCell align='center' className={classes.textPrimary}>Total Orders</TableCell>
                            <TableCell align='center' className={classes.textPrimary}>Total Sales</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align='center' className={classes.textSecondary}>00:00</TableCell>
                            <TableCell align='center' className={classes.textSecondary}>2</TableCell>
                            <TableCell align='center' className={classes.textSecondary}>RD$ 288.15</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center' className={classes.textSecondary}>00:00</TableCell>
                            <TableCell align='center' className={classes.textSecondary}>2</TableCell>
                            <TableCell align='center' className={classes.textSecondary}>RD$ 288.15</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default OrderReport