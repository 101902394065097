import {FETCH_MENU_TIME_REQUEST, FETCH_MENU_TIME_SUCCESS, FETCH_MENU_TIME_EDITDATA, FETCH_MENU_TIME_FALIOUR} from "./menutimeTypes";

const initialState = {
    loader: false,
    data: null,
    status: '',
    message: ''
}

export const menutimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MENU_TIME_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_MENU_TIME_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_MENU_TIME_FALIOUR:
            return {
                ...state, 
                loader: false,
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_MENU_TIME_EDITDATA:
            return {
                ...state,
                loader: false,
                status: action.payload.status==='success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}