import {
  FETCH_OPERATIONAL_HOURS_EDITDATA,
  FETCH_OPERATIONAL_HOURS_FALIOUR,
  FETCH_OPERATIONAL_HOURS_SUCCESS,
  FETCH_OPERATIONAL_HOURS_REQUEST,
} from "./operationalhoursType";
import { fetchData, updateData } from "../../Api/api";

export const fetchoperationalhours = () => {
  return {
    type: FETCH_OPERATIONAL_HOURS_REQUEST,
  };
};

export const fetchoperationalhoursSuceess = (data) => {
  return {
    type: FETCH_OPERATIONAL_HOURS_SUCCESS,
    payload: data,
  };
};

export const fetchoperationalhoursFaliour = (data) => {
  return {
    type: FETCH_OPERATIONAL_HOURS_FALIOUR,
    payload: data,
  };
};

export const fetchoperationalhoursData = (data) => {
  return {
    type: FETCH_OPERATIONAL_HOURS_EDITDATA,
    payload: data,
  };
};

export const getoperationalhours = (user_id) => {
  return (dispatch) => {
    dispatch(fetchoperationalhours());
    fetchData(`get_operational_hours`, user_id)
      .then((response) =>
        dispatch(fetchoperationalhoursSuceess(response.data.response))
      )
      .catch((error) => fetchoperationalhoursFaliour(error.message));
  };
};

export const updateoperationalhours = (body, user_id) => {
  return (dispatch) => {
    updateData(`add_or_update_operational_hours`, body, user_id)
      .then((response) => {
        dispatch(fetchoperationalhoursData(response.data));
      })
      .catch((error) => fetchoperationalhoursFaliour(error.message));
  };
};
