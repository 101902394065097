import * as React from 'react';
import {
  Box, Tab, Tabs, AppBar, makeStyles} from "@material-ui/core";
import OrderReport from './OrderReport';

const Index = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.secondary.light
    },
    margin_y_10: {
      margin: "10px 0"
    },
    bgColor: {
      backgroundColor: theme.palette.primary.light,
    },
    textSecondary: {
      color: theme.palette.secondary.main
    },
  }));

  const classes = useStyles();

  const report = ["Orders per Hour", "Orders per Day", "Orders per Month", "Delivery Time Based Report"]

  return (
    <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
      <AppBar position="static" className={`${classes.bgColor} ${classes.margin_y_10}`}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {report.map((res, i) => (
            <Tab key={i} className={classes.textSecondary} label={res} />
          ))}
        </Tabs>
      </AppBar>
      <OrderReport heading={report[value]} />
    </Box>
  );
}

export default Index