import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllstate, updateUserDetails } from "../../../redux";
import { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";

const Userdetails = ({ back, next }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [formValue, setformValue] = useState({
    client_user_first_name: "",
    client_user_last_name: "",
    client_user_address_1: "",
    client_user_address_2: "",
    client_user_city: "",
    client_user_state: "",
    client_user_country: "",
    client_user_email: "",
    client_user_name_of_business: "",
    client_user_are_you_already_selling: "yes",
    client_user_current_revenue: "",
    client_user_are_you_setting_up_store_for_client: false,
    client_user_agreed_terms_and_condition: true,
    client_user_designation: "",
    client_user_restaurant_format: "",
    client_user_restaurant_no_of_outlets: "",
  });

  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    state?.countryCurrency?.allCountry?.map(
      (res) =>
        res?.timezones?.includes(tzid) &&
        setformValue({ ...formValue, client_user_country: res.name })
    );
  }, [tzid, state?.countryCurrency?.allCountry]);

  useEffect(() => {
    formValue.client_user_country &&
      dispatch(getAllstate(formValue.client_user_country));
  }, [formValue.client_user_country]);

  useEffect(() => {
    setformValue({
      ...formValue,
      client_user_first_name: state?.auth?.data?.client_user_first_name,
      client_user_last_name: state?.auth?.data?.client_user_last_name,
      client_user_address_1: state?.auth?.data?.client_user_address_1,
      client_user_address_2: state?.auth?.data?.client_user_address_2,
      client_user_city: state?.auth?.data?.client_user_city,
      client_user_email: state?.auth?.data?.client_user_email,
      client_user_current_revenue:
        state?.auth?.data?.client_user_current_revenue,
      client_user_are_you_setting_up_store_for_client:
        state?.auth?.data?.client_user_are_you_setting_up_store_for_client,
      client_user_agreed_terms_and_condition:
        state?.auth?.data?.client_user_agreed_terms_and_condition,
      client_user_state:
        state?.auth?.data?.client_user_state !== null
          ? state?.auth?.data?.client_user_state
          : "",
      client_user_country: state?.auth?.data?.client_user_country
        ? state?.auth?.data?.client_user_country
        : state?.countryCurrency?.allCountry?.find((res) =>
            res?.timezones?.includes(tzid)
          )?.name,
      client_user_are_you_already_selling: state?.auth?.data
        ?.client_user_are_you_already_selling
        ? state?.auth?.data?.client_user_are_you_already_selling
        : "yes",
      client_user_name_of_business:
        state?.auth?.data?.client_user_name_of_business,
      client_user_designation: state?.auth?.data?.client_user_designation,
      client_user_restaurant_format:
        state?.auth?.data?.client_user_restaurant_format,
      client_user_restaurant_no_of_outlets:
        state?.auth?.data?.client_user_restaurant_no_of_outlets,
    });
  }, [state?.auth?.data, tzid]);

  const onchange = (e) => {
    setformValue({ ...formValue, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    state?.auth?.message === "User Updated Succesfully !" && next(2);
  }, [state?.auth?.message]);

  const onsubmit = (e) => {
    e.preventDefault();
    console.log(formValue);
    dispatch(updateUserDetails(formValue, state?.auth?.data?._id));
  };

  return (
    <form className="px_20" onSubmit={(e) => onsubmit(e)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="type"
            label="Business Name"
            variant="outlined"
            size="small"
            required
            name="client_user_name_of_business"
            value={formValue.client_user_name_of_business}
            onChange={(e) => onchange(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="First name"
            variant="outlined"
            size="small"
            type="type"
            required
            name="client_user_first_name"
            value={formValue.client_user_first_name}
            onChange={(e) => onchange(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="type"
            label="Last Name"
            variant="outlined"
            size="small"
            required
            name="client_user_last_name"
            value={formValue.client_user_last_name}
            onChange={(e) => onchange(e)}
          />
        </Grid>

        {/* <Grid item xs={12}>
                    <TextField label="Email" fullWidth variant="outlined" size="small" type="email" name="client_user_email"
                        value={formValue.client_user_email} onChange={(e) => onchange(e)} />
                </Grid> */}
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={[
              "Cxo",
              "Founder",
              "Director",
              "VP",
              "Manager",
              "Consultant",
              "Chef",
              "Other",
            ]}
            value={formValue.client_user_designation}
            onChange={(e, newValue) =>
              setformValue({
                ...formValue,
                client_user_designation: newValue,
              })
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Designation"
                variant="outlined"
                size="small"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={[
              "Casual Dine",
              "Cloud Kitchen/Delivery Kitchen",
              "Fine Dine",
              "Microbrewery",
              "Pub/Bar",
              "Ice Cream Parlor",
              "Food Truck",
              "Food Court",
              "Bakery",
              "QSR",
              "Sweet Shop",
              "Multiple Formats",
            ]}
            value={formValue.client_user_restaurant_format}
            onChange={(e, newValue) =>
              setformValue({
                ...formValue,
                client_user_restaurant_format: newValue,
              })
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Business Type"
                variant="outlined"
                size="small"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={[
              "1",
              "2 to 5",
              "6 to 25",
              "26 to 50",
              "51 to 100",
              "100 to 250",
              "250 to 500",
              "500+",
              // "None",
            ]}
            value={formValue.client_user_restaurant_no_of_outlets}
            onChange={(e, newValue) =>
              setformValue({
                ...formValue,
                client_user_restaurant_no_of_outlets: newValue,
              })
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Number of Outlets"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            options={state.countryCurrency.states}
            value={formValue.client_user_state}
            onChange={(e, newValue) =>
              setformValue({
                ...formValue,
                client_user_state: newValue,
              })
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="State"
                required
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="City"
            fullWidth
            variant="outlined"
            size="small"
            required
            name="client_user_city"
            value={formValue.client_user_city}
            onChange={(e) => onchange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={state.countryCurrency.allCountry.map((res) => res.name)}
            value={formValue.client_user_country}
            onChange={(e, newValue) =>
              setformValue({
                ...formValue,
                client_user_country: newValue,
              })
            }
            getOptionLabel={(option) => option}
            disabled={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                required
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <div className={`d_flex align_item_center justify_content_between`}> */}
          <Typography color="secondary">
            By Pressing "CONTINUE" you accept the end{" "}
            <Link to="/legal" target="_blank">
              User License Agreement
            </Link>{" "}
            and the{" "}
            <Link to="/legal" target="_blank">
              Privacy Policy
            </Link>
          </Typography>
          {/* </div> */}
        </Grid>
        <Grid item xs={12}>
          <div className={`d_flex align_item_center justify_content_center`}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="button"
            >
              continue
            </Button>
          </div>
        </Grid>
        {
          state?.auth?.status &&
            // {
            state?.auth?.status === "red" && (
              <div style={{ color: state?.auth?.status }}>
                {state?.auth?.message}
              </div>
            )
          // }
        }
      </Grid>
    </form>
  );
};

export default Userdetails;
