import axios from "axios";

const baseURL = "https://multi-branch-api.robofood.io/api/";
// "https://app-saas-restaurant-app-backend-development-yy5usdfgwq-uc.a.run.app/api/";

export const fetchData = (endPoint, clientUserId) =>
  axios.get(baseURL + endPoint, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const postData = (endPoint, body, clientUserId) =>
  axios.post(baseURL + endPoint, body, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const authentication = (token) =>
  axios.post(baseURL + "ClientUserTokenIsValid", "", {
    headers: { "x-auth-token": token },
  });

export const updateData = (endPoint, body, clientUserId) =>
  axios.put(baseURL + endPoint, body, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const deleteData = (endPoint, body, clientUserId) =>
  axios.post(baseURL + endPoint, body, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const checkProdutMenuId = (value, clientUserId) =>
  axios.get(`${baseURL}/check_item_sku/${value}`, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const getUserByWord = (word, clientUserId) =>
  axios.get(`${baseURL}/search_user/${word}`, {
    headers: {
      clientuserid: clientUserId,
      branchid: localStorage.getItem("branch-id"),
    },
  });

export const getDownloadLink = () =>
  axios.get(`${baseURL}get_all_download_app`, {
    headers: { branchid: localStorage.getItem("branch-id") },
  });
